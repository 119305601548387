 export class ProskiDetails {
    AdminId: any;
    CandidateId: any;
    CandidatesList: any;
    CertificationJson: any;
    CertificationScore: any;
    CreatedBy: any;
    CreatedOn: any;
    Domain: any;
    Function: any;
    Id: any;
    Industry: any;
    JobPostingId: any;
    LeadershipSkillScore: any;
    QualificationJson: any;
    QualificationScore: any;
    Reason: any;
    RelevantExp: any;
    RemarksForEmployer: any;
    SkillsJson: any;
    SoftSkillScore: any;
    StatusOfCandidate: any;
    TechnicalSkillScore: any;
    UpdatedBy: any;
    UpdatedOn: any;
    ValueCreationSkillScore: any;
}
