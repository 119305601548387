import { Component, OnInit, Input,OnDestroy } from '@angular/core';
import {JobService} from '../services/index';
import {Response, AcademicDetails} from '../models/index';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AcademicDetailsUpdate } from './academicdetailsupdate.component';

@Component({
  selector: 'app-user-profile-academic',
  templateUrl: './userprofileacademic.component.html',
})
export class UserProfileAcademic implements OnInit,OnDestroy  {
  userId : any;
  Id: any;
  submitted : boolean;
  userInfo : Response;
  errormessage : any;
  successmessage : any;
  cities : any;
  states : any;
  dialogRef : any;
  academicDetails : any;
  academicDetail : AcademicDetails;
  academiccompletionscore : any;
  profilecompletionscore : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;


  constructor(private jobService: JobService, private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.userInfo = new Response();
    this.academicDetail = new AcademicDetails();
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.successmessage = '';
    this.userId = sessionStorage.getItem('userid');
    this.showalert  = false;
    
    var acadProfScore =  sessionStorage.getItem('AcademicProfileScore');
    console.log('Acad profile score from session : ',acadProfScore);
    
    if(!(acadProfScore) || (acadProfScore == 'null')){
      console.log('Resetting to empty');
      this.academiccompletionscore = '0';
    } else {
      this.academiccompletionscore = acadProfScore;
    }
    this.profilecompletionscore = sessionStorage.getItem('ProfileCompletionScore');
    
    this.getUserprofileAcademicData();
  }

  ngOnDestroy(){
    console.log('In ngdestroy of user profile academic');
    if(this.dialogRef){
      console.log('Closing dialog');
      this.dialogRef.close();
    }
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  getUserprofileAcademicData(){
    this.academicDetails = [];
      console.log('userid from session : ', this.userId);
      this.jobService.getAcademicDetails(this.userId).subscribe(
        data => {
                if(data.success == true){
                  this.academicDetails = data.Results;
                  
                  console.log('Academic details obtained : ', this.academicDetails);
                } else {
                  this.errormessage = data.error1;
                  this.responseMessage = data.error1;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          this.responseMessage = 'Error occurred while getting user data';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          console.log('Error occurred : ', error);
              });

  }

openDialog(save : boolean, ob : any){
  this.successmessage = '';
  this.errormessage = '';
  let saveorupdate = '';
  if(save){
    this.academicDetail = new AcademicDetails();
    saveorupdate = 'save';
  } else {
    this.academicDetail = ob;
    saveorupdate = 'update';
  }

  this.dialogRef = this.dialog.open(AcademicDetailsUpdate, {
    width:'100vw',
    height:'100%',
    closeOnNavigation : true,
    data: {academicdetail : this.academicDetail, save : saveorupdate}
  });

  this.dialogRef.afterClosed().subscribe(result => {
    console.log('in dialog close test');
    if(result){
      this.academicDetail = result;
      this.academicDetail.UserId = this.userId;
      console.log('Academic detail to be updated : ', this.academicDetail);
      if(save){
        this.createAcademicDetails();
      } else {
        this.updateAcademicDetails();
      }

    }
  else{
    this.getUserprofileAcademicData();
  }
  });
}

updateAcademicDetails(){
  this.errormessage = '';
  this.successmessage = '';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert =false;
  console.log('In updateAcademicDetails');
  this.academicDetail.AdminId = this.userId;
  this.jobService.updateAcademicDetails(this.academicDetail).subscribe(
      data => {
              if(data.success == true){
                this.userInfo = data.Result;
                this.academiccompletionscore = this.userInfo.AcadProfileCompletionScore;
                this.profilecompletionscore = this.userInfo.TotalProfileCompletionScore;
                sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
                this.academicDetails = data.Result.CupAcademicDetail;
                this.responseMessage = 'Academic details updated successfully';
                this.typeOfResponseMessage = 'success';
                this.showalert = true;
            } else {
                this.errormessage = data.error;
                this.responseMessage = data.error;
                this.typeOfResponseMessage = 'error';
                this.showalert = true;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
}

createAcademicDetails() {
  this.errormessage = '';
  this.successmessage = '';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert =false;
  console.log('In createAcademicDetails');
  this.academicDetail.AdminId = this.userId;
  this.jobService.createAcademicDetails(this.academicDetail).subscribe(
    data => {
            if(data.success == true){
              this.userInfo = data.Result;
              this.academiccompletionscore = this.userInfo.AcadProfileCompletionScore;
              this.profilecompletionscore = this.userInfo.TotalProfileCompletionScore;
              sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
              this.academicDetails = data.Result.CupAcademicDetail;
              console.log('Academic details received : ', this.academicDetails);
              this.responseMessage = 'Academic details created successfully';
              this.typeOfResponseMessage = 'success';
              this.showalert = true;
            } else {
              this.responseMessage = data.error;
              this.typeOfResponseMessage = 'error';
              this.showalert = true;
              this.errormessage = data.error;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
}

deleteAcademicDetails(ob: any){
  this.errormessage = '';
  this.successmessage = '';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert =false;
  console.log('In deleteAcademicDetails');
  this.academicDetail = ob;
  this.academicDetail.AdminId = this.userId;
  this.jobService.deleteAcademicDetails(this.academicDetail).subscribe(
      data => {
              if(data.success == true){
              this.getUserprofileAcademicData();
              this.responseMessage = 'Academic details deleted successfully';
              this.typeOfResponseMessage = 'success';
              this.showalert = true;
            } else {
              this.getUserprofileAcademicData();
              this.errormessage = data.error;
              this.responseMessage = data.error;
              this.typeOfResponseMessage = 'error';
              this.showalert = true;
              console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
}

closeAlert(event : any){
  if(event){
    this.showalert = false;
  }
}




 
}




