import { Component, OnInit, Input,Inject,OnChanges } from '@angular/core';
import {JobService} from '../services/index';
import {Response} from '../models/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import {Config} from '../config/index';
import {CompanyDetails} from '../models';
@Component({
  selector: 'app-curcompany-details-update',
  templateUrl: './curcompdetailsupdate.component.html',
})
export class CurCompanyDetailsUpdate implements OnInit ,OnChanges {
  userId : any;
  jobreqId: any;
  Id: any;
  userInfo : Response;
  errormessage : any;
  joblevels : any;
  years: any;
  months : any;
  fmonth: any;
  fyear: any;
  tmonth: any;
  tyear: any;
  updateScenario : boolean;
  dialogRefCP : any;
  fyearvalid : boolean;
  fmonthvalid : boolean;
  tyearvalid : boolean;
  tmonthvalid : boolean;
  submitted : boolean;
  Designation : any;
  CompanyName : any;
  FromDate : any;
  ToDate : any;
  JobLevel : any;
  showYear : boolean;
  tmonths : any;
  companyDetails: [];
  companyDetail: CompanyDetails;
  

  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<CurCompanyDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config,public dialog: MatDialog) { }

    ngOnChanges(){
      console.log('In ngonchanges of CurCompanyDetailsUpdate component');
    }
  ngOnInit() {

    this.userInfo = new Response();
    this.Id = '';
    this.errormessage = '';
    this.userId = sessionStorage.getItem('userid');
    this.jobreqId = sessionStorage.getItem('jobreqid');
    this.years = this.config.getYearsList();
    this.months = this.config.getMonthsList();
    this.tmonths = JSON.parse(JSON.stringify(this.months));
    this.tmonths.unshift('Till Date');
    console.log('tmonths : ', this.tmonths);
    console.log('months : ', this.months);
    this.getJobLevels();
    this.fmonthvalid = true;
    this.fyearvalid = true;
    this.tmonthvalid = true;
    this.tyearvalid = true;
    this.showYear = true;
    console.log("this.data.curCompanylist",this.data.curCompanylist);
    if(this.data.save == 'update'){
      this.updateScenario = true;
      this.initializeExp();
    }
    
    this.getCompanyDetails();
  }
  getCompanyDetails() {
    this.companyDetails = []
    this.jobService.getCompanyDetails(this.userId).subscribe(
      data => {
        if (data.success == true) {
          this.companyDetails = data.Results;
          console.log('Company details obtained : ', this.companyDetails);
        } else {
          console.log('Error message : ', data.error);
        }
      },
      error => {
        console.log('Error occurred : ', error);
            });
  }
  gotoPage(route){
    this.router.navigate([route]);
  }

  initializeExp(){
    console.log('');
    var fyr = this.data.curcomp.FromDate.split(' ');
    if(fyr && fyr.length == 2){
      this.fmonth = fyr[0];
      this.fyear = fyr[1];
    }
    if(this.data.curcomp.ToDate == 'Till Date'){
      this.tmonth = 'Till Date';
      this.showYear = false;
    } else {
      var tyr = this.data.curcomp.ToDate.split(' ');
      if(tyr && tyr.length == 2){
        this.tmonth = tyr[0];
        this.tyear = tyr[1];
      }
    }
  }


getJobLevels(){
  this.jobService.getJobLevels().subscribe(
    data => {
            if(data.success == true){
              this.joblevels = data.Results;
            } else {
              this.errormessage = data.error;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      console.log('Error occurred : ', error);
          });
  
  }

  decideYearDP(ob){
    if(ob.value == 'Till Date'){
      this.showYear = false;
    } else {
      this.showYear = true;
    }
  }


onCancel(): void { 
  this.dialogRef.close(); 
}


saveDetails(valid : boolean){
   console.log("this.curCompanyList", this.companyDetails);
  if(!valid){
    this.submitted=true;
  }
  if(valid){
    if(this.fmonth && this.fyear){
      this.data.curcomp.FromDate = this.fmonth+' '+this.fyear;
    
    }
    if(this.tmonth == 'Till Date'){
      this.data.curcomp.ToDate = 'Till Date';
    } else {
      if(this.tmonth && this.tyear){
        this.data.curcomp.ToDate = this.tmonth+' '+this.tyear;
      }
      else{
        this.data.curcomp.ToDate = 'Till Date';
      }
    }    
    
    let exists = false;
    exists = (this.companyDetails as CompanyDetails[]).some(company =>
          company.CompanyName.toLowerCase() ===  this.data.curcomp.CompanyName.toLowerCase() &&
          company.FromDate ===  this.data.curcomp.FromDate &&
          company.Designation.toLowerCase() ===  this.data.curcomp.Designation.toLowerCase() &&
          company.JobLevel ===  this.data.curcomp.JobLevel
        );
    if(exists){
      this.dialogRef.close(null);
    }
    else{
      this.dialogRef.close(this.data.curcomp);
    }
  } else {
    console.log('Company detail form is not valid');
    return;
  }
}
}
