import { Component, OnInit, Input , Output,EventEmitter} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-job-alert',
  templateUrl: './jobalert.component.html',
})
export class JobAlert implements OnInit {
  @Input() jobalert: string;
  @Output() respaction = new EventEmitter<string>();
  
  constructor(private router: Router) { }

  ngOnInit() {
    
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  closeAlert(){
    this.respaction.emit('close');
  }
  


}
