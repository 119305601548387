import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import {Config} from '../config/index';
import { CareerPathSkill } from './careerpathskill.component';

@Component({
  selector: 'app-careerpath',
  templateUrl: './careerpaths.component.html',
})
export class CareerPaths implements OnInit {
  dialogRefCPS : any;
  skillDetails : any;
  userId : any;
  jobtemplatedetails : any;
  careerpathskillreq : any;
  successmessage : any;

  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<CareerPaths>, public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config) { }

  ngOnInit() {
    this.userId = sessionStorage.getItem('userid');
  }

  gotoPage(route){
    this.router.navigate([route]);
  }


  onCancel(): void { 
    this.dialogRef.close(); 
  }

  openSkills(id : any, joblevel : any){
    this.successmessage ='';
    console.log('CareerpathId : ',id , ' Job level : ', joblevel);
    this.careerpathskillreq ={};
    this.careerpathskillreq.id = id;
    this.careerpathskillreq.joblevel = joblevel;
    this.dialogRefCPS = this.dialog.open(CareerPathSkill, {
      width:'100%',
      height:'80%',
      closeOnNavigation : true,
      data: {cpskills : this.careerpathskillreq}
    });
  
    this.dialogRefCPS.afterClosed().subscribe(result => {
      console.log('Result of careerpath skill : ', result);
      if(result){
        this.successmessage = 'skills saved successfully';
      }
      
    });
  }







}
