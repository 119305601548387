import {CurrentCompany } from './index';
import { JobApplySkill } from './jobapplyskilldetails';
import { JobApplyAcademicDetails } from './jobapplyacademicdetails';
import { JobApplyCertification } from './jobapplycertifications';
 
export class JobApplyData {
CandidateId: any;
AdminId: any;
jobPostingId: any;
CandCurrentCTC: any;
CandECTC: any;
CandNp: any;
CurrentJobLevel: any;
ExpectedJobLevel: any;
ResumeLink: any;
TotalExp: any;
OtherInputs: any;
CupSkillRatingModels: JobApplySkill[];
CupAcademicDetail: JobApplyAcademicDetails[];
CupCertificationDetail: JobApplyCertification[];
AllocationStatus: any;
CurrentCompDetails: CurrentCompany[];
ReasonForJobChange: any;
HoldingAnyOffer: any;
RelevantExp: any;
PreferredLocations: any;
CUPCompanyDetails: any;
CandidatePhoto : any;
}
