import { Component, OnInit, Input,OnDestroy } from '@angular/core';
import {JobService} from '../services/index';
import {Response,SkillDetailsProfile, Skill,SkillBadgeDetails} from '../models/index';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SkillDetailsUpdate } from './skilldetailsupdate.component';
import { AddSkill } from './addskill.component';

@Component({
  selector: 'app-user-skill-details',
  templateUrl: './skilldetails.component.html',
})
export class SkillDetails implements OnInit,OnDestroy  {
  userId : any;
  Id: any;
  submitted : boolean;
  userInfo : Response;
  errormessage : any;
  successmessage : any;
  cities : any;
  states : any;
  dialogRef : any;
  skillDetails : any;
  skillDetail : SkillDetailsProfile;
  companyDetailsReq : any;
  professioncompletionscore : any;
  profilecompletionscore : any;
  deleteObj : any;
  saveorupdatereq : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;
  dataLoad: boolean = false;

  constructor(private jobService: JobService, private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.companyDetailsReq = [];
    this.saveorupdatereq = [];
    this.userInfo = new Response();
    this.skillDetail = new SkillDetailsProfile();
    this.Id = '';
    this.deleteObj = {};
    this.submitted = false;
    this.errormessage = '';
    this.successmessage = '';
    this.userId = sessionStorage.getItem('userid');
    //this.professioncompletionscore = sessionStorage.getItem('CompanyProfileScore');
    var compScore = sessionStorage.getItem('SkillProfileCompletionScore');

    if(!(compScore) || (compScore == 'null')){
      this.professioncompletionscore = '0';
    } else {
      this.professioncompletionscore = compScore;
    }
    this.profilecompletionscore = sessionStorage.getItem('ProfileCompletionScore');
    this.showalert = false;
    this.getSkillDetails();
  }

  ngOnDestroy(){
    console.log('In ngdestroy of user profile academic');
    if(this.dialogRef){
      console.log('Closing dialog');
      this.dialogRef.close();
    }
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  getSkillDetails(){
    this.skillDetails = [];
      console.log('userid from session : ', this.userId);
      this.jobService.getSkillDetails(this.userId).subscribe(
        data => {
                if(data.success == true){
                  this.skillDetails = data.Results;
                  this.dataLoad = true;
                  console.log("dafvdhbfu",this.dataLoad);
                  
                  if(this.skillDetails && this.skillDetails.length > 0){
                    this.professioncompletionscore = this.skillDetails[0].ProfileCompletionScore;
                    sessionStorage.setItem('SkillProfileCompletionScore', this.professioncompletionscore);
                  }
                  console.log('Skill details obtained : ', this.skillDetails);
                } else {
                  this.errormessage = data.error1;
                  this.responseMessage = data.error1;
                  this.professioncompletionscore = 0;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          this.responseMessage = 'Error occurred while getting user data';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          console.log('Error occurred : ', error);
              });

  }

  isOddIndex(index : any){
    
    if(index % 2 !== 0){
      return true;
    } else {
      return false;
    }
  }

openDialog(save : boolean, ob : any, skindex : number){
  this.successmessage = '';
  this.errormessage = '';
  let saveorupdate = '';
  if(save){
    this.skillDetail = new SkillDetailsProfile();
    saveorupdate = 'save';
  } else {
    this.skillDetail = ob;
    saveorupdate = 'update';
  }

  this.dialogRef = this.dialog.open(SkillDetailsUpdate, {
    width:'100%',
    height:'70%',
    closeOnNavigation : true,
    data: {skilldetail : this.skillDetail, skills : this.skillDetails,
      index : skindex, save : saveorupdate}
  });

  this.dialogRef.afterClosed().subscribe(result => {
    if(result){
      this.skillDetail = result;
      this.skillDetail.UserId = this.userId;
      console.log('Skill detail to be updated : ', this.skillDetail);
      this.updateSkillDetails();

    }
  });
}

openAddSkillDialog(){
  this.dialogRef = this.dialog.open(AddSkill, {
    width:'100%',
    height:'100%',
    closeOnNavigation : true,
    data: {}
  });

  this.dialogRef.afterClosed().subscribe(result => {
    console.log('in dialog close test');
    if(result){
      this.skillDetail = result;
      this.skillDetail.UserId =this.userId;
      console.log('Skill detail to be saved : ', this.skillDetail);
      this.createSkillDetails();

    }
    else
    {
        this.getSkillDetails();
    }

  });
}

updateSkillDetails(){
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;
    this.skillDetail.CertificationNames = [];
    console.log('In updateSkillDetails : ',this.skillDetail.Certifications );
    if(this.skillDetail.Certifications && this.skillDetail.Certifications.length > 0){
      for(var certobj of this.skillDetail.Certifications){
        this.skillDetail.CertificationNames.push(certobj.CertificationName);
      }
    }
    this.skillDetail.AdminId = this.userId;
    this.jobService.updateSkillDetails(this.skillDetail).subscribe(
      data => {
              if(data.success == true){
                this.professioncompletionscore = data.Result.SkillProfileCompletionScore;
                sessionStorage.setItem('SkillProfileCompletionScore', this.professioncompletionscore);
                this.profilecompletionscore = data.Result.TotalProfileCompletionScore;
                sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
                this.getSkillDetails();
                this.successmessage = 'Skill updated successfully';
                this.responseMessage = 'Skill updated successfully';
                this.typeOfResponseMessage = 'success';
                this.showalert = true;
              } else {
                this.errormessage = data.error;
                this.responseMessage = data.error;
                this.typeOfResponseMessage = 'error';
                this.showalert = true;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });

}

createSkillDetails(){
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;
    this.saveorupdatereq = [];
    this.skillDetail.CertificationNames = [];
    console.log('In createSkillDetails : ',this.skillDetail.Certifications );
    if(this.skillDetail.Certifications && this.skillDetail.Certifications.length > 0){
      for(var certobj of this.skillDetail.Certifications){
        this.skillDetail.CertificationNames.push(certobj.CertificationName);
      }
    }
    this.skillDetail.AdminId = this.userId;
    this.saveorupdatereq.push(this.skillDetail);
    this.jobService.createSkillDetails(this.saveorupdatereq).subscribe(
      data => {
              if(data.success == true){
                this.professioncompletionscore = data.Result.SkillProfileCompletionScore;
                sessionStorage.setItem('SkillProfileCompletionScore', this.professioncompletionscore);
                this.profilecompletionscore = data.Result.TotalProfileCompletionScore;
                sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
                this.getSkillDetails();
               
                this.successmessage = 'Skill saved successfully';
                this.responseMessage = 'Skill saved successfully';
                this.typeOfResponseMessage = 'success';
                this.showalert = true;
                console.log('Skill details obtained : ', this.skillDetails);
              } else {
                this.errormessage = data.error;
                this.responseMessage = data.error;
                this.typeOfResponseMessage = 'error';
                this.showalert = true;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });

}

deleteSkillDetailById(skillobj : any){
    this.deleteObj.Id = skillobj.Id;
    this.deleteObj.UserId = this.userId;
    this.deleteObj.AdminId = this.userId;
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;
    this.jobService.deleteSkillDetailById(this.deleteObj).subscribe(
      data => {
              if(data.success == true){
                this.successmessage = 'Skill deleted successfully';
                this.responseMessage = 'Skill deleted successfully';
                this.typeOfResponseMessage = 'success';
                this.showalert = true;
                this.getSkillDetails();
                
              } else {
                this.errormessage = data.error;
                this.responseMessage = data.error;
                this.typeOfResponseMessage = 'error';
                this.showalert = true;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });

}

closeAlert(event : any){
  if(event){
    this.showalert = false;
  }
}

}




