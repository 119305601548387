
export class JobApplySkill {
    
CreatedOn: any;
SelfRating: any;
SkillName: any;
Category: any;
isCore: any; // If Skill category = 'Technical' , then value is 1

AdminId: any;
CandidateId: any;
EmployerRating: any;
InternalRating: any;
SystemRating: any;
}
