import {Injectable} from '@angular/core';

@Injectable()
export class SessionService {


    /*to get auth token*/
    public getSessionToken() {
        const authToken = sessionStorage.getItem('sessiontoken');
        return authToken;
    }

    /* to set auth token in Session storage*/
    public setSessionToken(sessiontoken: string) {
        if (sessiontoken) {
            sessionStorage.setItem('sessiontoken', sessiontoken);
        }
    }
    
}
