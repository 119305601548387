import { Component, OnInit, Input } from '@angular/core';
import {JobService} from '../services/index';
import {UserInfo} from '../models/index';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './resetpassword.component.html',
})
export class ResetPassword implements OnInit {
  @Input() EmailId: string;
  @Input() otp: any;
  submitted : boolean;
  userInfo : UserInfo;
  initialpassword : any;
  finalpassword : any;
  errormessage : any;
  show : any;
  show1 : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;

  constructor(private jobService: JobService, private router: Router) { }

  ngOnInit() {
    this.userInfo = new UserInfo();
    this.userInfo.EmailId = this.EmailId;
    this.userInfo.Otp = this.otp;
    this.userInfo.Role = 'candidateUser';
    this.submitted = false;
    this.errormessage = '';
    this.show = false;
    this.show1 = false;
    this.showalert = false;
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  reset(valid: boolean){
    console.log('In reset password');
    this.submitted = true;
    this.showalert = false;
    this.responseMessage ='';
    this.typeOfResponseMessage = '';

    if(valid == true){
      if (this.initialpassword !== this.finalpassword) {
        
        this.errormessage = 'Password and re-entered password do not match.';
        this.responseMessage ='Password and re-entered password do not match.';
        this.typeOfResponseMessage = 'error';
        this.showalert = true;
        return;
      }
      this.userInfo.NewPass = this.finalpassword;
      this.jobService.resetpassword(this.userInfo).subscribe(
        data => {
          this.submitted = false;
                if(data.success == true){
                  this.router.navigate(['/login']);
                 
                } else {
                  this.errormessage = data.error;
                  this.responseMessage =data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          console.log('Error occurred : ', error);
              });
      
    } else {
      console.log('User form not valid');
    }
  }

  togglepassword(show : boolean){
    if(show){
      this.show = !this.show;
    } else {
      this.show1 = !this.show1;
    }
    
  }

    closeAlert(event : any){
    if(event){
      this.showalert = false;
    }
  }


}
