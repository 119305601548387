import { Component, OnInit,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import {Config} from '../config/index';
import {CareerPathSkills} from '../models/index';


@Component({
  selector: 'app-careerpath-skill',
  templateUrl: './careerpathskill.component.html',
})
export class CareerPathSkill implements OnInit {

  
  userId : any;
  skillsOfUser  : any;
  templateDetails : any;
  skillDetails : any;
  jobskills : CareerPathSkills[];
  skillBadges : any;
  skilldisabled : boolean;
  skillObject : any;
  dataload:boolean =false;
  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<CareerPathSkill>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.skillBadges = ['Have a skill', 'Planning to have', 'None'];
    this.config = new Config();
    this.userId = sessionStorage.getItem('userid');
    this.jobskills = [];
    this.skillDetails = [];
    this.skilldisabled = false;
    this.getSkillDetails();
    this.getJobTemplateDetailByCareerPathNJobLevel();
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  onCancel(): void { 
    this.dialogRef.close(); 
  }

  getName(skill : any){
    return skill.Skill;
  }

  getSkillDetails(){
    this.skillDetails = [];
    console.log('userid from session : ', this.userId);
    this.jobService.getSkillDetails(this.userId).subscribe(
      data => {
              if(data.success == true){
                this.skillDetails = data.Results;
                this.dataload = true;
                this.processSkillDetails();
                
                console.log('Skill details obtained : ', this.skillDetails);
              } else {
                console.log('Error message : ', data.error);
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });

}

processSkillDetails(){
  this.skillsOfUser = [];
  if(this.skillDetails && this.skillDetails.length > 0){
    for(var skill of this.skillDetails){
      this.skillsOfUser.push(skill.SkillName);
    }
  }
}

getJobTemplateDetailByCareerPathNJobLevel(){
  this.templateDetails = [];
  this.jobService.getJobTemplateDetailByCareerPathNJobLevel(this.data.cpskills.id, this.data.cpskills.joblevel).subscribe(
    data => {
            if(data.success == true){
              this.templateDetails = data.Result.JobTemplateDetails;
              this.processTemplateDetails();
            } else {
              console.log('Error message : ', data.error)
            }
    },
    error => {
      console.log('Error occurred : ', error);
          });

}

processTemplateDetails(){
  if(this.templateDetails.Skills && this.templateDetails.Skills.length > 0){
    for(var skill of this.templateDetails.Skills){
      this.jobskills.push(skill);
    }
  }
}

decideSkillOfUser(skill : any){
  console.log('In decideSkillOfUser : ', skill.Skill);
  for(var jobskill of this.skillsOfUser){
    if(jobskill == skill.Skill){
      console.log('Skill is present in user  ');
      skill.skillbadge = 'Have a skill';
      return true;
    }
  }
  return false;
}

save(){
  var finalSkillsArray = [];
  var fromStorage = sessionStorage.getItem('careerpathskills');

  if(fromStorage){
    finalSkillsArray = JSON.parse(fromStorage);
  }

  for(var skill of this.jobskills){
    this.skillObject = {};
    if(skill.skillbadge == 'Have a skill' || skill.skillbadge == 'Planning to have'){
      this.skillObject.skillname = skill.Skill;
      this.skillObject.category = skill.SkillCategory;
      finalSkillsArray.push(this.skillObject);
    }
  }
  if(finalSkillsArray && finalSkillsArray.length > 0){
    sessionStorage.setItem('careerpathskills', JSON.stringify(finalSkillsArray));
  }
  console.log('Final Skills array : ', finalSkillsArray);

  this.dialogRef.close('saved'); 
}



}
