import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-eduqual-update',
  templateUrl: './eduQualupdate.component.html',
})
export class EduQualUpdate implements OnInit {
  
  submitted : boolean;
  educationLevels : any;
  qualifications : any;
  specializations : any;
  errormessage : any;

  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<EduQualUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log('Input data obtained : ', this.data.academicdetail);
    this.getEducationLevels();
  }

  gotoPage(route){
    this.router.navigate([route]);
  }


  onCancel(): void { 
    this.dialogRef.close(); 
  }

  saveDetails(valid : boolean){

    if(valid){
      this.dialogRef.close(this.data.academicdetail);
    }
  }

getEducationLevels(){
  this.jobService.getEducationLevels().subscribe(
    data => {
            if(data.success == true){
              this.educationLevels = data.Results;
              this.getQualifications(this.data.academicdetail.QualCategory, true);
            } else {
              this.errormessage = data.error;
              console.log('Error message : ', data.error);
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
  }

  getQualifications(ob : any, update : boolean){
    console.log('in getQualifications : ', ob.value, ob);
    let edulevel = '';
    if(update){
      edulevel = ob;
    } else {
      edulevel = ob.value;
    }
    this.jobService.getQualifications(edulevel).subscribe(
      data => {
              if(data.success == true){
                this.qualifications = data.Results;
                this.getSpecializations(this.data.academicdetail.Qualification, true);
              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error);
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
  }

  getSpecializations(ob : any, update : boolean){
    console.log('in getSpecializations : ', ob);
    let qualification = '';
    if (update){
      qualification = ob;
    } else {
      qualification = ob.value;
    }
    this.jobService.getSpecializations(this.data.academicdetail.QualCategory, qualification).subscribe(
      data => {
              if (data.success == true){
                this.specializations = data.Results;
              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
  }




}
