import { Component, OnInit, Input ,Inject} from '@angular/core';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alertdialog.component.html',
})
export class AlertDialog implements OnInit {

  constructor(private router: Router, public dialogRef: MatDialogRef<AlertDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }



}
