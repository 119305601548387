import { Component, OnInit,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import {Config} from '../config/index';
import {SkillMaster,Skill, SkillDetailsProfile} from '../models/index';
import { SkillDetailsUpdate } from './skilldetailsupdate.component';



@Component({
  selector: 'app-add-skill',
  templateUrl: './addskill.component.html',
})
export class AddSkill implements OnInit {

  leadershipskills : SkillMaster[];
  technicalskills : SkillMaster[];
  scalas : SkillMaster[];
  skills : SkillMaster[];
  sskills : any;
  leaderships: any;
  scalables : any;
  technicals : any;
  softskills : SkillMaster[];
  skill : Skill;
  userId : any;
  skillDetail : SkillDetailsProfile;
  dialogRefSkill : any;
  skillname : any;
  selectedUser :any;
  filterdOptions : any;
  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<AddSkill>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.softskills = [];
    this.leadershipskills = [];
    this.technicalskills = [];
    this.scalas = [];
    this.config = new Config();
    this.userId = sessionStorage.getItem('userid');
    this.getSkills();
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  onCancel(): void { 
    this.dialogRef.close(); 
  }

  getSkills(){

    this.jobService.getSkills(this.userId).subscribe(
      data => {
              if(data.success == true){
                this.skills = data.Results;

                if(this.skills && this.skills.length > 0){
                  this.populateSkills();
                }

               
              } else {
                console.log('Error message : ', data.error)
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
    
    }

    populateSkills(){
      for(var skill of this.skills){
        if(skill.SkillType == 'Softskills'){
          this.softskills.push(skill);
          continue;
        }
        if(skill.SkillType == 'Leadership'){
          this.leadershipskills.push(skill);
          continue;
        }
        if(skill.SkillType == 'Technical'){
          this.technicalskills.push(skill);
          continue;
        }
        if(skill.SkillType == 'Value creation'){
          this.scalas.push(skill);
          continue;
        }
      }
    }

    openDialog(save : boolean, ob : any,category : any){
      var skillnm = [];
      skillnm = ob.value;
      console.log('Skill name selected : ', skillnm[0]);
      let saveorupdate = '';
      if(save){
        this.skillDetail = new SkillDetailsProfile();
        this.skillDetail.SkillName = skillnm[0];
        this.skillDetail.Category = category;
        saveorupdate = 'save';
      } 

      this.dialogRefSkill = this.dialog.open(SkillDetailsUpdate, {
        width:'100%',
        height:'70%',
        closeOnNavigation : true,
        data: {skilldetail : this.skillDetail, skills : '',
          index : '', save : saveorupdate}
      });
    
      this.dialogRefSkill.afterClosed().subscribe(result => {
        if(result){
          this.skillDetail = result;
          this.data.skilldetail = this.skillDetail;
          this.dialogRef.close(this.data.skilldetail);
          console.log('Skill detail to be saved : ', this.skillDetail);
    
        }
      });
    }

   filterFunction(value,type) {
      
      console.log("selectedUser",value);
      if(type == 'Technical'){
      let filterUsers = this.technicalskills.filter(
        item => item.SkillCategory3.toLowerCase().includes(value.toLowerCase())
      );
      
       if (filterUsers.length > 0) {
          this.technicalskills = filterUsers;
          console.log("technicalskills after filter",this.technicalskills);

      }  
    }

    if(type == 'Softskills'){
      let filterUsers = this.softskills.filter(
        item => item.SkillCategory3.toLowerCase().includes(value.toLowerCase())
      );
      
       if (filterUsers.length > 0) {
          this.softskills = filterUsers;
          console.log("technicalskills after filter",this.softskills);

      }  
    }
    if(type == 'Leadership'){
      let filterUsers = this.leadershipskills.filter(
        item => item.SkillCategory3.toLowerCase().includes(value.toLowerCase())
      );
      
       if (filterUsers.length > 0) {
          this.leadershipskills = filterUsers;
          console.log("technicalskills after filter",this.leadershipskills);

      }  
    }
   
    if(type == 'Value creation'){
      let filterUsers = this.scalas.filter(
        item => item.SkillCategory3.toLowerCase().includes(value.toLowerCase())
      );
      
       if (filterUsers.length > 0) {
          this.scalas = filterUsers;
          console.log("technicalskills after filter",this.scalas);

      }  
    }

} 


}
