
export class JobViewDetail {
    ViewId: any;
    JobPostingId: any;
    Role: any;
    showRefFees: any;
    showSalBudget: any;
    showJoiningBonus: any;
    showRelocationSup: any;
    showBuyNP: any;
    showSpReq: any;
    showJobPostedBy: any;
    showJobPostFor: any;
    showAuthorizedUser: any;
    showEligibleCurrentCTC: any;
    JobPostOwner: any;
    CreatedBy: any;
    CreatedOn: any;
    UpdatedBy: any;
    UpdatedOn: any;
    isJobPostPublished: any;
    ShortUrlId: any;
    AdminId: any;
    Message: any;
    CandStatus: any;
    RequestType: any;
    IsReadonly: any;
}
