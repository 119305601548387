import { VerificationDetails} from './index';

export class Response {
        Id: string;
        UserId: string;
        FirstName: string;
        LastName: string;
        EmailId: string;
        Password: string;
        Role: string;
        City: string;
        State: string;
        PlaceOfBirth: string;
        Dob: string;
        BloodGroup: string;
        MotherName: string;
        SessionToken: null;
        isActive: any;
        ProfileSubmittedBy: string;
        CreatedBy: string;
        isTnCAccepted: any;
        CreatedOn: any;
        UpdatedBy: string;
        UpdatedOn: string;
        ifScoresUpdated: string;
        Message: string;
        APIStartTime: string;
        SelectionOfCandidatesEndTime: string;
        GettingUserInfoTime: any;
        Mobile: any;
        Address : string;
        JobLevel: string;
        TotalExp: string;
        isActivelyLookingForJob: string;
        SkillNames: any;
        CandidateDetails: any;
        CupAcademicDetails: any;
        CupCertificationDetails: any;
        CUPCompanyDetails: any;
        CupProfessionalDetails: any;
        CandVisibilityDetails: any;
        CupSkillsDetails: any;
        CupSkillsDetails_rating: any;
        CandSkillBadgeDetails: any;
        EmployerUser: any;
        PartnerUser: any;
        PropmoUser: any;
        EmployerDetails: any;
        PartnerDetails: any;
        CareerPartnerDetails: any;
        ProSkiDetails: any;
        CandidateStatus: any;
        SelfAssessmentProSki: any;
        ProfileCompletion: any;
        BaseScoreForJobPost: any;
        CandidateTotalPoints: any;
        File: any;
        Company: any;
        AdminId: any;
        JobPostId: any;
        EmployerFeedback: any;
        EmployerAdminForJob: any;
        PropmoResumeLink: any;
        Message1: any;
        VerificationDetails: VerificationDetails;
        ProfileCompletionScore: any;
        PersonalCompletionScore: any;
        AcadProfileCompletionScore: any;
        AcadCompletionScore: any;
        TotalProfileCompletionScore: any;
        CompanyCompletionScore : any;
        CompanyProfileCompletionScore: any;
        AcadProSkiScore: any;
        LeadershipProSkiScore: any;
        SelfAcadProSkiScore : any;
        SkillsCompletionScore : any;
        SoftSkillProSkiScore : any;
        TechProSkiScore: any;
        ThirdPartyAcadProSki: any;
        ThirdPartyAssessmentProSki: any;
        ValueCreationProSkiScore : any;
        SelfAssesmentProSki : any;
        CandPhotoLink: any;

}
