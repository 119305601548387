
export class Job {
    Id: number;
    CompanyLogo:string;
    Domain : string;
    EligibleCTC : string;
    EmployementType : string;
    Industry : string;
    JobCity : string;
    JobLevel : string;
    JobPostedFor : string;
    JobReqId : string;
    JobPostTotalBaseScore : any;
    DescForShare : any;
    ShortUrlId : any;
    JobTitle : any;
}
