import { Component, OnInit } from "@angular/core";
import { JobFilter} from "../models/index";
import { Config } from "../config/index";
import { JobService } from "../services/index";
import {Router} from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-jobserachskills",
  templateUrl: "./jobserachskills.component.html",
  styleUrls: ["./jobserachskills.component.scss"],
})
export class JobserachskillsComponent implements OnInit {
  config: Config;
  selectedSkills: any;
  selectedExp: any;
  selectedLocation: string = "";
  selectedYears: string = "";
  showIframe: boolean = false;
  iframeUrl: string = "";
  safeUrl: any;
  jobFilter: JobFilter;
  cities: any;
  jobCity: any;
  skillDetails: any;
  userId: any;
  professioncompletionscore: any;
  professionDetails: any;
  userInfo: any;
  Id: any;
  dataLoad: boolean = true;
  constructor(
    private sanitizer: DomSanitizer,
    private jobService: JobService,
    private router: Router
  ) {}
 

   ngOnInit() {
    this.Id = "";
    this.jobFilter = new JobFilter();
    this.config = new Config();
    this.cities = this.config.getCitiesForJobPosts();
    this.userId = sessionStorage.getItem("userid");
    this.showIframe = false;
    this.getSkillDetails();
    this.getTotalExperience();
    this.getLocation();
    
    console.log("loadFramenfno",this.selectedLocation);
    console.log("nvnfknd",this.selectedSkills);
    console.log("fggng",this.selectedExp);
    setTimeout(() => {
      if(this.selectedSkills){
        this.loadIframe();
      }
     
    }, 5000);

    window.addEventListener('message', (event) => {
     console.log('Message from child:', event.data);
     sessionStorage.setItem('jobreqid',event.data);
     this.router.navigate(['/jobprofile' ,this.userId,this.userId,event.data]);
     
    });

    
    if(this.selectedLocation && this.selectedSkills && this.selectedExp){
     // this.loadIframe();
    }
  }
 

   getSkillDetails() {
    this.skillDetails = [];
    console.log("userid from session : ", this.userId);
    this.jobService.getSkillDetails(this.userId).subscribe(
      (data) => {
        if (data.success == true) {
          this.skillDetails = data.Results;
          if (this.skillDetails && this.skillDetails.length > 0) {
            this.professioncompletionscore =
              this.skillDetails[0].ProfileCompletionScore;
            sessionStorage.setItem(
              "SkillProfileCompletionScore",
              this.professioncompletionscore
            );
          }
          console.log("Skill details obtained : ", this.skillDetails);
          const skillNames = this.skillDetails.map((skill) =>
            skill.SkillName.toLowerCase()
          );
          this.selectedSkills = skillNames.join(", ");
          console.log( this.selectedSkills);
          //this.loadIframe();
        } else {
          console.log("Error message : ", data.error);
        }
      },
      (error) => {
        console.log("Error occurred : ", error);
      }
    );
  }
   getTotalExperience() {
    console.log("userid and id from session : ", this.userId);
    this.jobService.getCupProfessionalDetails(this.userId).subscribe(
      (data) => {
        if (data.success == true) {
          this.professionDetails = data.Result;
          console.log("skillsdetailsinyears", this.professionDetails);
          var totalExp = parseInt(this.professionDetails.TotalExp);
          console.log("tookfovo", totalExp, typeof totalExp);
          if (totalExp < 24) {
            this.selectedExp = "0-2";
          } else if (totalExp > 24 && totalExp <= 72) {
            this.selectedExp = "2-6";
          } else if (totalExp > 72 && totalExp <= 120) {
            this.selectedExp = "6-10";
          } else if (totalExp > 120 && totalExp <= 180) {
            this.selectedExp = "10-15";
          } else if (totalExp > 180 && totalExp <= 600) {
            this.selectedExp = "15-above";
          }
          
          console.log("totalExp", this.selectedExp);
         //this.loadIframe();
        } else {
        }
      },
      (error) => {
        console.log("Error occurred : ", error);
      }
    );
  }

   getLocation(){
    console.log("userid and id from session : ", this.userId, this.Id);
    this.jobService.getCandidateDetails(this.userId, this.Id).subscribe(
      (data) => {
        if (data.success == true) {
          this.userInfo = data.Result;
          this.selectedLocation = data.Result.PlaceOfBirth;
          console.log("userInfoskills", this.selectedLocation);
          //this.loadIframe();
        } else {
          console.log("Error message : ", data.error);
        }
      },
      (error) => {
        console.log("Error occurred : ", error);
      }
    );
  }

  loadIframe() {
    const baseUrl = "https://careerbuddyonline.com/api-job/";
    const queryString = `jobskill=${this.selectedSkills}&&jobsloc=${this.selectedLocation}&&${this.selectedExp}`;
    //const queryString = `E30E740111665717985A71A4CFD665BFBC26B1978CB80743D1735A791D619150`;
    this.iframeUrl = `${baseUrl}?${queryString}`;
    console.log("iframeUrl this.iframeUrl",this.iframeUrl);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeUrl
    );
    console.log("iframeUrl jobsearch",this.safeUrl);
    this.dataLoad = false;
    this.showIframe = true;
  }
}


