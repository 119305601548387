import { Component, OnInit, Input, Inject, OnChanges } from '@angular/core';
import { JobService } from '../services/index';
import { Response, SkillMaster, Skill, RelevantTemplate, RelevantTemplateResponse } from '../models/index';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Config } from '../config/index';
import { CareerPaths } from './careerpaths.component';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-company-details-update',
  templateUrl: './companydetailsupdate.component.html',
})
export class CompanyDetailsUpdate implements OnInit, OnChanges {
  userId: any;
  Id: any;
  submitted: boolean;
  userInfo: Response;
  errormessage: any;
  industries: any[] = [];
  joblevels: any[] = [];
  domains: any[] = [];
  functions: any[] = [];
  years: any;
  months: any;
  marktypes: any;
  softskills: SkillMaster[];
  skill: Skill;
  leadershipskills: SkillMaster[];
  technicalskills: SkillMaster[];
  scalas: SkillMaster[];
  skills: SkillMaster[];
  sskills: any;
  leaderships: any;
  scalables: any;
  technicals: any;
  fmonth: any;
  fyear: any;
  tmonth: any;
  tyear: any;
  techskillvalid: boolean;
  leadershipskillvalid: boolean;
  softskillvalid: boolean;
  scalabilityskillvalid: boolean;
  relevantTemplate: RelevantTemplate;
  relevantTemplateResponse: RelevantTemplateResponse[];
  updateScenario: boolean;
  dialogRefCP: any;
  fyearvalid: boolean;
  fmonthvalid: boolean;
  tyearvalid: boolean;
  tmonthvalid: boolean;
  tmonths: any;
  showYear: boolean;
  domainControl = new FormControl();
  functionControl = new FormControl();
  industryControl = new FormControl();
  joblevelControl = new FormControl();
  scalasControl = new FormControl();
  leadershipskillsControl = new FormControl();
  softskillsControl = new FormControl();
  technicalsControl = new FormControl();
  filteredOptions: Observable<any[]>;
  filteredfunctions: Observable<any[]>;
  filteredIndustry: Observable<any[]>;
  filteredJoblevel: Observable<any[]>;
  filteredleadershipskills: Observable<any[]>;
  filteredscalas: Observable<any[]>;
  filteredsoftskills: Observable<any[]>;
  filteredtechnicals: Observable<any[]>;
  lastFilter: string = '';
  selectedSkill: any;
  arrowIcon = 'keyboard_arrow_down';
  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<CompanyDetailsUpdate>,
    @Inject(MAT_DIALOG_DATA) public data: any, private config: Config, public dialog: MatDialog) { }

  ngOnChanges() {
    console.log('In ngonchanges of companydetailsupdate component');
  }
  ngOnInit() {
    this.userInfo = new Response();
    this.relevantTemplate = new RelevantTemplate();
    this.relevantTemplateResponse = [];
    this.softskills = [];
    this.leadershipskills = [];
    this.technicalskills = [];
    this.scalas = [];
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.showYear = true;
    this.userId = sessionStorage.getItem('userid');
    this.years = this.config.getYearsList();
    this.months = this.config.getMonthsList();
    this.tmonths = JSON.parse(JSON.stringify(this.months));
    this.tmonths.unshift('Till Date');
    this.marktypes = ['Percentage', 'GPA'];
    this.getIndustries();
    this.getDomains();
    this.getFunctions();
    this.getJobLevels();
    this.getSkills();
    this.techskillvalid = true;
    this.leadershipskillvalid = true;
    this.scalabilityskillvalid = true;
    this.softskillvalid = true;
    this.fmonthvalid = true;
    this.fyearvalid = true;
    this.tmonthvalid = true;
    this.tyearvalid = true;
    this.leaderships = [];
    this.scalables = [];
    this.sskills = [];
    this.technicals = [];

    if (this.data.save == 'update') {
      this.updateScenario = true;
      this.initializeExp();
    }
  }
  setupDomainFilter() {
    console.log("setupDomainFilter");
    this.filteredOptions = this.domainControl.valueChanges.pipe(
      startWith(''),
      map(name => this.filterlist(name, this.domains, 'DomainName')));
  }
  setupFunctionFilter() {
    this.filteredfunctions = this.functionControl.valueChanges.pipe(
      startWith(''),
      map(name => this.filterlist(name, this.functions, 'FunctionName')));
  }
  setupIndustryFilter() {
    this.filteredIndustry = this.industryControl.valueChanges.pipe(
      startWith(''),
      map(name => this.filterlist(name, this.industries, 'IndustryName')));

  }
  setupJoblevelFilter() {
    this.filteredJoblevel = this.joblevelControl.valueChanges.pipe(
      startWith(''),
      map(name => this.filterlist(name, this.joblevels, 'JobLevel')));
  }
  setupleadershipskillsFilter() {
    this.filteredleadershipskills = this.leadershipskillsControl.valueChanges.pipe(
      startWith<string | any[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(name => this.filterlist(name, this.leadershipskills, 'SkillCategory3')));
  }
  setupScalasFilter() {
    this.filteredscalas = this.scalasControl.valueChanges.pipe(
      startWith<string | any[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(name => this.filterlist(name, this.scalas, 'SkillCategory3')));

  }
  setupSoftskillsFilter() {
    this.filteredsoftskills = this.softskillsControl.valueChanges.pipe(
      startWith<string | any[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(name => this.filterlist(name, this.softskills, 'SkillCategory3')));
  }
  setuptechnicalFilter() {
    this.filteredtechnicals = this.technicalsControl.valueChanges.pipe(
      startWith<string | any[]>(''),
      map(value => typeof value === 'string' ? value : this.lastFilter),
      map(name => this.filterlist(name, this.technicalskills, 'SkillCategory3')));
  }
  filterlist(name: string, datalist: any[], filterby: string): any[] {
    if (filterby === 'SkillCategory3') {
      this.lastFilter = name;
    }
    const filterValue = name.toLowerCase();
    return datalist.filter(domain => domain[filterby].toLowerCase().includes(filterValue));
  }
  toggleSelection(lskills: any, allskill: any) {
    let i = 0;
    if (allskill === 'l') {
      if (!this.leaderships.includes(lskills)) {
        this.leaderships.push(lskills);
      }
      else {
        i = this.leaderships.findIndex(value => value.SkillCategory3 === lskills.SkillCategory3);
        this.leaderships.splice(i, 1);
      }
      this.leadershipskillsControl.setValue(this.leaderships);
    }
    else if (allskill === 's') {
      if (!this.scalables.includes(lskills)) {
        this.scalables.push(lskills);
      }
      else {
        i = this.scalables.findIndex(value => value.SkillCategory3 === lskills.SkillCategory3);
        this.scalables.splice(i, 1);
      }
      this.scalasControl.setValue(this.scalables);
    }
    else if (allskill === 'ss') {
      if (!this.sskills.includes(lskills)) {
        this.sskills.push(lskills);
      }
      else {
        i = this.sskills.findIndex(value => value.SkillCategory3 === lskills.SkillCategory3);
        this.sskills.splice(i, 1);
      }
      this.softskillsControl.setValue(this.sskills);
    }
    else if (allskill === 't') {
      if (!this.technicals.includes(lskills)) {
        this.technicals.push(lskills);
      }
      else {
        i = this.technicals.findIndex(value => value.SkillCategory3 === lskills.SkillCategory3);
        this.technicals.splice(i, 1);
      }
      this.technicalsControl.setValue(this.technicals);
    }
  }
  displaylskill(value: any[] | string): string | undefined {
    let displayValue: string = '';
    if (Array.isArray(value)) {
      value.forEach((lskills, index) => {
        if (index === 0) {
          console.log('lskills.SkillCategory', lskills.SkillCategory3);
          displayValue = lskills.SkillCategory3;
        } else {
          displayValue += ', ' + lskills.SkillCategory3;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }
  optionClicked(event: Event, lskills: any, allskill: string) {
    event.stopPropagation();
    this.toggleSelection(lskills, allskill);
  }
  decideYearDP(ob) {
    if (ob.value == 'Till Date') {
      console.log(ob);
      this.showYear = false;
    } else {
      this.showYear = true;
    }
  }

  initializeExp() {
    console.log('');
    var fyr = this.data.curcomp.FromDate.split(' ');
    if (fyr && fyr.length == 2) {
      this.fmonth = fyr[0];
      this.fyear = fyr[1];
    }
    if (this.data.curcomp.ToDate == 'Till Date') {
      this.tmonth = 'Till Date';
      this.showYear = false;
    } else {
      var tyr = this.data.curcomp.ToDate.split(' ');
      if (tyr && tyr.length == 2) {
        this.tmonth = tyr[0];
        this.tyear = tyr[1];
      }
    }
  }

  gotoPage(route) {
    this.router.navigate([route]);
  }


  getIndustries() {
    console.log('in getIndustries : ');

    this.jobService.getIndustries().subscribe(
      data => {
        if (data.success == true) {

          this.setupIndustryFilter();
          this.industries = data.Results;
          this.industries.sort((a, b) => a.IndustryName.localeCompare(b.IndustryName));

        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });
  }

  getDomains() {
    this.jobService.getDomains().subscribe(
      data => {
        if (data.success == true) {
          this.domains = data.Results;
          this.domains.sort((a, b) => a.DomainName.localeCompare(b.DomainName));
          this.setupDomainFilter();
          console.log("getdomain API", this.domains);
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });
  }

  getFunctions() {
    this.jobService.getFunctions().subscribe(
      data => {
        if (data.success == true) {
          this.functions = data.Results;
          this.functions.sort((a, b) => a.FunctionName.localeCompare(b.FunctionName));
          this.setupFunctionFilter();
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });

  }

  getJobLevels() {
    this.jobService.getJobLevels().subscribe(
      data => {
        if (data.success == true) {
          this.joblevels = data.Results;
          this.joblevels.sort((a, b) => a.JobLevel.localeCompare(b.JobLevel));
          this.setupJoblevelFilter();
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });

  }

  getSkills() {
    this.jobService.getSkills(this.userId).subscribe(
      data => {
        if (data.success == true) {
          this.skills = data.Results;
          if (this.skills && this.skills.length > 0) {
            this.populateSkills();
          }
          if (this.data.save == 'update') {
            // this.updateScenario = true;
            this.populateSkillsFromObject();
          }
          this.setupleadershipskillsFilter();
          this.setupScalasFilter();
          this.setupSoftskillsFilter();
          this.setuptechnicalFilter();
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });
  }

  populateSkills() {
    for (var skill of this.skills) {
      if (skill.SkillType == 'Softskills') {
        this.softskills.push(skill);
        continue;
      }
      if (skill.SkillType == 'Leadership') {
        this.leadershipskills.push(skill);
        continue;
      }
      if (skill.SkillType == 'Technical') {
        this.technicalskills.push(skill);
        continue;
      }
      if (skill.SkillType == 'Value creation') {
        this.scalas.push(skill);
        continue;
      }
    }
    this.softskills.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
    this.leadershipskills.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
    this.technicalskills.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
    this.scalas.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  getRelevantTemplates() {
    if (this.data.companydetail.Industry && this.data.companydetail.Domain &&
      this.data.companydetail.Function && this.data.companydetail.JobLevel) {
      this.relevantTemplate.Domain = this.data.companydetail.Domain;
      this.relevantTemplate.Industry = this.data.companydetail.Industry;
      this.relevantTemplate.Function = this.data.companydetail.Function;
      this.relevantTemplate.JobLevel = this.data.companydetail.JobLevel;

      this.jobService.getRelevantTemplates(this.relevantTemplate).subscribe(
        data => {
          if (data.success == true) {
            this.relevantTemplateResponse = data.Results;
            this.populateJobLevelForTemplate();
            this.openCareerPathDialog();
            console.log('relevant templates received : ', this.relevantTemplateResponse);
          } else {

            this.errormessage = data.error;
            console.log('Error message : ', data.error)
          }
        },
        error => {
          this.submitted = false;
          console.log('Error occurred : ', error);
        });
    }
  }

  populateJobLevelForTemplate() {
    if (this.relevantTemplateResponse && this.relevantTemplateResponse.length > 0) {
      for (var reltemplate of this.relevantTemplateResponse) {
        reltemplate.JobLevel = this.data.companydetail.JobLevel;
      }
    }
  }

  openCareerPathDialog() {
    this.dialogRefCP = this.dialog.open(CareerPaths, {
      width: '100%',
      height: '50%',
      closeOnNavigation: true,
      data: { templates: this.relevantTemplateResponse }
    });
    this.dialogRefCP.afterClosed().subscribe(result => {
      this.processCareerPathSkills();
    });
  }

  processCareerPathSkills() {
    console.log('In processCareerPathSkills');
    var fromStorage = sessionStorage.getItem('careerpathskills');
    console.log('fromStorage 1 : ', fromStorage);
    if (fromStorage) {

      var cpathskills = JSON.parse(fromStorage);
      console.log('fromStorage 2 : ', cpathskills);

      if (cpathskills) {
        for (var cpskill of cpathskills) {
          console.log('cpskill : ', cpskill);
          console.log('cpskill name : ', cpskill.skillname);
          if (cpskill.category == 'Technical') {
            console.log('Pushing to technical');
            this.technicals.push(cpskill.skillname);
            continue;
          }
          if (cpskill.category == 'Softskills') {
            console.log('Pushing to softskill');
            this.sskills.push(cpskill.skillname);
            continue;
          }
          if (cpskill.category == 'Leadership') {
            console.log('Pushing to leadership');
            this.leaderships.push(cpskill.skillname);
            continue;
          }
          if (cpskill.category == 'Value creation') {
            console.log('Pushing to value creation');
            this.scalables.push(cpskill.skillname);
            continue;
          }
        }
        console.log('technicals array formed : ', this.technicals);
        console.log('softskills array formed : ', this.sskills);
        console.log('leadership array formed : ', this.leaderships);
        console.log('scalables array formed : ', this.scalables);
      }
    }
  }

  saveDetails(valid: boolean, formobj: any) {
    console.log('Form object : ', formobj);
    this.submitted = true;

    if (!this.technicals) {
      this.techskillvalid = false;
    }
    if (!this.scalables) {
      this.scalabilityskillvalid = false;
    }
    if (!this.sskills) {
      this.softskillvalid = false;
    }

    if (!this.sskills) {
      this.leadershipskillvalid = false;
    }

    if (!this.updateScenario) {
      if (!this.fmonth) {
        this.fmonthvalid = false;
      }

      if (!this.fyear) {
        this.fyearvalid = false;
      }

      if (!this.tmonth) {
        this.tmonthvalid = false;
      }

      if (!this.tyear) {
        this.tyearvalid = false;
      }
    }

    if (valid) {
      this.populateSkillsToObject(true);
      if (this.fmonth && this.fyear) {
        this.data.companydetail.FromDate = this.fmonth + ' ' + this.fyear;
      }
      console.log("this.tmonth", this.tmonth, this.fmonth);
      if (this.tmonth == undefined) {
        this.tmonth = 'Till Date';
      }
      if (this.tmonth == 'Till Date') {
        this.data.companydetail.ToDate = 'Till Date';
      } else {
        if (this.tmonth && this.tyear) {
          this.data.companydetail.ToDate = this.tmonth + ' ' + this.tyear;
        }
      }
      console.log('Company detail object created : ', this.data.companydetail);
      console.log('Company detail form is valid');
      this.dialogRef.close(this.data.companydetail);
    } else {
      console.log('Company detail form is not valid');
      return;
    }

  }

  populateSkillsToObject(save: boolean) {

    if (save) {
      this.data.companydetail.skills = [];
    }
    for (var technicalskill of this.technicals) {
      this.skill = new Skill();
      this.skill.SkillName = technicalskill.SkillCategory3;
      this.skill.Category = 'Technical';
      this.data.companydetail.skills.push(this.skill);
    }

    for (var softskill of this.sskills) {
      this.skill = new Skill();
      this.skill.SkillName = softskill.SkillCategory3;
      this.skill.Category = 'SoftSkill';
      this.data.companydetail.skills.push(this.skill);
    }

    for (var leadership of this.leaderships) {
      this.skill = new Skill();
      this.skill.SkillName = leadership.SkillCategory3;
      this.skill.Category = 'Leadership';
      this.data.companydetail.skills.push(this.skill);
    }

    for (var scalable of this.scalables) {
      this.skill = new Skill();
      this.skill.SkillName = scalable.SkillCategory3;
      this.skill.Category = 'Scalability';
      this.data.companydetail.skills.push(this.skill);
    }

    console.log('Skills selected : ', this.data.companydetail.skills);
    this.softskills.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
    this.leadershipskills.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
    this.technicalskills.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
    this.scalas.sort((a, b) => a.SkillCategory3.localeCompare(b.SkillCategory3));
  
  }

  populateSkillsFromObject() {

    for (var skill of this.data.companydetail.skills) {
      if (skill.Category == 'Technical') {
        var skills = this.getSkillsArray(skill.SkillName);
        for (var skillname of skills) {
          this.selectedSkill = this.filterlist(skillname, this.technicalskills, 'SkillCategory3');
          for (var s of this.selectedSkill) {
            this.technicals.push(s);
          }
        }
        continue;
      }

      if (skill.Category == 'SoftSkill') {
        var skills = this.getSkillsArray(skill.SkillName);
        for (var skillname of skills) {
          this.selectedSkill = this.filterlist(skillname, this.softskills, 'SkillCategory3');
          for (var s of this.selectedSkill) {
            this.sskills.push(s);
          }
        }
        continue;
      }

      if (skill.Category == 'Leadership') {
        var skills = this.getSkillsArray(skill.SkillName);
        for (var skillname of skills) {
          this.selectedSkill = this.filterlist(skillname, this.leadershipskills, 'SkillCategory3');
          for (var s of this.selectedSkill) {
            this.leaderships.push(s);
          }
        }
        continue;

      }

      if (skill.Category == 'Scalability') {
        var skills = this.getSkillsArray(skill.SkillName);
        for (var skillname of skills) {
          this.selectedSkill = this.filterlist(skillname, this.scalas, 'SkillCategory3');
          for (var s of this.selectedSkill) {
            this.scalables.push(s);
          }
        }
        continue;
      }
    }
    this.leadershipskillsControl.setValue(this.leaderships);
    this.technicalsControl.setValue(this.technicals);
    this.softskillsControl.setValue(this.sskills);
    this.scalasControl.setValue(this.scalables);
  }
  setallfiltervalue(allskill: string, lskills: any): Boolean {
    if (allskill === 'l') {
      if (this.leaderships.includes(lskills)) {
        return true;
      }
    }
    if (allskill === 's') {
      if (this.scalables.includes(lskills)) {
        return true;
      }
    }
    if (allskill === 'ss') {
      if (this.sskills.includes(lskills)) {
        return true;
      }
    }
    if (allskill === 't') {
      if (this.technicals.includes(lskills)) {
        return true;
      }
    }
    return false;

  }

  getSkillsArray(str: any) {
    if (str) {
      var str1 = str.substr(1, (str.length) - 2);
      var strarr = str1.split(',');
      return strarr;
    }
  }


}
