
export class JobLevelCertification {
    Id:any;
    JobReqId:any;
    CertificationName:any;
    Need:any;
    CreatedBy:any;
    CreatedOn:any;
    UpdatedBy:any;
    UpdatedOn:any;
    AdminId:any;
    isPresentInCand:any;
    UserId: any;
    selected : any;
    
}
