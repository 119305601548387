export * from './userprofile.component';
export * from './personaldetailsupdate.component';
export * from './userprofileacademic.component';
export * from './academicdetailsupdate.component';
export * from './companydetails.component';
export * from './companydetailsupdate.component';
export * from './skilldetails.component';
export * from './professionaldetails.component';
export * from './visadetailsupdate.component';
export * from './langdetailsupdate.component';
export * from './professionaldetailsupdate.component';
export * from './skilldetailsupdate.component';
export * from './certdetailsupdate.component';
export * from './addskill.component';
export * from './careerpaths.component';
export * from './careerpathskill.component';


