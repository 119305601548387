import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
const { App } = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router, private zone: NgZone
  ) {
    this.initializeApp();
  }

  initializeApp() {
    

    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
          // Example URL: https://example.com/signup?ref=1234           
          const slug = data.url.split("#")[0];
          if (slug == 'https://careers.careerbuddyonline.com/' || slug == 'https://careersuat.careerbuddyonline.com/') {
              var jobpart = data.url.split("#")[1];
              var jobpostid = jobpart.split("/")[2];
              sessionStorage.setItem('jobreqid', jobpostid);
              sessionStorage.setItem('shared', 'true');
              this.router.navigateByUrl('/alertlogin');
          }
          // If there's no match do nothing
      });
  });

  this.platform.ready().then(() => {
    this.statusBar.styleDefault();
    this.splashScreen.hide();
  });
  }
}
