import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Config} from '../config/index';


@Component({
  selector: 'app-cert-details-update',
  templateUrl: './certdetailsupdate.component.html',
})
export class CertDetailsUpdate implements OnInit {
  certifications : any;

  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<CertDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config) { }

  ngOnInit() {
    this.getCertifications();
  }


  getCertifications(){

    this.jobService.getCertifications().subscribe(
      data => {
              if(data.success == true){
                this.certifications = data.Results;

              } else {
                console.log('Error message : ', data.error)
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
    
    }

  gotoPage(route){
    this.router.navigate([route]);
  }


  onCancel(): void { 
    this.dialogRef.close(); 
  }

  saveDetails(valid : boolean){

  if(valid){
    this.dialogRef.close(this.data.certdetail);
  } else {
    return;
  }

}


}
