
export class CurrentCompany {
    Designation: any;
    CompanyName: any;
    FromDate: any;
    ToDate: any;
    JobLevel: any;
    UserId: any;
    Id : any;

}
