import { Component, OnInit, Input } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import {JobService} from '../services/index';
import { JobPostingDetail, JobLevelSkill,JobViewDetail } from '../models';
import {Config} from '../config/index';


@Component({
  selector: 'app-job-profile',
  templateUrl: './jobprofile.component.html',
})
export class JobProfile implements OnInit {
  jobdata : JobPostingDetail;
  userId: any;
  adminId: any;
  errormessage : any;
  techskills : JobLevelSkill[];
  softskills : JobLevelSkill[];
  leadershipskills : JobLevelSkill[];
  jobViewDetail : JobViewDetail;
  config : Config;
  interviewModes : any;
  jobId: any;
  showData : boolean;
  isShared : any;
  isShown:boolean =true;

  constructor(private router: Router,private jobService : JobService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    console.log('Job ID:', id);
    sessionStorage.setItem('jobreqid',id);
    this.showData = false;
    this.useridExists();
    this.userId = sessionStorage.getItem('userid');
    this.jobId = sessionStorage.getItem('jobreqid');
    this.isShared = sessionStorage.getItem('shared');
    this.config = new Config();
    this.techskills = [];
    this.softskills = [];
    this.leadershipskills = [];
    this.interviewModes = [];
    this.getJobPostsDetail();
   
  }
  useridExists()
  {
    if(sessionStorage.getItem('userid')==null)
    {
      this.router.navigate(['/welcome']);
    }
  }

  populateSkills(){
    if(this.jobdata.JobLevelSkills){
      for(var skill of this.jobdata.JobLevelSkills){
        if(skill.SkillCategory == 'Technical'){
          this.techskills.push(skill);
          continue;
        }
        if(skill.SkillCategory == 'Leadership'){
          this.leadershipskills.push(skill);
          continue;
        }
        if(skill.SkillCategory == 'Softskills'){
          this.softskills.push(skill);
          continue;
        }
    }
  }
  }

  getJobPostsDetail(){
    console.log('In getJobPostsDetailById');

    if(this.isShared && this.isShared == 'true'){
      this.getViewDetailById();
    } else {
      this.getJobPostingDetailsById();
    }
  }

  getJobPostingDetailsById(){
    console.log('In getJobPostingDetailsById');

    this.jobService.getJobPostingDetailsById(this.userId, this.userId, this.jobId).subscribe(
      data => {

              this.jobdata = data.Result;
              this.populateSkills();
              this.processInterviewModes();
              this.showData = true;

      },
      error => {
        console.log('Error occurred : ', error);
            });
  }

  getViewDetailById(){
    console.log('In getViewDetailById');

    this.jobService.getViewDetailsByShortUrlId(this.jobId).subscribe(
      data => {

        this.jobViewDetail = data.Result;
        this.jobId = this.jobViewDetail.JobPostingId;
        sessionStorage.setItem('jobreqid', this.jobId);
        sessionStorage.removeItem('shared');
        this.getJobPostingDetailsById();
      },
      error => {
        console.log('Error occurred : ', error);
            });
  }

  

  getSkillRatingDesc(rating){
    return this.config.getSkillRatingDescription(rating);
  }

  convertMonthToYear(monthval) : any {
    if(monthval <=12){
      return monthval+' Years';
    } else {
      if(monthval % 12 == 0){
        return (monthval / 12)+' Years';
      } else {
        var months = monthval % 12;
        return ((monthval - months) / 12)+ ' Years '+ months+ ' Months';
      }
    }
    
  }

  processInterviewModes(){
    this.interviewModes= [];
    if(this.jobdata.InterviewMode){
      var jsonParsed = JSON.parse(this.jobdata.InterviewMode);
      const objKeys = Object.keys(jsonParsed);
      console.log('Object keys : ', objKeys);
      const objValues = Object.values(jsonParsed);
  
      for(var i =0;i<objKeys.length;i++){
        var strkey = objKeys[i];
        console.log('STR : ', strkey);
        var supscript = this.getSuperScripts(strkey);
        var finalString = strkey+supscript+' Round:-'+objValues[i];
        console.log('Final string : ', finalString);
        this.interviewModes.push(finalString);
      }
  
    }
    
  }

  getSuperScripts(value){
    if(value == 1){
      return 'st';
    }
    if(value == 2){
      return 'nd';
    }

    if(value == 3){
      return 'rd';
    }

    if(value >= 4 && value<=10){
      return 'th';
    }
  }

  applyForJob(){
    this.router.navigate(['/jobapply']);
  }

  scrollToBottom(){
    console.log("scrollbottom");
    document.getElementById("applyjob").scrollIntoView();
    this.isShown = false;
    
    //window.scrollBy(0, 500);
    //window.scrollTo(0,document.body.scrollHeight);


  }

  myFunction() {
  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  if (dots.style.display === "none") {
    dots.style.display = "inline";
    btnText.innerHTML = "Read more";
    moreText.style.display = "none";
  } else {
    dots.style.display = "none";
    btnText.innerHTML = "Read less";
    moreText.style.display = "inline";
  }
  }
  


  /*getJobPostingDetailsById(){
    this.jobService.getJobPostingDetailsById(this.userId,this.userId,this.jobid).subscribe(
      data => {
              if(data.success == true){
                
                console.log('Job details obtained : ');
              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
  }
   

  


}*/
}
