
export class VerificationDetails {
    Id: number;
    UserId: string;
    EmailId: string;
    Mobile: string;
    VerificationCodeForEmail: string;
    VerificationCodeForMobile: string;
    isEmailVerified: number;
    isMobileVerified: number;
    CreatedBy: string;
    CreatedOn: any;
    UpdatedBy: any;
    UpdatedOn: any;
    ResendFlag: any;
    DecryptedPass: any;
    Role: any;
}
