import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {SkillMaster,SkillDetailsProfile,JobLevelSkill,CupSkillCertification} from '../models/index';
import {Router} from '@angular/router';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import {Config} from '../config/index';
import { CertDetailsUpdate } from './certdetailsupdate.component';

@Component({
  selector: 'app-skill-details-update',
  templateUrl: './skilldetailsupdate.component.html',
})
export class SkillDetailsUpdate implements OnInit {
  userId : any;
  Id: any;
  submitted : boolean;
  errormessage : any;
  successmessage : any;
  skills : SkillMaster[];
  skillbadges : any;
  updateScenario : boolean;
  certifications : any;
  certification : CupSkillCertification;
  skillDetails : SkillDetailsProfile;
  booleanvalues : any;
  skillbadge : any;
  dialogRefCert : any;
  skillname : any;
  certificationReq : any;
  badgedetails : any;
  badgedetailobj : any;
  skillslist : any;
  skillindex : number;
  curindex : number;
  showupdatenext : any;
  showAddCertification : boolean;

  constructor(private jobService: JobService, private router: Router, public dialog: MatDialog,
    public dialogRef: MatDialogRef<SkillDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config) { }

  ngOnInit() {
    this.config = new Config();
    this.submitted = false;
    this.skillname = '';
    this.errormessage = '';
    this.certificationReq= [];
    this.badgedetails = [];
    this.badgedetailobj = {};
    this.userId = sessionStorage.getItem('userid');
    this.skillbadges = this.config.getSkillBadges();
    this.booleanvalues = [{'key': 1,'value':'yes'}, {'key': 0,'value':'no'}];
    this.getCertifications();
    console.log('data skill details : ', this.data.skilldetail);
     if(this.data.skilldetail.BadgeDetails && this.data.skilldetail.BadgeDetails.length > 0){
        this.skillbadge = this.data.skilldetail.BadgeDetails[0].SkillBadge;
     }
    this.skillname = this.data.skilldetail.SkillName;
    this.skillslist = this.data.skills;
    this.skillindex = this.data.index;
    this.curindex = this.skillindex +1;
    if(this.data.save == 'update'){
      this.showupdatenext = true;
      this.toggleCertAdd('');
    }
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  toggleCertAdd(value : any){
    console.log('In toggleCert Add value : ', value);
    if(value){
      if(value.value == 1){
        this.showAddCertification = true;
      } else {
        this.showAddCertification = false;
      }
      return;
    }
    if(this.data.skilldetail.isCertified == 1){
      this.showAddCertification = true;
    } else {
      this.showAddCertification = false;
    }
  }


  getCertifications(){

    this.jobService.getCertifications().subscribe(
      data => {
              if(data.success == true){
                this.certifications = data.Results;

              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
    }



onCancel(): void { 
  this.dialogRef.close(); 
}



saveDetails(valid : boolean, isupdatenext : boolean){
  this.submitted = true;

  if(valid){
   
    console.log('Skill detail object created : ', this.data.skilldetail);
    if(this.data.skilldetail.BadgeDetails && this.data.skilldetail.BadgeDetails.length > 0){
       this.data.skilldetail.BadgeDetails[0].SkillBadge= this.skillbadge;

   } else {
    this.badgedetailobj.CandidateId = this.userId;
    this.badgedetailobj.Skill = this.skillname;
    this.badgedetailobj.SkillBadge = this.skillbadge;
    this.badgedetailobj.SkillCategory = this.data.skilldetail.Category;

    this.badgedetails.push(this.badgedetailobj);
    this.data.skilldetail.BadgeDetails = this.badgedetails;

   }
    if(isupdatenext){
      this.updateSkillDetails();
    } else {
      this.dialogRef.close(this.data.skilldetail);
    }
  } else {
    console.log('Skill detail form is not valid');
    return;
  }

}

updateSkillDetails(){
  console.log('Current index : ', this.curindex);
  console.log('Skills length : ', this.data.skills.length);
  this.data.skilldetail.CertificationNames = [];
  if(this.data.skilldetail.Certifications && this.data.skilldetail.Certifications.length > 0){
    for(var certobj of this.data.skilldetail.Certifications){
      this.data.skilldetail.CertificationNames.push(certobj.CertificationName);
    }
  }
  this.data.skilldetail.AdminId = this.userId;
  this.jobService.updateSkillDetails(this.data.skilldetail).subscribe(
    data => {
            if(data.success == true){
             // this.getSkillDetails();

             if(this.curindex >= this.data.skills.length){
              this.successmessage = 'You have updated all skills successfully.. Thank you';
             } else {
              this.data.skilldetail = this.data.skills[this.curindex];
              console.log('Skill detail coming up next : ', this.data.skilldetail);
              this.curindex += 1;
              document.getElementById('namefield').scrollIntoView();
             }
             
            } else {
              this.errormessage = data.error;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });

}

getSliderLabel(skillObject : JobLevelSkill){
  if(skillObject.SelfRating == 'null' || !skillObject.SelfRating){
    return '()';
  }else {
    return '('+this.config.getSkillRatingDescriptionAlongWithValue(skillObject.SelfRating)+')';

  }
}

openCertificateDialog(){
  
  this.certification = new CupSkillCertification();
  this.dialogRefCert = this.dialog.open(CertDetailsUpdate, {
    width:'100%',
    height:'50%',
    closeOnNavigation : true,
    data: {certdetail : this.certification}
  });

  this.dialogRefCert.afterClosed().subscribe(result => {
    console.log('in dialog close test');
    if(result){
      this.certification = result;
      this.certification.UserId = this.userId;
      this.certification.AdminId = this.userId;
      this.certification.SkillName = this.skillname;
      this.certificationReq.push(this.certification);

      if(this.data.skilldetail.Certifications && this.data.skilldetail.Certifications.length > 0){
        this.data.skilldetail.Certifications.push(this.certification);
      } else {
        this.data.skilldetail.Certifications = [];
        this.data.skilldetail.Certifications.push(this.certification);
      }
      
      this.UpdateCertifications();
    }
    
  });
}

getNameForCert(certObj : any, index : any, type : any){
  if(type == 'name'){
    return certObj.CertificationName+index;
  }

  if(type == 'issue'){
    return certObj.IssuingAuthority+index;
  }

  if(type == 'year'){
    return certObj.Year+index;
  }

  if(type == 'remark'){
    return certObj.Remark+index;
  }

  if(type == 'link'){
    return certObj.ResultUrl+index;
  }

}

UpdateCertifications(){

    this.jobService.createCertificationDetails(this.certificationReq).subscribe(
      data => {
              if(data.success == true){

              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
    }

    deleteCertification(index : any){
      console.log('in Delete certifications : ', index);
      this.data.skilldetail.Certifications.splice(index, 1);
    }

    getCertificationOption(selectedValue : any){
      
    }



}
