import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { JobService } from "../services/index";

@Component({
  selector: 'app-availablecareer',
  templateUrl: './availablecareer.component.html',
  styleUrls: ['./availablecareer.component.scss'],
})
export class AvailablecareerComponent implements OnInit {
  @Input() hideNav: boolean;
  showIframe: boolean = false;
  safeUrl: any;
  dataLoad: boolean = true;
  iframeUrl: string = "";
  skillDetails:any;
  userId:any;
  responseMessage:any;
  selectedSkills:any;
  badgeArray:any;
  errormessage:any;
  typeOfResponseMessage:any;
  showalert:any;

  constructor(
    private sanitizer: DomSanitizer,
    private jobService: JobService,
  ) { }

  ngOnInit() {
    this.showIframe = false;
    this.getSkillDetails();
    setTimeout(() => {
      this.loadIframe();
    }, 5000);
  }

  loadIframe() {
    const baseUrl = "https://careerbuddyonline.com/training-program/";
    const queryString = `Skill&&${this.selectedSkills}`;
   // this.iframeUrl = `${baseUrl}?${queryString}`;
   this.iframeUrl = `${baseUrl}`;
    console.log("iframeUrl",this.iframeUrl)
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.iframeUrl
    );
    this.dataLoad = false;
    this.showIframe = true;
  }

  getSkillDetails() {
    this.skillDetails = [];
    this.userId = sessionStorage.getItem("userid");
    this.jobService.getSkillDetails(this.userId).subscribe(
      (data) => {
        if (data.success == true) {
          this.skillDetails = data.Results;
        
          if (this.skillDetails && this.skillDetails.length > 0) {
            sessionStorage.setItem(
              "SkillProfileCompletionScore",
              this.skillDetails[0].ProfileCompletionScore
            );
          }
          const resultObject: { [key: string]: string[] } = {};
          const skillNames = this.skillDetails.map((skill) =>
            skill.SkillName.toLowerCase()
          );
          this.selectedSkills = skillNames.join(", ");
          console.log( this.selectedSkills);
          // Iterate through the input array and populate the resultObject
          this.skillDetails.forEach((item:any) => {
            if(item.BadgeDetails && item.BadgeDetails.length > 0 && item.BadgeDetails[0].SkillBadge){
            const skillBadge = item.BadgeDetails[0].SkillBadge;
            const skill = item.BadgeDetails[0].Skill;

            if (!resultObject[skillBadge]) {
              resultObject[skillBadge] = [skill];
            } else {
              resultObject[skillBadge].push(skill);
            }}
          });

          // Convert the resultObject to an array of objects
          const resultArray = Object.keys(resultObject).map((key) => ({
            [key]: resultObject[key],
          }));
           this.badgeArray = resultArray;
        } else {
          this.errormessage = data.error1;
          this.responseMessage = data.error1;
          this.typeOfResponseMessage = "error";
          this.showalert = true;
          console.log("Error message : ", data.error);
        }
      },
      (error) => {
        this.responseMessage = "Error occurred while getting user data";
        this.typeOfResponseMessage = "error";
        this.showalert = true;
        console.log("Error occurred : ", error);
      }
    );
  }
}
