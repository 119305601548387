import { CupSkillCertification } from './index';

export class CupSkillDetails {
    AdminId: any;
BadgeDetails:any;
Category: any;
Certifications: CupSkillCertification[];
Duration: any;
EmployerRating: any;
Id: any;
InternalRating: any;
LastUsed:any;
SelfRating:any;
SkillName:any;
UserId: any;
isCertified: any;
isCore:any;
}
