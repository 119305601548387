import { Component, OnInit,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {ProfessionDetails,VisaDetails,LanguageDetails} from '../models/index';
import {Router} from '@angular/router';
import { MatDialog,MAT_DIALOG_DATA,MatDialogRef } from '@angular/material/dialog';
import {Config} from '../config/index';
import { VisaDetailsUpdate } from './visadetailsupdate.component';
import { LanguageDetailsUpdate } from './langdetailsupdate.component';

@Component({
  selector: 'app-prof-details-update',
  templateUrl: './professionaldetailsupdate.component.html',
})
export class ProfessionalDetailsUpdate implements OnInit  {
  userId : any;
  Id: any;
  submitted : boolean;
  errormessage : any;
  successmessage : any;
  cities : any;
  states : any;
  dialogRefvisaLang : any;
  professionDetails : ProfessionDetails;
  profCompletionScore : any;
  noInfoAdded : boolean;
  years: any;
  months : any;
  config : Config;
  joblevels : any;
  jobavailability : any;
  parttime : boolean;
  fulltime : boolean;
  permanentjobtype : any;
  contracthirejobtype : any;
  contractjobtype : any;
  uploaderrormsg : any;
  uploadsuccessmsg : any;
  visaDetails : VisaDetails[];
  visaDetail : VisaDetails;
  langDetails : LanguageDetails[];
  langDetail : LanguageDetails;
  uploadreq : any;
  selectedFile : File;
  totalyears : any;
  showAvailForm : boolean;
  noofyears : any;
  noofmonths : any;
  showNpForm : boolean;
  showCoi : boolean;
  languages : any;
  showAddLangButton : boolean;
  isEctcNegotiable : boolean;
  isCoi : boolean;
  isDifferentlyAbled : boolean;
  canRelocate : boolean;
  canWorkInShifts : boolean;
  islookingforjob : boolean;
  isreadyforoverseas : boolean;
  isnpnegotiable : boolean;
  uploadedfilename : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;
  validFileFormats : any;

  constructor(private jobService: JobService, private router: Router,
    public dialog: MatDialog,public dialogRef: MatDialogRef<ProfessionalDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log('data obtained in input :  ', this.data.profdetail);
    this.uploadreq = {};
    this.totalyears = '';
    this.config = new Config();
    this.visaDetail = new VisaDetails();
    this.langDetail = new LanguageDetails();
    this.visaDetails = [];
    this.langDetails = [];
    this.professionDetails = new ProfessionDetails();
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.successmessage = '';
    this.noInfoAdded = true;
    this.showAddLangButton = true;
    this.userId = sessionStorage.getItem('userid');
    this.years = this.config.getNumbersList();
    this.months = this.config.getNumbersList();
    this.languages = this.config.getLanguagesArray();
    this.islookingforjob = false;
    this.validFileFormats = this.config.getFileFormats();
    this.joblevels = [];
    this.getJobLevels();
    this.totalyears = '';
    this.getExperienceInWords();
    this.displayLanguages();
    this.displayVisas();
    this.processEngagementType();
    this.processJobAvailability();
    this.processJobType();
    this.getCoi();
    this.processRadioData();
    this.populatefilename();
    this.showalert = false;
    
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  getJobLevels(){
    this.jobService.getJobLevels().subscribe(
      data => {
              if(data.success == true){
                this.joblevels = data.Results;
              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
    }

    processJobAvailability(){
      if(this.data.profdetail.NoticePeriod !== null){
        this.jobavailability = 'npafteroffer';
        this.showNpForm = true;
        return ;
      }
  
      if(this.data.profdetail.canJoinImmediately == 'Immediately'){
        this.jobavailability = 'immediately';
        return;
      }
  
      if(this.data.profdetail.AvailableFrom !== null){
        this.showAvailForm = true;
        this.jobavailability = 'onavaildate';
        return;
      }
    }

    processRadioData(){
      this.isCoi = this.getRadioDataTOrF(this.data.profdetail.isConfictOfInterest);
      this.isDifferentlyAbled= this.getRadioDataTOrF(this.data.profdetail.isDifferentlyAbled);
      this.canRelocate = this.getRadioDataTOrF(this.data.profdetail.canRelocate);
      this.canWorkInShifts= this.getRadioDataTOrF(this.data.profdetail.ReadyToWorkInShifts);
      this.isEctcNegotiable = this.getRadioDataTOrF(this.data.profdetail.isECTCNegotiable);
      this.islookingforjob = this.getRadioDataTOrF(this.data.profdetail.isActivelyLookingForJob);
      this.isnpnegotiable = this.getRadioDataTOrF(this.data.profdetail.isNPNegotiable);
      this.isreadyforoverseas= this.getRadioDataTOrF(this.data.profdetail.ReadinessForOverSeas);
    }

    getCheckChange(){
      this.islookingforjob = !this.islookingforjob;
    }

    processEngagementType(){
  
      let exJobEngType = this.data.profdetail.EngagementType;
      if(exJobEngType){
        if(exJobEngType.indexOf(',') != -1){
          let jobTypeArray = exJobEngType.split(',');
  
          for(let type of jobTypeArray){
            if(type == 'Full Time'){
              this.fulltime = true;
            }
        
            if(type == 'Part Time'){
              this.parttime = true;
            }
  
          }
  
        } else {
          if(this.data.profdetail.EngagementType =='Full Time'){
            this.fulltime = true;
          }
      
          if(this.data.profdetail.EngagementType =='Part Time'){
            this.parttime = true;
          }
        }
      }
    }

  getAvailDateForm(){
    this.showAvailForm = false;
    this.showNpForm = false;

    if(this.jobavailability == 'onavaildate'){
      this.showAvailForm = true;
    }

    if(this.jobavailability == 'npafteroffer'){
      this.showNpForm = true;
    }
  }

  getCoi(){
    this.showCoi = false;
    if(this.isCoi == true){
      this.showCoi = true;
    }

    if(this.isCoi == false){
      this.showCoi = false;
    }
  }

  getExperienceInWords(){
    var years : any;
    var months  : any;
    var totalexp = this.data.profdetail.TotalExp;

    if(totalexp){
      years = (Number.parseInt(totalexp) - (Number.parseInt(totalexp) % 12 )) / 12;
      months = Number.parseInt(totalexp) % 12;

      console.log('Years : ', years);
      console.log('Months : ', months);

      if(Number.parseInt(years) > 0){
        this.totalyears = years+' Years';
      }

      if(Number.parseInt(months) > 0){
        this.totalyears = this.totalyears+' '+months+' Months';
      }
      console.log(this.totalyears);
    }
  }

  displayVisas(){
    if(this.data.profdetail.VisaDetails){
      for(var visadetail of this.data.profdetail.VisaDetails){
        console.log('VIsa detail in popup : ', visadetail);
        this.visaDetails.push(visadetail);
      }
    }
  }

  displayLanguages(){

    if(this.data.profdetail.LanguageProficiency){
      if(typeof this.data.profdetail.LanguageProficiency === 'string'){
        try {
          var objectArray = JSON.parse(this.data.profdetail.LanguageProficiency);
          console.log('Object array:', objectArray);
          for(var lanDetail of objectArray){
        
            this.langDetails.push(lanDetail);
            console.log("lanDetaillanDetail",lanDetail);
          }
          if(this.langDetails && this.langDetails.length == 2){
            this.showAddLangButton = false;
          }
        } catch (error) {
          console.error('Error parsing JSON string:', error);
        }
      }else{
      for(var lanDetail of this.data.profdetail.LanguageProficiency){
        this.langDetails.push(lanDetail);
      }
      if(this.langDetails && this.langDetails.length == 2){
        this.showAddLangButton = false;
      }
    }
    }
  }

  getLangArray(langObject : any){
    var finalArr = [];
    if(langObject){
      var arr = langObject.split(',');
      if(arr){
        for(let item of arr){
          if(item && (item != '' || item != null)){
            finalArr.push(item);
          }
        }
      }
      return finalArr;
    }
  }
  
  getName(mainObj : any, languageAction : any){
    return mainObj.Clanguages+languageAction;
  }

  getRadioDataTOrF(data){
    console.log('data : ',data );
  
      if(data == '0'){
        return false;
      }
      if(data == '1'){
        return true;
      }
    
  }

  populateRadioForObject(type : any){

    if(type == 'ectc'){
      if(this.isEctcNegotiable){
        return '1';
      }
    
      if(this.isEctcNegotiable == false){
        return '0';
      }
    }
  
    if(type == 'differentlyabled'){
      if(this.isDifferentlyAbled){
        return '1';
      }
    
      if(this.isDifferentlyAbled == false){
        return '0';
      }
    }
  
    if(type == 'relocation'){
      if(this.canRelocate){
        return '1';
      }
    
      if(this.canRelocate == false){
        return '0';
      }
    }
  
    if(type == 'workshifts'){
      if(this.canWorkInShifts){
        return '1';
      }
    
      if(this.canWorkInShifts == false){
        return '0';
      }
    }
  
    if(type == 'lookingforjob'){
      if(this.islookingforjob){
        return 'true';
      }
    
      if(this.islookingforjob == false){
        return 'false';
      }
    }

    if(type == 'coi'){
      if(this.isCoi){
        return '1';
      }
    
      if(this.isCoi == false){
        return '0';
      }
    }

    if(type == 'overseas'){
      if(this.isreadyforoverseas){
        return '1';
      }
    
      if(this.isreadyforoverseas == false){
        return '0';
      }
    }
  
  
    
    
  }


openVisaDialog(){
  this.visaDetail = new VisaDetails();
  this.successmessage = '';
  this.errormessage = '';
  this.dialogRefvisaLang = this.dialog.open(VisaDetailsUpdate, {
    width:'100%',
    height:'50%',
    closeOnNavigation : true,
    data: {visadetail : this.visaDetail}
  });

  this.dialogRefvisaLang.afterClosed().subscribe(result => {
    console.log('in dialog close test');
    if(result){
      this.visaDetail = result;
      this.visaDetail.UserId = this.userId;
      console.log('Visa detail to be updated : ', this.visaDetail);
      this.visaDetails.push(this.visaDetail);
      console.log('Visa details array : ', this.visaDetails);

    }
    
  });
}

openLanguageDialog(){
  this.langDetail =new LanguageDetails();
  this.successmessage = '';
  this.errormessage = '';
  this.dialogRefvisaLang = this.dialog.open(LanguageDetailsUpdate, {
    width:'100%',
    height:'50%',
    closeOnNavigation : true,
    data: {langdetail : this.langDetail}
  });

  this.dialogRefvisaLang.afterClosed().subscribe(result => {
    if(result){
      this.data.profdetail.LanguageProficiency = [];
      this.langDetail = result;
      console.log('Lang detail to be updated : ', this.langDetail);
      this.langDetails.push(this.langDetail);
      console.log('Lang details array : ', this.langDetails);
      
    }
    
  });
}

uploadcv(){
  this.uploaderrormsg = '';
  this.uploadsuccessmsg = '';
  this.uploadreq = {};
  this.uploadreq.UserId = this.userId;
  this.uploadreq.AdminId = this.userId;
  this.uploadreq.FileType = this.getFileType(this.selectedFile.name);
  if(!this.checkIfValidFile(this.uploadreq.FileType)){
    console.log('Uploaded file format is not correct');
    this.responseMessage = 'Only rtf or doc or docx file allowed';
    this.typeOfResponseMessage = 'error';
    this.showalert = true;
    return;
 }
  this.uploadreq.resumelink = 'testlink';

  this.jobService.uploadFile(this.uploadreq, this.selectedFile).subscribe(
    data => {
            if(data.success == true){
             console.log('data result : ', data.Result);
             this.data.profdetail.ResumeLink = data.Result;
             this.uploadsuccessmsg = 'Successfully uploaded resume';
             this.typeOfResponseMessage = 'success';
             this.responseMessage = 'Successfully uploaded resume';
             this.showalert = true;
             this.populatefilename();
            } else {
              this.errormessage = data.error;
              this.typeOfResponseMessage = 'error';
              this.responseMessage = 'Failed to upload resume';
              this.showalert = true;
              this.uploaderrormsg = 'Failed to upload resume';
              console.log('Error message : ', data.error)
            }
    },
    error => {
      console.log('Error occurred : ', error);
          });
}

checkIfValidFile(fileType : any) : boolean{
  if(fileType) {
    if(this.validFileFormats.includes(fileType)){
      return true;
    } 
  }
  return false;
}

changeImage(event){
  if (event.srcElement.files && event.srcElement.files[0]) {
    var e = document.getElementById('cvupload');
    e.setAttribute('src', window.URL.createObjectURL(event.srcElement.files[0]));
    this.selectedFile = event.srcElement.files[0];
    console.log('Uploaded file :  ', this.selectedFile);
    console.log('Uploaded file name:  ', this.selectedFile.name);
    console.log('Uploaded file name:  ', this.selectedFile.type);
}
}

getFileType(fileName : any){
  var fileType = '';
  if(fileName.indexOf('.') > 0){
    fileType = fileName.substr(fileName.indexOf('.') + 1 , fileName.length - 1);
    console.log('File type : ', fileType);
  }
  return fileType;
}

getLangName(lang : any){
  return lang.Clanguages;
}


onCancel(): void { 
  this.dialogRef.close(); 
}

populatefilename(){
  if(this.data.profdetail.ResumeLink && this.data.profdetail.ResumeLink != null){
    var resumeLink = this.data.profdetail.ResumeLink;
    this.uploadedfilename = resumeLink.substring(resumeLink.indexOf('/')+1,resumeLink.length )
  }
}

processJobType(){
  let exJobType = this.data.profdetail.ExpectedJobType;
  if(exJobType){
    if(exJobType.indexOf(',') != -1){
      let jobTypeArray = exJobType.split(',');

      for(let type of jobTypeArray){
        if(type == 'Permanent'){
          this.permanentjobtype = true;
        }
    
        if(type == 'Contract'){
          this.contractjobtype = true;
        }
    
        if(type == 'Contract to Hire'){
          this.contracthirejobtype = true;
        }

      }

    } else {
      if(this.data.profdetail.ExpectedJobType =='Permanent'){
        this.permanentjobtype = true;
      }
  
      if(this.data.profdetail.ExpectedJobType =='Contract'){
        this.contractjobtype = true;
      }
  
      if(this.data.profdetail.ExpectedJobType == 'Contract to Hire'){
        this.contracthirejobtype = true;
      }
    }
  }
}


update(valid : boolean){
  this.submitted = true;

  if(this.noofyears && this.noofmonths){
    this.data.profdetail.TotalExp = Number.parseInt(this.noofyears) * 12 + Number.parseInt(this.noofmonths);

  }

  let engTypes = [];
  if(this.parttime){
    engTypes.push('Part Time');
  }

  if(this.fulltime){
    engTypes.push('Full Time');
  }

  if(engTypes && engTypes.length > 0){
    this.data.profdetail.EngagementType = engTypes.join(',');
  }

  if(this.jobavailability){
    if(this.jobavailability == 'npafteroffer'){
      this.data.profdetail.isNPAfterOffer = 'NoticePeriod';
    }
    if(this.jobavailability == 'immediately'){
      this.data.profdetail.canJoinImmediately = 'Immediately';
    }
  }
  let expectedJobTypes = [];
  if(this.contractjobtype){
    expectedJobTypes.push('Contract');
  }

  if(this.contracthirejobtype){
    expectedJobTypes.push('Contract to Hire');
  }

  if(this.permanentjobtype){
    expectedJobTypes.push('Permanent');
  }

  if(expectedJobTypes && expectedJobTypes.length > 0){
    this.data.profdetail.ExpectedJobType = expectedJobTypes.join(',');
  }

  this.data.profdetail.LanguageProficiency = JSON.stringify(this.langDetails);
  this.data.profdetail.VisaDetails = this.visaDetails;

  this.data.profdetail.isECTCNegotiable = this.populateRadioForObject('ectc');
  this.data.profdetail.canRelocate = this.populateRadioForObject('relocation');
  this.data.profdetail.isDifferentlyAbled = this.populateRadioForObject('differentlyabled');
  this.data.profdetail.ReadyToWorkInShifts = this.populateRadioForObject('workshifts');
  this.data.profdetail.isActivelyLookingForJob = this.populateRadioForObject('lookingforjob');
  this.data.profdetail.isConfictOfInterest = this.populateRadioForObject('coi');

  this.data.profdetail.ReadinessForOverSeas = this.populateRadioForObject('overseas');


  if(valid){
    console.log('User profile form is valid :  ', this.data.profdetail);
    this.dialogRef.close(this.data.profdetail);
  } else {
    console.log('User profile detail form is not valid');
    return;
  }
}

getVisaNameProp(visaobj: any, field : any){
  if(field == 'name'){
    return visaobj.Visa;
  }
  if(field == 'country'){
    return visaobj.Visa+visaobj.Country;
  }

  if(field == 'issuedate'){
    return visaobj.Visa+visaobj.IssueDate;
  }

  if(field == 'expdate'){
    return visaobj.Visa+visaobj.ExpDate;
  }
}

deleteVisaDetail(index : any){
  this.visaDetails.splice(index , 1);
  console.log('visa array afetr deletion : ', this.visaDetails);
}

convertDate(date : any){
  var dat = new Date(date);
  
  let latest_date = dat.toISOString();
  console.log('Converted date : ', latest_date);
  return latest_date;
 }

 closeAlert(event : any){
  if(event){
    this.showalert = false;
  }
}

}




