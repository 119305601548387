import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent implements OnInit {

  constructor() { }
  newUserForm:boolean = false;
  signInForm:boolean = true;
  forgotPasswordForm:boolean = false;
  submitForm:boolean = false;
  loginPageFlag:boolean = true;
  ngOnInit() {
    var userid = sessionStorage.getItem('userid');
    if(userid && userid !== 'null'){
      this.loginPageFlag = false;
      console.log("loginPageFlag",this.loginPageFlag);
      
    }
  }
  signUp(){
    this.signInForm = false;
    this.newUserForm = true;
    this.forgotPasswordForm = false;
    this.submitForm = false;
  }

  handleSignInData(event){
    console.log("evnettbtb",event);
    if(event == 'signinForm' || event == 'login'){
      this.signInForm=true;
      this.newUserForm = false;
      this.forgotPasswordForm = false;
      this.submitForm = false;
    }
    
    if(event == 'regprof'){
      this.submitForm = true;
      this.signInForm=false;
      this.newUserForm = false;
      this.forgotPasswordForm = false;
    }
  }
  forgotPassword(){
    this.signInForm=false;
    this.newUserForm = false;
    this.forgotPasswordForm = true;
    this.submitForm = false;
  }

  handleSignEvent(event){
    console.log("routte",event);
    if(event == '/forgotpassword'){
      this.signInForm=false;
    this.newUserForm = false;
    this.forgotPasswordForm = true;
    this.submitForm = false;
    }
    else{
      this.signInForm=false;
      this.newUserForm = true;
      this.forgotPasswordForm = false;
      this.submitForm = false;
    }
    
  }
}
