import { Component, OnInit,OnDestroy } from '@angular/core';
import {JobService} from '../services/index';
import {ProfessionDetails,VisaDetails,LanguageDetails} from '../models/index';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {Config} from '../config/index';
import { VisaDetailsUpdate } from './visadetailsupdate.component';
import { LanguageDetailsUpdate } from './langdetailsupdate.component';
import { ProfessionalDetailsUpdate } from './professionaldetailsupdate.component';
import { observable } from 'rxjs';

@Component({
  selector: 'app-prof-details',
  templateUrl: './professionaldetails.component.html',
})
export class ProfessionalDetails implements OnInit,OnDestroy  {
  userId : any;
  Id: any;
  submitted : boolean;
  errormessage : any;
  successmessage : any;
  cities : any;
  states : any;
  dialogRef : any;
  dialogRefVisa : any;
  dialogRefLang : any;
  professionDetails : ProfessionDetails;
  noInfoAdded : boolean;
  years: any;
  months : any;
  config : Config;
  joblevels : any;
  jobavailability : any;
  parttime : boolean;
  fulltime : boolean;
  permanentjobtype : any;
  contracthirejobtype : any;
  contractjobtype : any;
  uploaderrormsg : any;
  uploadsuccessmsg : any;
  visaDetails : VisaDetails[];
  visaDetail : VisaDetails;
  langDetails : LanguageDetails[];
  langDetail : LanguageDetails;
  uploadreq : any;
  selectedFile : File;
  totalyears : any;
  showAvailForm : boolean;
  noofyears : any;
  noofmonths : any;
  showNpForm : boolean;
  showCoi : boolean;
  visas : any;
  langs : any;
  languages : any;
  profilecompletionscore : any;
  professionScore :any;
  isEctcNegotiable : boolean;
  isCoi : boolean;
  isDifferentlyAbled : boolean;
  canRelocate : boolean;
  canWorkInShifts : boolean;
  islookingforjob : boolean;
  isreadyforoverseas : boolean;
  isnpnegotiable : boolean;
  uploadedfilename : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;
  validFileFormats : any;

  constructor(private jobService: JobService, private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    console.log('In ngoninit of professional details component');
    this.visas = [];
    this.langs = [];
    this.uploadreq = {};
    this.totalyears = '';
    this.config = new Config();
    this.visaDetail = new VisaDetails();
    this.langDetail = new LanguageDetails();
    this.visaDetails = [];
    this.langDetails = [];
    this.professionDetails = new ProfessionDetails();
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.successmessage = '';
    this.noInfoAdded = true;
    this.userId = sessionStorage.getItem('userid');
    this.years = this.config.getNumbersList();
    this.months = this.config.getNumbersList();
    this.languages = this.config.getLanguagesArray();
    this.validFileFormats = this.config.getFileFormats();
    this.joblevels = [];
    this.getJobLevels();
    this.getCupProfessionalDetails();
    this.totalyears = '';
    this.showalert = false;
    
    this.profilecompletionscore = sessionStorage.getItem('ProfileCompletionScore');
  }

  ngOnDestroy(){
    this.successmessage = '';
    this.errormessage = '';
    if(this.dialogRef){
      console.log('Closing dialog');
      this.dialogRef.close();
    }
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  getJobLevels(){
    this.jobService.getJobLevels().subscribe(
      data => {
              if(data.success == true){
                this.joblevels = data.Results;
              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
    }

  getCupProfessionalDetails(){
      console.log('userid and id from session : ', this.userId, this.Id);
      this.jobService.getCupProfessionalDetails(this.userId).subscribe(
        data => {
                if(data.success == true){
                  this.professionDetails = data.Result;
              
                  this.professionScore = this.professionDetails.ProfileCompletionScore;
                  if(this.professionDetails.Id > 0){
                    this.noInfoAdded = false;
                    this.processData();
                  }
                } else {
                  this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  this.errormessage = data.error;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          this.responseMessage = 'Error occurred while getting user data';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          console.log('Error occurred : ', error);
              });

  }

  processData(){
    this.populateIsActivelyLookingForJob();
    this.getExperienceInWords();
    this.populateRadioData();
    //this.processCTC();
    this.processJobAvailability();
    this.processEngagementType();
    this.processJobType();
    this.displayVisas();
    this.displayLanguages();
    this.getCoi();
    this.populatefilename();
  }

  populatefilename(){
    if(this.professionDetails.ResumeLink && this.professionDetails.ResumeLink != null){
      var resumeLink = this.professionDetails.ResumeLink;
      this.uploadedfilename = resumeLink.substring(resumeLink.indexOf('/')+1,resumeLink.length )
    }
  }

  displayVisas(){
    this.visaDetails = [];
    if(this.professionDetails.VisaDetails){
      for(var visadetail of this.professionDetails.VisaDetails){
        console.log('Visa detail obtained initially : ', visadetail);
        this.visaDetails.push(visadetail);
      }
    }
  }

  displayLanguages(){
    this.langDetails = [];
    if(this.professionDetails.LanguageProficiency){
      this.professionDetails.LanguageProficiency = JSON.parse(this.professionDetails.LanguageProficiency);
      for(var lanDetail of this.professionDetails.LanguageProficiency){
        this.langDetails.push(lanDetail);
        console.log("display languages",this.professionDetails.LanguageProficiency);
        
      }
    }
  }

  processJobType(){
    let exJobType = this.professionDetails.ExpectedJobType;
    if(exJobType){
      if(exJobType.indexOf(',') != -1){
        let jobTypeArray = exJobType.split(',');

        for(let type of jobTypeArray){
          if(type == 'Permanent'){
            this.permanentjobtype = true;
          }
      
          if(type == 'Contract'){
            this.contractjobtype = true;
          }
      
          if(type == 'Contract to Hire'){
            this.contracthirejobtype = true;
          }

        }

      } else {
        if(this.professionDetails.ExpectedJobType =='Permanent'){
          this.permanentjobtype = true;
        }
    
        if(this.professionDetails.ExpectedJobType =='Contract'){
          this.contractjobtype = true;
        }
    
        if(this.professionDetails.ExpectedJobType == 'Contract to Hire'){
          this.contracthirejobtype = true;
        }
      }
    }
  }

  processEngagementType(){
    if(this.professionDetails.EngagementType == 'Full Time'){
      this.fulltime = true;
    }

    if(this.professionDetails.EngagementType == 'Part Time'){
      this.parttime = true;
    }

    let exJobEngType = this.professionDetails.EngagementType;
    if(exJobEngType){
      if(exJobEngType.indexOf(',') != -1){
        let jobTypeArray = exJobEngType.split(',');

        for(let type of jobTypeArray){
          if(type == 'Full Time'){
            this.fulltime = true;
          }
      
          if(type == 'Part Time'){
            this.parttime = true;
          }

        }

      } else {
        if(this.professionDetails.EngagementType =='Full Time'){
          this.fulltime = true;
        }
    
        if(this.professionDetails.EngagementType =='Part Time'){
          this.parttime = true;
        }
      }
    }
  }

  processJobAvailability(){
    if(this.professionDetails.NoticePeriod !== null){
      this.jobavailability = 'npafteroffer';
      this.showNpForm = true;
      return ;
    }

    if(this.professionDetails.canJoinImmediately == 'Immediately'){
      this.jobavailability = 'immediately';
      return;
    }

    if(this.professionDetails.AvailableFrom !== null){
      this.showAvailForm = true;
      this.jobavailability = 'onavaildate';
      return;
    }
  }

  processCTC(){
    if(this.professionDetails.CurrentCTC){
      this.professionDetails.CurrentCTC = Number.parseInt(this.professionDetails.CurrentCTC) / 100000;
    }

    if(this.professionDetails.ECTC){
      this.professionDetails.ECTC = Number.parseInt(this.professionDetails.ECTC) / 100000;
    }
  }
 
  populateRadioData(){
    this.isCoi = this.getRadioDataTOrF(this.professionDetails.isConfictOfInterest);
    this.isDifferentlyAbled= this.getRadioDataTOrF(this.professionDetails.isDifferentlyAbled);
    this.canRelocate = this.getRadioDataTOrF(this.professionDetails.canRelocate);
    this.canWorkInShifts= this.getRadioDataTOrF(this.professionDetails.ReadyToWorkInShifts);
    this.isEctcNegotiable = this.getRadioDataTOrF(this.professionDetails.isECTCNegotiable);
    this.isnpnegotiable = this.getRadioDataTOrF(this.professionDetails.isNPNegotiable);
    this.isreadyforoverseas= this.getRadioDataTOrF(this.professionDetails.ReadinessForOverSeas);

  }

  populateIsActivelyLookingForJob(){
    if(this.professionDetails.isActivelyLookingForJob == 'true'){
      this.islookingforjob = true;
    } else if(this.professionDetails.isActivelyLookingForJob == 'false'){
      this.islookingforjob = false;
    }
  }

  populateJobAvailability(){
    if(this.professionDetails.isNPAfterOffer){
      this.jobavailability = 'npafteroffer';
    }
    if(this.professionDetails.canJoinImmediately == 'Immediately'){
      this.jobavailability = 'immediately';
    }

    if(this.professionDetails.AvailableFrom){
      this.jobavailability = 'onavaildate';
    }
  }

  getAvailDateForm(){
    this.showAvailForm = false;
    this.showNpForm = false;

    if(this.jobavailability == 'onavaildate'){
      this.showAvailForm = true;
    }

    if(this.jobavailability == 'npafteroffer'){
      this.showNpForm = true;
    }
  }

  getRadioDataTOrF(data){
    console.log('data : ',data );
  
    if(data == '0'){
        return false;
      }
    if(data == '1'){
        return true;
      }
    
  }

  getCoi(){
    this.showCoi = false;
    if(this.isCoi == true){
      this.showCoi = true;
    }

    if(this.isCoi == false){
      this.showCoi = false;
    }
  }

  getExperienceInWords(){
    var years : any;
    var months : any;
    var totalexp = this.professionDetails.TotalExp;

    if(totalexp){
      years = (Number.parseInt(totalexp) - (Number.parseInt(totalexp) % 12 )) / 12;
      months = Number.parseInt(totalexp) % 12;

      console.log('Years : ', years);
      console.log('Months : ', months);

      if(Number.parseInt(years) > 0){
        this.totalyears = years+' Years';
      }

      if(Number.parseInt(months) > 0){
        this.totalyears = this.totalyears+' '+months+' Months';
      }
      console.log(this.totalyears);
    }
  }

openVisaDialog(){
  this.visaDetail = new VisaDetails();
  this.successmessage = '';
  this.errormessage = '';
  this.dialogRefVisa = this.dialog.open(VisaDetailsUpdate, {
    width:'100%',
    height:'50%',
    closeOnNavigation : true,
    data: {visadetail : this.visaDetail}
  });

  this.dialogRefVisa.afterClosed().subscribe(result => {
    console.log('in dialog close test');
    if(result){
      this.visaDetail = result;
      this.visaDetail.UserId = this.userId;
      console.log('Visa detail to be updated : ', this.visaDetail);
      this.visaDetails.push(this.visaDetail);
      console.log('Visa details array : ', this.visaDetails);

      this.professionDetails.VisaDetails = JSON.stringify(this.visaDetails);
    }
    
  });
}

deleteLangDetails(ob:any){
  console.log("object in deletelang",ob);
  this.errormessage = '';
  this.successmessage = '';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert =false;
  this.langDetail = ob;
  console.log("object in",this.langDetail);
  this.jobService.deleteAcademicDetails(this.langDetail).subscribe(
    data => {
            if(data.success == true){
            this.responseMessage = 'Academic details deleted successfully';
            this.typeOfResponseMessage = 'success';
            this.showalert = true;
          } else {
            this.errormessage = data.error;
            this.responseMessage = data.error;
            this.typeOfResponseMessage = 'error';
            this.showalert = true;
            console.log('Error message : ', data.error)
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
  

}

openLanguageDialog(){
  this.langDetail =new LanguageDetails();
  this.successmessage = '';
  this.errormessage = '';
  this.dialogRefLang = this.dialog.open(LanguageDetailsUpdate, {
    width:'100%',
    height:'50%',
    closeOnNavigation : true,
    data: {langdetail : this.langDetail}
  });

  this.dialogRefLang.afterClosed().subscribe(result => {
    if(result){
      this.professionDetails.LanguageProficiency = [];
      this.langDetail = result;
      console.log('Lang detail to be updated : ', this.langDetail);
      this.langDetails.push(this.langDetail);
      console.log('Lang details array : ', this.langDetails);
      this.professionDetails.LanguageProficiency = JSON.stringify(this.langDetails);
    }
    
  });
}

getLangArray(langObject : any){
  var finalArr = [];
    if(langObject){
      var arr = langObject.split(',');
      if(arr){
        for(let item of arr){
          if(item && (item != '' || item != null)){
            finalArr.push(item);
          }
        }
      }
      return finalArr;
    }
}

getName(mainObj : any, languageAction : any){
  return mainObj.Clanguages+languageAction;
}

uploadcv(){
  this.uploaderrormsg = '';
  this.uploadsuccessmsg = '';
  this.uploadreq = {};
  this.uploadreq.UserId = this.userId;
  this.uploadreq.AdminId = this.userId;
  this.uploadreq.FileType = this.getFileType(this.selectedFile.name);
  if(!this.checkIfValidFile(this.uploadreq.FileType)){
    console.log('Uploaded file format is not correct');
    this.responseMessage = 'Only rtf or doc or docx file allowed';
    this.typeOfResponseMessage = 'error';
    this.showalert = true;
    return;
 }
  this.uploadreq.resumelink = 'testlink';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert = false;

  this.jobService.uploadFile(this.uploadreq, this.selectedFile).subscribe(
    data => {
            if(data.success == true){
             console.log('data result : ', data.Result);
             this.professionDetails.ResumeLink = data.Result;
             this.uploadsuccessmsg = 'Successfully uploaded resume';
             this.typeOfResponseMessage = 'success';
             this.responseMessage = 'Successfully uploaded resume';
             this.showalert = true;
            } else {
              this.errormessage = data.error;
              this.typeOfResponseMessage = 'error';
              this.responseMessage = 'Failed to upload resume';
              this.showalert = true;
              this.uploaderrormsg = 'Failed to upload resume';
              console.log('Error message : ', data.error);
            }
    },
    error => {
      console.log('Error occurred : ', error);
          });
}

changeImage(event){
  if (event.srcElement.files && event.srcElement.files[0]) {
    var e = document.getElementById('cvupload');
    e.setAttribute('src', window.URL.createObjectURL(event.srcElement.files[0]));
    this.selectedFile = event.srcElement.files[0];
    console.log('Uploaded file :  ', this.selectedFile);
    console.log('Uploaded file name:  ', this.selectedFile.name);
    console.log('Uploaded file name:  ', this.selectedFile.type);
}
}

getFileType(fileName : any){
  var fileType = '';
  if(fileName.indexOf('.') > 0){
    fileType = fileName.substr(fileName.indexOf('.') , fileName.length - 1);
    console.log('File type : ', fileType,fileName.indexOf('.') + 1 ,);
  }
  return fileType;
}

checkIfValidFile(fileType : any) : boolean{
  if(fileType) {
    if(this.validFileFormats.includes(fileType)){
      return true;
    } 
  }
  return false;
}

saveInfo(){
  this.professionDetails.isECTCNegotiable = this.populateRadioForObject('ectc');
  this.professionDetails.canRelocate = this.populateRadioForObject('relocation');
  this.professionDetails.isDifferentlyAbled = this.populateRadioForObject('differentlyabled');
  this.professionDetails.ReadyToWorkInShifts = this.populateRadioForObject('workshifts');
  this.professionDetails.isActivelyLookingForJob = this.populateRadioForObject('lookingforjob');
  this.professionDetails.isConfictOfInterest = this.populateRadioForObject('coi');
  this.professionDetails.ReadinessForOverSeas = this.populateRadioForObject('overseas');

  if(this.noofyears && this.noofmonths){
    // tslint:disable-next-line:radix
    this.professionDetails.TotalExp = Number.parseInt(this.noofyears) * 12 + Number.parseInt(this.noofmonths);
  }
  let engTypes = [];
  if(this.parttime){
    engTypes.push('Part Time');
  }

  if(this.fulltime){
    engTypes.push('Full Time');
  }

  if(engTypes && engTypes.length > 0){
    this.professionDetails.EngagementType = engTypes.join(',');
  }

  if(this.jobavailability){
    if(this.jobavailability == 'npafteroffer'){
      this.professionDetails.isNPAfterOffer = 'NoticePeriod';
    }
    if(this.jobavailability == 'immediately'){
      this.professionDetails.canJoinImmediately = 'Immediately';
    }
  }

  let expectedJobTypes = [];
  if(this.contractjobtype){
    expectedJobTypes.push('Contract');
  }

  if(this.contracthirejobtype){
    expectedJobTypes.push('Contract to Hire');
  }

  if(this.permanentjobtype){
    expectedJobTypes.push('Permanent');
  }

  if(expectedJobTypes && expectedJobTypes.length > 0){
    this.professionDetails.ExpectedJobType = expectedJobTypes.join(',');
  }

  this.professionDetails.LanguageProficiency = JSON.stringify(this.langDetails);
  this.professionDetails.VisaDetails = this.visaDetails;
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert = false;
  this.professionDetails.AdminId = this.userId;
  this.professionDetails.UserId = this.userId;
  this.jobService.createProfesssionalDetails(this.professionDetails).subscribe(
    data => {
            if(data.success == true){
            console.log('data result : ', data.Result);
            this.successmessage = 'professional details saved successfully';
            this.responseMessage = 'professional details saved successfully';
            this.typeOfResponseMessage = 'success';
            this.profilecompletionscore = data.Result.TotalProfileCompletionScore;
            sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
            this.showalert = true;
             //this.router.navigate(['/dashboard']);
            } else {
              this.errormessage = data.error;
              this.responseMessage = data.error;
              this.typeOfResponseMessage = 'error';
              this.showalert = true;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      console.log('Error occurred : ', error);
          });

  console.log('Professional details to be saved : ', this.professionDetails);

}

getLangName(lang : any){
  return lang.Clanguages;
}

getVisaNameProp(visaobj: any, field : any){
  if(field == 'name'){
    return visaobj.Visa;
  }
  if(field == 'country'){
    return visaobj.Visa+visaobj.Country;
  }

  if(field == 'issuedate'){
    return visaobj.Visa+visaobj.IssueDate;
  }

  if(field == 'expdate'){
    return visaobj.Visa+visaobj.ExpDate;
  }
}

updateInfo(){
  this.successmessage = '';
  this.errormessage = '';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert = false;
  this.professionDetails.AdminId = this.userId;
  this.jobService.updateProfesssionalDetails(this.professionDetails).subscribe(
    data => {
            if(data.success == true){
             console.log('data result : ', data.Result);
             this.successmessage = 'professional details updated successfully';
             this.responseMessage = 'Professional details updated successfully';
             this.typeOfResponseMessage = 'success';
             this.showalert = true;
             this.professionDetails = data.Result.CupProfessionalDetail;
             this.profilecompletionscore = data.Result.TotalProfileCompletionScore;
             sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
             this.processData();
             //this.router.navigate(['/dashboard']);
            } else {
              this.errormessage = data.error;
              this.responseMessage = data.error;
              this.typeOfResponseMessage = 'error';
              this.showalert = true;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      console.log('Error occurred : ', error);
          });
}

openEditDialog(){
  this.successmessage = '';
  this.errormessage = '';
  this.professionDetails.isECTCNegotiable = this.populateRadioForObject('ectc');
  this.professionDetails.canRelocate = this.populateRadioForObject('relocation');
  this.professionDetails.isDifferentlyAbled = this.populateRadioForObject('differentlyabled');
  this.professionDetails.ReadyToWorkInShifts = this.populateRadioForObject('workshifts');
  this.professionDetails.isActivelyLookingForJob = this.populateRadioForObject('lookingforjob');
  this.professionDetails.isConfictOfInterest = this.populateRadioForObject('coi');
  this.professionDetails.ReadinessForOverSeas = this.populateRadioForObject('overseas');

  this.dialogRef = this.dialog.open(ProfessionalDetailsUpdate, {
    width:'100%',
    height:'100%',
    closeOnNavigation : true,
    data: {profdetail : this.professionDetails}
  });

  this.dialogRef.afterClosed().subscribe(result => {
    console.log('in dialog close test : ', result);
    if(result){
      console.log('Updating data');
      this.professionDetails = result;
      this.updateInfo();
    } else {
      console.log('ectc negotaible : ', this.isEctcNegotiable);
      //this.ngOnInit();
      //this.getCupProfessionalDetails();
      //this.router.navigate(['/professionaldetails']);
      /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/professionaldetails']);
    });*/
  //   this.router.navigate(['/professionaldetails'])
  //     .then(() => {
  //   window.location.reload();
  // });
    }
  });
}

populateRadioForObject(type : any){

  if(type == 'ectc'){
    if(this.isEctcNegotiable){
      return '1';
    }
  
    if(this.isEctcNegotiable == false){
      return '0';
    }
  }

  if(type == 'differentlyabled'){
    if(this.isDifferentlyAbled){
      return '1';
    }
  
    if(this.isDifferentlyAbled == false){
      return '0';
    }
  }

  if(type == 'relocation'){
    if(this.canRelocate){
      return '1';
    }
  
    if(this.canRelocate == false){
      return '0';
    }
  }

  if(type == 'workshifts'){
    if(this.canWorkInShifts){
      return '1';
    }
  
    if(this.canWorkInShifts == false){
      return '0';
    }
  }

  if(type == 'lookingforjob'){
    if(this.islookingforjob){
      return '1';
    }
  
    if(this.islookingforjob == false){
      return '0';
    }
  }

  if(type == 'coi'){
    if(this.isCoi){
      return '1';
    }
  
    if(this.isCoi == false){
      return '0';
    }
  }

  if(type == 'overseas'){
    if(this.isreadyforoverseas){
      return '1';
    }
  
    if(this.isreadyforoverseas == false){
      return '0';
    }
  }


  
  
}

convertDate(date : any){
  var dat = new Date(date);
  
  let latest_date = dat.toISOString();
  console.log('Converted date : ', latest_date);
  return latest_date;
 }

 closeAlert(event : any){
  if(event){
    this.showalert = false;
  //   this.router.navigate(['/professionaldetails'])
  //     .then(() => {
  //   window.location.reload();
  // });
  }
}

}




