
export class JobApplyAcademicDetails {
    
    Percentage: any;
   // QualCategory : any;

    UserId: any;
    Degree: any;
    EducationCategory: any;
    Major: any;
   
}
