import {JobLevelCertification , JobLevelQualification ,JobLevelSkill,JobRequirement,CurrentCompany } from './index';
 
export class JobPostingDetail {
AdminId: any;
BudgetAssigned: any;
CandCurrentCTC: any;
CandECTC: any;
CandNp: any;
CandProSki: any;
CandRefFee: any;
CandReqEarliestByDate: any;
CandReqLatestByDate: any;
CandStatus: any;
ClosureDate: any;
CreatedBy: any;
CreatedOn: any;
CurrentJobLevel: any;
Domain: any;
EligibleCTC: any;
EmployerStatus: any;
EmploymentType: any;
ExpectedJobLevel: any;
ExpectedStartDate: any;
Id: any;
Industry: any;
InterviewCity: any;
InterviewLocation: any;
InterviewMode: any;
InterviewState: any;
IsJoiningBonusProvided: any;
IsRelocationSupportProvided: any;
JobCity: any;
JobLevelCertifications: JobLevelCertification[];
JobLevelQualifications: JobLevelQualification[];
JobLevelSkills: JobLevelSkill[];
JobLocation: any;
JobPostTotalBaseScore: any;
JobPostedBy: any;
JobPostedFor: any;
JobPostedOn: any;
JobReqId: any;
JobRequirements: JobRequirement;
JobState: any;
JobStatus: any;
JobTitle: any;
JoiningBonusAmt: any;
LeadershipBaseScore: any;
LeadershipTotal: any;
NoOfActiveCand: any;
NoOfCandProposed: any;
NoOfCandRejected: any;
NoOfInterviewRounds: any;
NoOfVacancies: any;
OtherInputs: any;
PayRollType: any;
ReadyToBuyNP: any;
ReasonForJobStatus: any;
ResumeLink: any;
ShiftFromTime: any;
ShiftToTime: any;
SoftSkillBaseScore: any;
SoftSkillTotal: any;
SpRequirements: any;
TechBaseScore: any;
TechTotal: any;
TotalExp: any;
UpdatedBy: any;
UpdatedOn: any;
UsersRespForJobPost: any;
VacancyClosureDate: any;
ValueCreationBaseScore: any;
ValueCreationTotal: any;
WeeklyOff: any;
isPublished: any;
isReqForRotShifts: any;
CurrentCompDetails: CurrentCompany;
ReasonForJobChange: any;
HoldingAnyOffer: any;
RelevantExp: any;
PreferredLocations: any;
CandidatePhoto: any;
CandQualCategory: any;
CandQualSpecialization: any;
CandQualification: any;
}
