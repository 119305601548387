import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {JobService} from '../services/index';
import {UserInfo,SkillMaster,Skill} from '../models/index';
import {Config} from '../config/index';
import {Router} from '@angular/router';

@Component({
  selector: 'app-reg-prof',
  templateUrl: './registerprof.component.html',
})
export class Registerprofession implements OnInit {
  @Input() hideNav:boolean;
  @Output() subitform:EventEmitter<any> = new EventEmitter();
  submitted : boolean;
  errormessage : any;
  successmessage : any;
  currentCTC : any;
  noofyears : any;
  noofmonths : any;
  joblevel : any;
  techskills : any;
  joblevels : any;
  professionalDetail : any;
  userid : any;
  skills : SkillMaster[];
  technicals : any;
  technicalskills : SkillMaster[];
  skill : Skill;
  techskillreq : Skill[];
  years: any;
  months : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;

  constructor(private jobService: JobService,private config : Config,private router: Router) { }

  ngOnInit() {
    this.userid = sessionStorage.getItem('userid');
    this.config = new Config();
    this.submitted = false;
    this.techskillreq = [];
    this.technicalskills = [];
    this.getJobLevels();
    this.getSkills();
    this.errormessage = '';
    this.successmessage = '';
    this.professionalDetail = {};
    this.years = this.config.getNumbersList();
    this.months = this.config.getNumbersList();
    this.showalert = false;
  }

  submit(valid: boolean){
    this.submitted = true;
    this.errormessage = '';
    this.successmessage = '';
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;

    if(valid == true){

      this.professionalDetail.CurrentCTC = this.currentCTC;
      this.professionalDetail.CurrentJobLevel = this.joblevel;
      if(this.noofyears && this.noofmonths){
        this.professionalDetail.TotalExp = (this.noofyears)* 12 + parseInt(this.noofmonths);
      }
      this.professionalDetail.UserId = this.userid;
      this.professionalDetail.AdminId = this.userid;
      console.log('Finally formed mobile number : ', this.professionalDetail);

      this.jobService.createProfesssionalDetails(this.professionalDetail).subscribe(
        data => {
          this.submitted = false;
          console.log('User registration data received :', data);
                if(data.success == true){
                  if(this.techskillreq && this.techskillreq.length > 0){
                    this.createSkillDetails();
                  } else {
                    //this.router.navigate(['/login']);
                    this.subitform.emit('login');
                  }
                  
                } else {
                  this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  this.errormessage = data.error;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          console.log('Error occurred : ', error);
              });
    } else {
      console.log('User form not valid');
    }
  }
  
  getJobLevels(){
    this.jobService.getJobLevels().subscribe(
      data => {
              if(data.success == true){
                this.joblevels = data.Results;
              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
    }

    createSkillDetails(){
      this.populateSkillsToObject();
      this.jobService.createSkillDetails(this.techskillreq).subscribe(
        data => {
          console.log('User registration data received :', data);
                if(data.success == true){
                  this.router.navigate(['/login']);
                } else {
                  this.errormessage = data.error;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          console.log('Error occurred : ', error);
              });
    }

    populateSkills(){
      for(var skill of this.skills){
       
        if(skill.SkillType == 'Technical'){
          this.technicalskills.push(skill);
        }
        
      }
    }

    populateSkillsToObject(){
      
      for(var technicalskill of this.technicals){
          this.skill = new Skill();
          this.skill.SkillName = technicalskill;
          this.skill.Category = 'Technical';
          this.skill.isCore = '1';
          this.skill.UserId = this.userid;
          this.skill.AdminId = this.userid;
          this.techskillreq.push(this.skill);
      }     
    }

    getSkills(){

      this.jobService.getSkills(this.userid).subscribe(
        data => {
                if(data.success == true){
                  this.skills = data.Results;
  
                  if(this.skills && this.skills.length > 0){
                    this.populateSkills();
                  }

                } else {
                  this.errormessage = data.error;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          console.log('Error occurred : ', error);
              });
      
      }

      closeAlert(event : any){
        if(event){
          this.showalert = false;
        }
      }

}
