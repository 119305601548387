import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-welcome-screen',
  templateUrl: './welcomescreen.component.html',
})
export class WelcomeScreen implements OnInit {

  slidevalue : any;
  
  constructor(private router: Router) { }

  ngOnInit() {
    this.slidevalue = 0;
  }

  handleClick(){
    this.router.navigate(['/jobsearch']);
  }

  viewJobBoard(){
    sessionStorage.setItem('currentroute', this.router.url);
    var userid = sessionStorage.getItem('userid');
    if(userid && userid !== 'null'){
      this.router.navigate(['/jobsearch']);
    } else {
      sessionStorage.setItem('currentroute', this.router.url);
      sessionStorage.setItem('navfrom', 'jobsearch');
      this.router.navigate(['/alertlogin'] );
    }
      
    //this.router.navigate(['/jobsearch']);
    console.log('Slider value obtained : ', this.slidevalue);
/* 
    if(this.slidevalue > 9){
      this.router.navigate(['/jobsearch']);
      this.slidevalue = 0;
    } */
  }
  viewDashboard(route){
    var userid = sessionStorage.getItem('userid');
    if(userid && userid !== 'null'){
      this.router.navigate([route]);
    } else {
      sessionStorage.setItem('currentroute', this.router.url);
        sessionStorage.setItem('navfrom', 'dashboard');
      this.router.navigate(['/alertlogin']);
    }
  }
  viewProSkiScore(route){
    if(route == '/proskiscore' || route == '/jobsearch'){
      var userid = sessionStorage.getItem('userid');
      if(userid && userid !== 'null'){
        this.router.navigate([route]);
      } else {
        sessionStorage.setItem('currentroute', this.router.url);
        sessionStorage.setItem('navfrom', 'proski');
        this.router.navigate(['/alertlogin']);
       
      
      }
    } else {
      this.router.navigate([route]);
    }
    //this.router.navigate(['/proskiscore']);
   /*  console.log('Slider value obtained : ', this.slidevalue);

    if(this.slidevalue > 9){
      this.router.navigate(['/proskiscore']);
      this.slidevalue = 0;
    } */
  }

}
