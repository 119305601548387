export class SkillDetailsProfile {
AdminId: any;
Badge: any;
BadgeDetails: any;
CandidateId: any;
Category: string;
CertificationNames: any;
Certifications: any;
CreatedBy: any;
CreatedOn: any;
Duration: any;
EmployerRating: any;
Id: any;
InternalRating: any;
LastUsed: any;
OneYearAvgRating: any;
ProfileCompletionScore: any;
SelfRating: any;
SkillMasterId: any;
SkillName: string;
SystemRating: any;
UpdatedBy: any;
UpdatedOn: any;
isCertified: any;
isCore: any;
UserId : any;
}
