
export class JobRequirement {
    CreatedBy: any;
    CreatedOn: any;
    JobDesc: string;
    JobFunction: any;
    JobLevel: any;
    JobReqId: any;
    MaxExpReqd: any;
    MinExpReqd: any;
    UpdatedBy: any;
    UpdatedOn: any;
    
    
}
