import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class Header implements OnInit {
  @Input() name: string;
  @Input() showBackArrow : boolean;
  @Input() showBergerMenu : boolean;
  loginshow : boolean;
  expand : boolean;
  username : string;
  role : string;
  fontSize:['Small', 'Normal', 'Large'];

  constructor(private router: Router,private location: Location) { }

  ngOnInit() {
    this.expand = false;
    this.decideLoginOrSignout();
    //document.getElementById('nav').style.width = '0%';
  }
/*   smallerFontSize(){
  document.documentElement.style.setProperty('--font-small', `0.64em`);//9px
   document.documentElement.style.setProperty('--font-normal', `0.92em`);//13px
   document.documentElement.style.setProperty('--font-large', `1.21em`);//17px
   document.documentElement.style.setProperty('--font-huge', `2.21em`);//31px

    console.log('smallfontsmall: ',document.documentElement.style.getPropertyValue('--font-small'));
    console.log('smallfontnormal',document.documentElement.style.getPropertyValue('--font-normal'));
    console.log('smallfontlarge ',document.documentElement.style.getPropertyValue('--font-large'));
  } */

  normalFontSize(){
   
   document.documentElement.style.setProperty('--font-small', `0.71em`);//10px
    document.documentElement.style.setProperty('--font-normal', `1em`);//14px
    document.documentElement.style.setProperty('--font-large', `1.28em`);//18px
    document.documentElement.style.setProperty('--font-huge', `2.28em;`);//32px
 

     console.log('normalfontsmall: ',document.documentElement.style.getPropertyValue('--font-small'));
     console.log('normalfontnormal',document.documentElement.style.getPropertyValue('--font-normal'));
     console.log('normalfontlarge ',document.documentElement.style.getPropertyValue('--font-large'));
   }

   largerFontSize(){
 
   document.documentElement.style.setProperty('--font-small', ` 0.78em`);//11px
    document.documentElement.style.setProperty('--font-normal', `1.07em`);//15px
    document.documentElement.style.setProperty('--font-large', `1.35em`);//19px
    document.documentElement.style.setProperty('--font-huge', `2.35em`);//33px
 
 
     console.log('largefontsmall: ',document.documentElement.style.getPropertyValue('--font-small'));
     console.log('largefontnormal',document.documentElement.style.getPropertyValue('--font-normal'));
     console.log('largefontlarge ',document.documentElement.style.getPropertyValue('--font-large'));
   }

   changefont(event){
     console.log("font changed...",event)
   }

  expandNav(){
    this.decideLoginOrSignout();
    this.expand = true;
    
    console.log('current route : ', this.router.url);
    
  }

  closeNav(){
    this.expand= false;
  }

  goBack(){
    this.location.back();
  }

  login(){
    this.expand = false;
    sessionStorage.setItem('currentroute', this.router.url);
    this.router.navigate(['/alertlogin']);
  }

  gotoPage(route){
    this.expand = false;
    console.log("prodskifuvhvjijv",route);
    
    
    if(route == '/proskiscore' || route == '/jobsearch'){
      var userid = sessionStorage.getItem('userid');
      if(userid && userid !== 'null'){
        console.log("prodski",userid);
        this.router.navigate([route]);
      } else {
        console.log("elseprod",userid);
        sessionStorage.setItem('currentroute', this.router.url);
        this.router.navigate(['/alertlogin']);
      }
    } 
    else if(route == '/dashboard'){
      var userid = sessionStorage.getItem('userid');
      sessionStorage.removeItem('jobreqid');
      if(userid && userid !== 'null'){
        this.router.navigate([route]);
      } else {
        sessionStorage.setItem('currentroute', this.router.url);
        this.router.navigate(['/alertlogin']);
      }
    } else {
      this.router.navigate([route]);
    }
  }

  signout(){
    sessionStorage.clear();
    localStorage.clear();
    this.expand = false;
    //this.router.navigate(['/jobsearch']);
    this.router.navigate(['/welcome']);
    //sessionStorage.setItem('userid', null);
    sessionStorage.removeItem('userid');
  }

  decideLoginOrSignout(){
    var userid = sessionStorage.getItem('userid');
    if(userid && userid !== 'null'){
      this.username = sessionStorage.getItem('fname');
      this.role = 'Professional';
      this.loginshow = false;

    } else {
      this.loginshow = true;
    }
  }

}
