import { SkillBadgeDetails, Skill } from './index';


export class CompanyDetails {
    Id: any;
    AdminId : any;
    UserId: any;
    CompanyName: string;
    Designation: string;
    Achievements: string;
    FromDate: string;
    ToDate: string;
    Responsibility: string;
    LastCTC: string;
    AwardsAndRecognition: string;
    skills: Skill[];
    SkillBadgeDetails: SkillBadgeDetails[];
    Industry: string;
    Domain: string;
    Function: string;
    JobLevel: string;
    JobCategory: string;
    CreatedBy: string;
    CreatedOn: string;
    UpdatedBy: string;
    UpdatedOn: string;
    ProfileCompletionScore: any;
}
