import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {JobService} from '../services/index';
import {UserInfo,Response} from '../models/index';
import {Job} from '../models/index';
import {Router} from '@angular/router';
import {Config} from '../config/index';


@Component({
  selector: 'app-user-login',
  styleUrls: [ './login.component.css'],
  templateUrl: './login.component.html'
})
export class Login implements OnInit {
  @Input() hideNav:boolean;
  @Output() signInForm:EventEmitter<any> = new EventEmitter();
  userInfo : UserInfo;
  submitted : boolean;
  emailid : string;
  pwd : string;
  errormessage : any;
  successmessage : any;
  showTCCheckbox : boolean;
  loginresponse : Response;
  TC : false;
  show : boolean;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;
  firstNum : any;
  secondNum : any;
  captchaRes : number;
  captchaDisplay : any;
  captchaValue : any;
  captchavalid : boolean;
  navParam: any;
  constructor(private jobService: JobService, private router: Router,private config : Config,) { }

  ngOnInit() {
    this.submitted = false;
    this.userInfo = new UserInfo();
    this.showTCCheckbox = false;
    this.errormessage = '';
    this.successmessage = '';
    this.show= false;
    this.showalert  = false;
    this.firstNum = this.config.makeid(1);
    this.secondNum = this.config.makeid(1);
    this.captchavalid = true;
    this.calculateCapthaRes();
    this.formCaptchaDisplay();
    console.log('Two random numbers obtained : ', this.firstNum , this.secondNum);
  }

  login(valid: boolean){
    console.log("loginnnn:")
    this.submitted = true;
    this.errormessage = '';
    this.successmessage = '';
    this.userInfo.EmailId = this.emailid;
    this.userInfo.Password = this.pwd;
    this.userInfo.Role = 'candidateUser';
    this.showalert = false;
    
    if(valid == true){
      if(this.TC){
        this.userInfo.isTnCAccepted = 1;
      } else {
        this.userInfo.isTnCAccepted = 0;
      }

      if(!this.isValidCaptcha()){
        return;
      }

      this.userInfo.CaptchaRandomReq1 = this.config.encryptData(this.firstNum);
      this.userInfo.CaptchaRandomReq2 = this.config.encryptData(this.secondNum);
      this.userInfo.CaptchaResponse =  this.config.encryptData(this.captchaValue + '');

      this.jobService.login(this.userInfo).subscribe(
        data => {
          this.submitted = false;
          console.log('Generate otp data received :', data);
              if(data.success == true){
                  console.log('Login success');
                  this.loginresponse = data.Result;
                  console.log("loginresponse",this.loginresponse);
                  console.log('Session token obtained ', this.loginresponse.SessionToken);
                  sessionStorage.setItem('sessiontoken', this.loginresponse.SessionToken);
                  sessionStorage.setItem('fname', this.loginresponse.FirstName);
                  sessionStorage.setItem('lname', this.loginresponse.LastName);
                  sessionStorage.setItem('email', this.loginresponse.EmailId);
                  sessionStorage.setItem('userid', this.loginresponse.UserId);
                  //this.router.navigate(['/userprofile']);
                  //this.router.navigate(['/jobopportunities']);
                  var currentUrl = sessionStorage.getItem('currentroute');
                  var navFrom = sessionStorage.getItem('navfrom');
                  var jobId = sessionStorage.getItem('jobreqid');
                  
                  console.log('current url obtained : ', currentUrl, navFrom);
                  this.router.navigate(['/dashboard']);
                  // if(currentUrl == '/welcome' || currentUrl == '/'){
                  //   console.log('Redirecting to current url',this.navParam);
                  //   if(navFrom == 'jobsearch'){
                  //     this.router.navigate(['/jobsearch'])
                  //   }else if(navFrom == 'dashboard'){
                  //     this.router.navigate(['/dashboard']);
                  //   }
                  //   else{
                  //     this.router.navigate(['/proskiscore']);
                  //   }
                  // } else {
                  //   if(jobId){
                  //     this.router.navigate(['/jobprofile']);
                  //   }
                  //   else{
                  //   this.router.navigate(['/jobsearch']);
                  //   }
                  // }
                  
                  //this.router.navigate(['/dashboard']);
                } else {
                  this.errormessage = data.error;
                   this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  if(this.errormessage == 'Kindly accept our Terms and conditions please!'){
                    this.responseMessage ='Kindly accept our Terms and conditions please!';
                    this.showTCCheckbox = true;
                  }
                  this.firstNum = this.config.makeid(1);
                  this.secondNum = this.config.makeid(1);
                  this.formCaptchaDisplay();
                  this.calculateCapthaRes();
                  console.log('Error message : ', data.error);
                  
                }
        },
        error => {
          this.submitted = false;
          this.responseMessage = 'Error occurred while login';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          console.log('Error occurred : ', error);
              });
      
    } else {
      console.log('User form not valid');
      
    }
  }

  gotoPage(route){
    this.signInForm.emit(route);
    //this.router.navigate([route]);
    
  }

  togglepassword(){
    console.log('Show initial : ', this.show);
    this.show = !this.show;
    console.log('Show final : ', this.show);
  }

  closeAlert(event : any){
    if(event){
      this.showalert = false;
    }
  }

 calculateCapthaRes(){
    this.captchaRes = parseInt(this.firstNum) + parseInt(this.secondNum);
    console.log('Captcha result obtained : ', this.captchaRes);
 }

 formCaptchaDisplay(){
   this.captchaDisplay = this.firstNum + ' + ' + this.secondNum + ' = ';
   console.log('captcha display :: ', this.captchaDisplay);
  
 }

 isValidCaptcha(){
  if(parseInt(this.captchaValue) == this.captchaRes){
    console.log('Entered captcha is valid');
    this.captchavalid = true;
    return true;
  }
  this.captchavalid = false;
  return false;
 }

 resetSubmit(){
  this.submitted = false;
  this.captchavalid = true;
}


}
