import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { JobService } from "../services/index";
import { UserInfo, Response } from "../models/index";
import { Router, ActivatedRoute  } from "@angular/router";
import { Config } from "../config/index";
import { SERVER_URL_HOST } from "../../environments/environment";
import { MatDialog } from "@angular/material/dialog";
import { ProfessionalDetailsUpdate } from "../userprofile/professionaldetailsupdate.component";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
})
export class Dashboard implements OnInit {
  jobreqid:any;
  fname: any;
  lname: any;
  email: any;
  userId: any;
  Id: any;
  safeUrl: any;
  userInfo: Response;
  usrInfo: Response;
  isImageUploaded: boolean;
  serverUrl: any;
  dialogRef: any;
  imgPath: any;
  message: any;
  responseMessage: any;
  typeOfResponseMessage: any;
  showalert: boolean;
  selectedImageFile: File;
  selectedFile: File;
  uploaderrormsg: any;
  uploadsuccessmsg: any;
  uploadreq: any;
  uploadedImage: any;
  errormessage: any;
  successmessage: any;
  validFileFormats: any;
  validImageFormats: any;
  config: Config;
  file: any;
  selecetdFile: File;
  imagePreview: string;
  professionDetails: any;
  skillDetails: any;
  showProfile: any;
  showProski:boolean = false;
  showcareer:boolean =false;
  showjob:any;
  badgeArray:any;
  isClicked: boolean = false;
  isProskiClicked: boolean = false;
  isJobClicked:boolean =false;
  isCareerClicked:boolean =false;
  showIframe: boolean = false;
  iframeUrl: string = "";
  selectedSkills: any;
  constructor(
    private router: Router,
    private jobService: JobService,
    public dialog: MatDialog,
    private sanitizer: DomSanitizer,
    public activatedRoute:ActivatedRoute
  ) {}

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnDestroy() {
    console.log("In ngOnDestroy of dashboard");
  }
  ngOnChanges(){
    this.dashboardLoad();
  }
  ngOnInit() {
    console.log("In ngoninit of dashboard");
    this.activatedRoute.params.subscribe(params => {
      console.log("activated router method",params);
      this.dashboardLoad();
      this.getjobdetails();
    });
   
  }
  getjobdetails()
  {
    this.userId=sessionStorage.getItem('userid');
    this.jobreqid=sessionStorage.getItem('jobreqid');
    if(this.userId!=null && this.jobreqid!=null)
    {
    //  const queryString  =`id=${this.jobreqid}`;
      this.router.navigate([`/jobprofile/${this.userId}/${this.userId}/${this.jobreqid}`]);
    }
  }

  dashboardLoad(){
    this.isClicked = false;
    this.showProfile = false;
    this.showProski = false;
    this.showjob = false;
    this.showcareer = false;
    this.serverUrl = SERVER_URL_HOST;
    this.imgPath = "";
    this.isImageUploaded = false;
    this.showalert = false;
    this.uploaderrormsg = "";
    this.uploadsuccessmsg = "";
    this.config = new Config();
    this.usrInfo = new Response();
    this.validFileFormats = this.config.getFileFormats();
    this.validImageFormats = this.config.getImageFormats();
    this.fname = sessionStorage.getItem("fname");
    this.showIframe = false;
    if (!this.fname) {
      this.fname = "";
    }
    this.lname = sessionStorage.getItem("lname");
    if (!this.lname) {
      this.lname = "";
    }
    this.email = sessionStorage.getItem("email");
    if (!this.email) {
      this.email = "";
    }
    this.Id = "";
    this.userId = sessionStorage.getItem("userid");
    
    this.getSkillDetails();
    this.getUserprofileData();
    this.getCupProfessionalDetails();
   
  }
  monthsToYearsAndMonths(months) {
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
  
    return `${years} year${years !== 1 ? 's' : ''} ${remainingMonths} month${remainingMonths !== 1 ? 's' : ''}`;
  }
  getCupProfessionalDetails() {
    console.log("userid and id from session : ", this.userId, this.Id);
    this.jobService.getCupProfessionalDetails(this.userId).subscribe(
      (data) => {
        if (data.success == true) {
         
          console.log("professionDetails", this.professionDetails);
          data.Result.experinceinYears = this.monthsToYearsAndMonths(data.Result.TotalExp);
          this.professionDetails = data.Result;
         
          
          // this.professionScore = this.professionDetails.ProfileCompletionScore;
          // if(this.professionDetails.Id > 0){
          //   this.noInfoAdded = false;
          //   this.processData();
          // }
        } else {
          this.responseMessage = data.error;
          this.typeOfResponseMessage = "error";
          this.showalert = true;
          this.errormessage = data.error;
          console.log("Error message : ", data.error);
        }
      },
      (error) => {
        this.responseMessage = "Error occurred while getting user data";
        this.typeOfResponseMessage = "error";
        this.showalert = true;
        console.log("Error occurred : ", error);
      }
    );
  }
  gotoPage(route) {
    this.showalert = false;
    this.showProfile = true;
   
    if (route == "/availcareerservices") {
      this.isClicked = false;
      this.showProfile = false;
      this.showProski=false;
      this.showjob=false;
      this.showcareer= true;
      this.typeOfResponseMessage = "error";
      console.log("showalert", this.showalert);
      // this.responseMessage =
      //   "Currently, there are no services for your profile. Please keep visiting this page.";
      // this.showalert = !this.showalert;
    } else {
      if(route == "/userprofile"){
        this.isClicked = true;
        this.showProfile = true;
        this.showProski=false;
        this.showjob=false;
        this.showcareer= false;
      }
      if(route == "/proskiscore"){
        this.isClicked = false;
        this.showProfile = false;
        this.showProski=true;
        this.showjob=false;
        this.showcareer= false;
      }
      if(route == "/jobopportunities"){
        this.isClicked = false;
        this.showProfile = false;
        this.showProski=false;
        this.showjob=true;
        this.showcareer= false;
      }
      //this.router.navigate([route]);
    }
  }

  changeImage(event, type) {
    console.log("event in profile:", event);
    if (event.srcElement.files && event.srcElement.files[0]) {
      var e = document.getElementById(type);
      e.setAttribute(
        "src",
        window.URL.createObjectURL(event.srcElement.files[0])
      );
      if (type == "imgupload") {
        this.selectedImageFile = event.srcElement.files[0];
        console.log("Uploaded image :  ", this.selectedImageFile);
        console.log("Uploaded image name:  ", this.selectedImageFile.name);
        console.log("Uploaded image name:  ", this.selectedImageFile.type);
      } else {
        this.selectedFile = event.srcElement.files[0];
        console.log("Uploaded file :  ", this.selectedFile);
        console.log("Uploaded file name:  ", this.selectedFile.name);
        console.log("Uploaded file name:  ", this.selectedFile.type);
      }
      this.uploadImage();
    }
  }

  uploadImage() {
    this.uploaderrormsg = "";
    this.uploadsuccessmsg = "";
    this.uploadreq = {};
    console.log("uploadimage", this.uploadreq);
    this.uploadreq.UserId = this.userId;
    this.uploadreq.AdminId = this.userId;
    this.uploadreq.FileType = this.getFileType(this.selectedImageFile.name);
    if (!this.checkIfValidFile(this.uploadreq.FileType, "image")) {
      console.log("Uploaded file format is not correct");
      this.responseMessage = "Only png or jpg or jpeg file allowed";
      this.typeOfResponseMessage = "error";
      this.showalert = true;
      return;
    }
    // this.typeOfResponseMessage = '';
    //this.responseMessage = '';
    //this.showalert = false;
    //this.isImageUploaded = false;
    this.jobService
      .uploadFile(this.uploadreq, this.selectedImageFile)
      .subscribe(
        (data) => {
          if (data.success == true) {
            console.log("data result : ", data.Result);
            this.uploadedImage = data.Result;
            this.isImageUploaded = true;
            this.imgPath = SERVER_URL_HOST + "/" + this.uploadedImage;
            this.uploadsuccessmsg = "Successfully uploaded image";
            this.responseMessage = "Successfully uploaded image";
            this.typeOfResponseMessage = "success";
            this.showalert = true;
          } else {
            this.responseMessage = "Failed to upload image";
            this.typeOfResponseMessage = "error";
            this.showalert = true;
            this.errormessage = data.error;
            this.uploaderrormsg = "Failed to upload image";
            console.log("Error message : ", data.error);
          }
        },
        (error) => {
          console.log("Error occurred : ", error);
        }
      );
  }

  getFileType(fileName: any) {
    var fileType = "";
    if (fileName.indexOf(".") > 0) {
      fileType = fileName.substr(
        fileName.indexOf(".") + 1,
        fileName.length - 1
      );
      console.log("File type : ", fileType);
    }
    return fileType;
  }

  checkIfValidFile(fileType: any, category: any): boolean {
    if (category == "resume") {
      if (fileType) {
        if (this.validFileFormats.includes(fileType)) {
          return true;
        }
      }
      return false;
    }

    if (category == "image") {
      if (fileType) {
        if (this.validImageFormats.includes(fileType)) {
          return true;
        }
      }
      return false;
    }
  }

  openEditDialog() {
    this.dialogRef = this.dialog.open(ProfessionalDetailsUpdate, {
      width: "100%",
      height: "80%",
      closeOnNavigation: true,
      data: { profdetail: this.professionDetails },
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      console.log("in dialog close test : ", result);
      if (result) {
        console.log("Updating data");
        this.professionDetails = result;
        this.updateInfo();
      } else {
        //this.ngOnInit();
        //this.getCupProfessionalDetails();
        //this.router.navigate(['/professionaldetails']);
        /*this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/professionaldetails']);
      });*/
        // this.router.navigate(["/professionaldetails"]).then(() => {
        //   window.location.reload();
        // });
      }
    });
  }
  updateInfo() {
    this.successmessage = "";
    this.errormessage = "";
    this.typeOfResponseMessage = "";
    this.responseMessage = "";
    this.showalert = false;
    this.professionDetails.AdminId = this.userId;
    this.jobService
      .updateProfesssionalDetails(this.professionDetails)
      .subscribe(
        (data) => {
          if (data.success == true) {
            console.log("data result : ", data.Result);
            this.successmessage = "professional details updated successfully";
            this.responseMessage = "Professional details updated successfully";
            this.typeOfResponseMessage = "success";
            this.showalert = true;
            this.professionDetails = data.Result.CupProfessionalDetail;
            // this.profilecompletionscore = data.Result.TotalProfileCompletionScore;
            this.professionDetails.experinceinYears = this.monthsToYearsAndMonths(data.Result.CupProfessionalDetail.TotalExp);
            sessionStorage.setItem(
              "ProfileCompletionScore",
              data.Result.CupProfessionalDetail
            );
            //this.processData();
            //this.router.navigate(['/dashboard']);
          } else {
            this.errormessage = data.error;
            this.responseMessage = data.error;
            this.typeOfResponseMessage = "error";
            this.showalert = true;
            console.log("Error message : ", data.error);
          }
        },
        (error) => {
          console.log("Error occurred : ", error);
        }
      );
  }

  getUserprofileData() {
    if (this.userId) {
      console.log("userid and id from session : ", this.userId, this.Id);
      this.jobService.getCandidateDetails(this.userId, this.Id).subscribe(
        (data) => {
          if (data.success == true) {
            this.userInfo = data.Result;
            console.log("userinfo", this.userInfo);
            if (this.userInfo.CandPhotoLink) {
              this.isImageUploaded = true;
              this.imgPath = this.serverUrl + "/" + this.userInfo.CandPhotoLink;
            }
          } else {
            console.log("Error message : ", data.error);
          }
        },
        (error) => {
          console.log("Error occurred : ", error);
        }
      );
    }
  }

  getSkillDetails() {
    this.skillDetails = [];
    console.log("userid from session : ", this.userId);
    this.jobService.getSkillDetails(this.userId).subscribe(
      (data) => {
        if (data.success == true) {
          this.skillDetails = data.Results;
          console.log("this.skillDetails", this.skillDetails);

          if (this.skillDetails && this.skillDetails.length > 0) {
            sessionStorage.setItem(
              "SkillProfileCompletionScore",
              this.skillDetails[0].ProfileCompletionScore
            );
          }
          console.log("Skill details obtained : ", this.skillDetails);
          const resultObject: { [key: string]: string[] } = {};
          const skillNames = this.skillDetails.map((skill) =>
            skill.SkillName.toLowerCase()
          );
          this.selectedSkills = skillNames.join(", ");
          console.log( this.selectedSkills);
          // Iterate through the input array and populate the resultObject
          this.skillDetails.forEach((item:any) => {
            console.log("bfhhfbhf",item);
            if(item.BadgeDetails && item.BadgeDetails.length > 0 && item.BadgeDetails[0].SkillBadge){
            const skillBadge = item.BadgeDetails[0].SkillBadge;
            const skill = item.BadgeDetails[0].Skill;

            if (!resultObject[skillBadge]) {
              resultObject[skillBadge] = [skill];
            } else {
              resultObject[skillBadge].push(skill);
            }}
          });

          // Convert the resultObject to an array of objects
          const resultArray = Object.keys(resultObject).map((key) => ({
            [key]: resultObject[key],
          }));
           this.badgeArray = resultArray;
          console.log("cjdhksns",this.badgeArray);
        } else {
          // this.errormessage = data.error1;
          // this.responseMessage = data.error1;
          // this.typeOfResponseMessage = "error";
          // this.showalert = true;
          // console.log("Error message : ", data.error);
        }
      },
      (error) => {
        this.responseMessage = "Error occurred while getting user data";
        this.typeOfResponseMessage = "error";
        this.showalert = true;
        console.log("Error occurred : ", error);
      }
    );
  }

 


  getKey(item: any): string {
    return Object.keys(item)[0];
  }
  
  getValue(item: any): string {
    return item[this.getKey(item)].join(', ');
  }
  onFileUpload(event) {
    this.file = event.target.files[0];
  }

  closeAlert(event: any) {
    if (event) {
      this.showalert = false;
    }
  }
}
