import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {Job} from '../models/index';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
//import {Plugins, ShareOptions} from '@capacitor/core';
//const {Share} = Plugins;


@Component({
  selector: 'app-job-item',
  templateUrl: './jobitem.component.html',
})
export class JobItem implements OnInit {
  @Input() jobData: Job;
  @Input() showJobProski: boolean;
  @Input() jobviewdata: Map<number, any>;
  jobpostedfor : any;
  ectc : any;
  showData : boolean;

  constructor(private router: Router,private socialSharing: SocialSharing) { }

  ngOnInit() {
    this.initializeData();
  }

  convertCTC(value){
    if(value && value !== 'confidential') {
      var noOfDigits = value.toString().length;

      if(noOfDigits >= 6 ){
        var num = Math.floor(value/100000);

        if(num == 1){
          return num.toString()+'Lac*';
        } else {
          return num.toString()+'Lacs*';
        }
      } 
    } else if (value == 'confidential' && this.jobData.EligibleCTC){
      return 'confidential';
    } else if (value == 'confidential' && !this.jobData.EligibleCTC){
      return ' ';
    }else {
      return ' ';
    }
  }

  applyJob(){
    sessionStorage.setItem('jobreqid', this.jobData.JobReqId);
    var userid = sessionStorage.getItem('userid');
    if(userid && userid !== 'null'){
      this.router.navigate(['/jobprofile',userid,userid,this.jobData.JobReqId]);
    } else {
      this.router.navigate(['/alertlogin']);
    }
    
  }
  async share(desc: string): Promise<void> {
    try {
      await navigator.share({
        title: 'Share Job',
        text: desc
      });
      console.log('Job shared successfully using Web Share API.');
    } catch (error) {
      console.error('Error sharing the job using Web Share API:', error);
    }
  }

  // share(desc : any){

  //   var options = {
  //   message : desc
  //   };
  //   this.socialSharing.shareWithOptions(options);
  // }

  initializeData(){
    console.log('In initializeData : ',this.jobviewdata);
   
    if(this.jobviewdata){

      var obj = this.jobviewdata.get(this.jobData.Id);

      if(obj.showjobpostedfor == 1){
        this.jobpostedfor = this.jobData.JobPostedFor;
      } else {
        this.jobpostedfor = 'confidential';
      }

      if(obj.showctc == 1){
        this.ectc = this.jobData.EligibleCTC;
      } else {
        this.ectc = 'confidential';
      }
      this.showData = true;

    }
  }

}
