import {  Pipe, PipeTransform } from '@angular/core';
import { JobFilter} from '../models/index';

@Pipe({
  name: 'SearchPipe'
})

export class SearchPipe implements PipeTransform {

  transform(items: Array<any>, industry : any, domain : any, joblevel : any, jobcity : any ): Array<any> {
    console.log('Items : ', items);
    console.log('Filter : ', industry,domain,joblevel,jobcity);
    return items.filter(item => {
           /* let notMatchingField = Object.keys(filter)
                                         .find(key => item[key] !== filter[key]);
            console.log('notMatchingField : ', notMatchingField);
            return !notMatchingField;*/
             // true if matches all fields
            if(!industry && !domain && !joblevel && !jobcity ){
              return true;
            }

            let notMatchingField = false;
            if(industry && !domain && !joblevel && !jobcity){
              notMatchingField =  item['Industry'] !== industry;
            } else if(!industry && domain && !joblevel && !jobcity){
              notMatchingField =  item['Domain'] !== domain;
            } else if(!industry && !domain && joblevel && !jobcity){
              notMatchingField =  item['JobLevel'] !== joblevel;
            } else if(!industry && !domain && !joblevel && jobcity){
              notMatchingField =  item['JobCity'] !== jobcity;
            } else if(industry && domain && !joblevel && !jobcity){
              notMatchingField =  (item['Industry'] !== industry || item['Domain'] !== domain);
            } else if(!industry && domain && joblevel && !jobcity){
              notMatchingField =  (item['JobLevel'] !== joblevel || item['Domain'] !== domain);
            } else if(!industry && !domain && joblevel && jobcity){
              notMatchingField =  (item['JobLevel'] !== joblevel || item['JobCity'] !== jobcity);
            } else if(industry && !domain && !joblevel && jobcity){
              notMatchingField =  (item['Industry'] !== industry || item['JobCity'] !== jobcity);
            } else if(industry && domain && joblevel && !jobcity){
              notMatchingField =  (item['Industry'] !== industry || 
              item['Domain'] !== domain || item['JobLevel'] !== joblevel);
            } else if(!industry && domain && joblevel && jobcity){
              notMatchingField =  (item['JobCity'] !== jobcity || 
              item['Domain'] !== domain || item['JobLevel'] !== joblevel);
            } else if(industry && !domain && joblevel && jobcity){
              notMatchingField =  (item['JobCity'] !== jobcity || 
              item['Industry'] !== industry || item['JobLevel'] !== joblevel);
            } else if(industry && domain && !joblevel && jobcity){
              notMatchingField =  (item['JobCity'] !== jobcity || 
              item['Industry'] !== industry || item['Domain'] !== domain);
            } else if(industry && domain && joblevel && jobcity){
              notMatchingField =  (item['JobCity'] !== jobcity || 
              item['Industry'] !== industry || item['Domain'] !== domain 
              || item['JobLevel'] !== joblevel);
            }

            return !notMatchingField;
             //return true;
        });
}
  

}
