import { Component, OnInit, Input } from '@angular/core';
import {JobService} from '../services/index';
import {UserInfo, Response} from '../models/index';
import {Router} from '@angular/router';
import * as c3 from 'c3';

@Component({
  selector: 'app-proski-score',
  templateUrl: './proskiscore.component.html',
})
export class ProskiScore implements OnInit {
  @Input() hideNav: boolean;
  userId : any;
  selfassessmentproski : Response;
  thirdpartyproski : Response;
  errormessage : any;
  acadscore: any;
  softskillscore: any;
  techscore : any;
  valuecreationscore : any;
  scopeforimprovement : any;
  leadershipscore : any;
  selfassessmentproskiscore : any;
  thirdpartyproskiscore : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;
  
  constructor(private router: Router,private jobService : JobService) { }
  

  ngOnInit() {
    this.userId = sessionStorage.getItem('userid');
    this.getSelfAssessmentProski();
    this.showalert = false;
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  getSelfAssessmentProski(){

    this.jobService.getSelfAssessmentProski(this.userId, this.userId).subscribe(
      data => {
              if(data.success == true){
                this.selfassessmentproski = data.Result;
                this.getThirdPartyProski();
                
              } else {
                this.errormessage = data.error;
                this.responseMessage = data.error;
                this.typeOfResponseMessage = 'error';
                this.showalert = true;
                console.log('Error message : ', data.error)
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
    
    }

    getThirdPartyProski(){

      this.jobService.getThirdPartyProski(this.userId, this.userId).subscribe(
        data => {
                if(data.success == true){
                  this.thirdpartyproski = data.Result;
                  this.processscore();
                  
                } else {
                  this.errormessage = data.error;
                  this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  this.errormessage = data.error;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          console.log('Error occurred : ', error);
              });
      
      }

      processscore(){
        if(this.selfassessmentproski){
          this.acadscore = this.selfassessmentproski.SelfAcadProSkiScore;
          this.softskillscore = this.selfassessmentproski.SoftSkillProSkiScore;
          this.leadershipscore = this.selfassessmentproski.LeadershipProSkiScore;
          this.valuecreationscore = this.selfassessmentproski.ValueCreationProSkiScore;
          this.techscore = this.selfassessmentproski.TechProSkiScore;
          this.selfassessmentproskiscore = this.selfassessmentproski.SelfAssesmentProSki;
          this.thirdpartyproskiscore = this.thirdpartyproski.ThirdPartyAssessmentProSki;
          console.log('Acad score : ', this.acadscore);
          console.log('soft score : ', this.softskillscore);
          console.log('tech score : ', this.techscore);
          console.log('value creation score : ', this.valuecreationscore);
          console.log('Leadership score : ', this.leadershipscore);
          this.scopeforimprovement = (1000 - (this.acadscore +this.softskillscore +this.leadershipscore+
            this.valuecreationscore+this.techscore ));
          console.log('Scope for improvement : ', this.scopeforimprovement);


          setTimeout(()=>{ this.displayGraph(); }, 0);
          setTimeout(()=>{ this.displayGraph1(); }, 0);


        }
      }
  

  displayGraph() {
    let cols: any = [];
    var chart = c3.generate({
      bindto: '#chart' ,
      data: {
        columns: [['Academics', this.acadscore],
                  ['Soft Skills', this.softskillscore ],
                  ['Value Creation Skills', this.valuecreationscore ],
                  ['Technical Skills', this.techscore ],
                  ['Leadership Skills', this.leadershipscore ],
                  ['Scope For Improvement', this.scopeforimprovement ]],
        type : 'pie',
      
    },
    size : {
      width: 150,
      height:200
    },
    color: {
      pattern:['#619ED4','#64EB17', '#E3E649','#FFA07A','#CD853F','#FD7D24']
    },
   
    pie : {
      padAngle: 0,
      label: {
        show: false,
        threshold : 0.1
      }

    },
    legend: {
      show: false,
    },
    tooltip: {
      show: true,
    },
    
});
  }

  displayGraph1() {
    let cols: any = [];
    var chart = c3.generate({
      bindto: '#chart1' ,
      data: {
        columns: [['Academics', this.acadscore],
        ['Soft Skills', this.softskillscore ],
        ['Value Creation Skills', this.valuecreationscore ],
        ['Technical Skills', this.techscore ],
        ['Leadership Skills', this.leadershipscore ],
        ['Scope For Improvement', this.scopeforimprovement ]],
        type : 'pie',
      
    },
    size : {
      width: 150,
      height:200
    },
    color: {
      pattern:['#619ED4','#64EB17', '#E3E649','#FFA07A','#CD853F','#FD7D24']
    },
   
    pie : {
      padAngle: 0,
      label: {
        show: false,
        threshold : 0.1
      }

    },
    legend: {
      show: false,
    },
    tooltip: {
      show: true,
    },
    
});
  }

  closeAlert(event : any){
    if(event){
      this.showalert = false;
    }
  }
}
