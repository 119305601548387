import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, Route, ActivatedRoute } from '@angular/router';
import { finalize, tap } from 'rxjs/operators';
import { SessionService } from './../services/index';

declare var $: any;

@Injectable()
export class OutgoingInterceptor implements HttpInterceptor {

	constructor( private router: Router,private sessionService : SessionService) {

	}

	intercept(req: HttpRequest<any>, next: HttpHandler):

		Observable<HttpEvent<any>> {

		let ok: string;
		const started = Date.now();

		let authToken;

		let notUseToken = false;

		if (req.url.indexOf('/login') !== -1 || req.url.indexOf('/CreateRegisterUser') !== -1 ||  req.url.indexOf('/GetJobPostsForLandingPage') !== -1 || 
		req.url.indexOf('/ForgotPass') !== -1 || req.url.indexOf('/GenerateOtp') !== -1 || req.url.indexOf('/Login') !== -1
		|| req.url.indexOf('/GetIndustryDetails') !== -1 || req.url.indexOf('/GetDomainDetails') !== -1 ||
		req.url.indexOf('/GetJobLevelDetails') !== -1 || req.url.indexOf('/GetJobPostingViewDetailById') !== -1 
		|| req.url.indexOf('/GetSkills') !== -1 || req.url.indexOf('/CreateCupProfessionalDetail') !== -1 
		) {
			notUseToken = true;
		}


		if (notUseToken === true) {
			/*for oauth login api call*/
			authToken = '';

		} else if (this.sessionService.getSessionToken()) {
			authToken = this.sessionService.getSessionToken();

		}

		let reqHead = req.headers;
		//reqHead = reqHead.set('sessiontoken', authToken);
		reqHead = reqHead.set('SessionToken', authToken);

		const authReq = req.clone({
			headers: reqHead
		});


		return next.handle(authReq)
			.pipe(
				tap(
					// Succeeds when there is a response; ignore other events
					event => {
						ok = event instanceof HttpResponse ? 'succeeded' : '';
						console.log('-------------ok result ---------------- ' + ok);

						if (event instanceof HttpResponse) {
							let h: HttpHeaders;
							h = event.headers;

							
							return event;
						}
					},
					error => {
						ok = 'failed';
						console.log('-------------ok result ---------------- ' + ok);
						if ((error.status === 401 || error.status === 403)) {

							$('.modal').each(function(index, value) {
								if (value.style.display === 'block') {
									$('#' + value.id).modal('toggle');
								}
							});
							$('.modal-backdrop').hide();
							this.router.navigate(['/login']);

							return Observable.throw(error);
						}

					}
				),
				// Log when response observable either completes or errors
				finalize(() => {
					const elapsed = Date.now() - started;
					const msg = `${req.method} '${req.urlWithParams}'
                     ${ok} in ${elapsed} ms.`;
				

					// console.log( '------------------msg -----------------------' + msg );
					// this.messenger.add(msg);
				})
			);
	}

}
