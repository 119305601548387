import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {Observable} from 'rxjs';
import { SERVER_URL } from '../../environments/environment';


@Injectable()
export class JobService {

  protected basePath = '/CareerBuddiesServices';
  public url;
  public userFreeTierStatus: string;

  constructor(private httpnew: HttpClient) {

  }

  private extendObj<T1, T2>(objA: any, objB: any) {
    for (const key in objB) {
      if (objB.hasOwnProperty(key)) {
        objA[key] = objB[key];
      }
    }
    return objA as T1 & T2;
  }

  public registerUser(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/RegisterUser/CreateRegisterUser';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }
 
  public getJobpostsForLandingPage(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/JobPosting/GetJobPostsForLandingPage';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public generateOtp(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/RegisterUser/GenerateOtp';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public resetpassword(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/RegisterUser/ForgotPass';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public login(param: any, extraHttpRequestParams?: any): Observable<any> {
    //this.url = '/RegisterUser/login';
    this.url = '/RegisterUser/Login';
    const queryParameters = new HttpParams();
    console.log('Server url obtained : ', SERVER_URL);
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getCandidateDetails(userid: any,id: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CandidateDetail/GetCandidateDetailById';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userid);
    queryParameters = queryParameters.set('AdminId', userid);
    if(id){
      queryParameters =  queryParameters.set('Id', id);
    }
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getAcademicDetails(userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupAcademicDetail/GetCupAcademicDetails';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userid);
    queryParameters = queryParameters.set('AdminId', userid);

    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public updateCandidateDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    console.log("candidataeupfd",param);
    this.url = '/CandidateDetail/UpdateCandidateDetail';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public createCandidateDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CandidateDetail/CreateCandidateDetail';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getEducationLevels(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/EducationMaster/GetEducationLevels';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getQualifications(edulevel: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/EducationMaster/GetDistinctDegreeVlues';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('edulevel', edulevel);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getSpecializations(edulevel: any, degree: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/EducationMaster/GetEducationDetailsByDegree';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('EduLevel', edulevel);
    queryParameters = queryParameters.set('Degree', degree);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public createAcademicDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupAcademicDetail/CreateCupAcademicDetail';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public updateAcademicDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupAcademicDetail/UpdateCupAcademicDetail';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public deleteAcademicDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupAcademicDetail/DeleteCupAcademicDetailById';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }
  public deleteLanguageDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupLangDetail/DeleteCupLangDetailById';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getCompanyDetails(userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupCompanyDetail/GetCupCompanyDetails';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userid);
    queryParameters = queryParameters.set('AdminId', userid);

    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public createCompanyDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupCompanyDetail/CreateCupCompanyDetails ';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public updateCompanyDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupCompanyDetail/UpdateCupCompanyDetails';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public deleteCompanyDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupCompanyDetail/DeleteCupCompanyDetailsById';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getIndustries(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/IndustryMaster/GetIndustryDetails';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getDomains(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/DomainMaster/GetDomainDetails';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getFunctions(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/FunctionMaster/GetFunctionDetails';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getJobLevels(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/JobLevelMaster/GetJobLevelDetails';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getRelevantTemplates(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CareerPathTemplate/GetRelevantTmplates';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getSkills(userid : any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/SkillsMaster/GetSkills';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('AdminId', userid);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getSkillDetails(userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupSkillsDetail/GetCupSkillsDetails';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userid);
    queryParameters = queryParameters.set('AdminId', userid);

    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getSelfAssessmentProski(userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CandidateDetail/GetSelfAssessmentProSki';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userid);
    queryParameters = queryParameters.set('AdminId', userid);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getThirdPartyProski(userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CandidateDetail/GetThirdPartyProski';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userid);
    queryParameters = queryParameters.set('AdminId', userid);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getJobPostingDetails(adminId: any, candidateId: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/JobPosting/GetJobPostingDetails';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('CandidateId', candidateId);
    queryParameters = queryParameters.set('AdminId', adminId);
    //const path = 'https://careerservices.careerbuddyonline.com/CareerBuddiesServices'+this.url;
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getMatchingDetailsOfCandidate(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/JobPosting/GetMatchingDetailsOfCand';
    const queryParameters = new HttpParams();
    //const path = 'https://careerservices.careerbuddyonline.com/CareerBuddiesServices'+this.url;
    const path = SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getJobPostingDetailsById(adminId: any, candidateId: any, jobId: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/JobPosting/GetJobPostingDetailById';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('CandidateId', candidateId);
    queryParameters = queryParameters.set('AdminId', adminId);
    queryParameters = queryParameters.set('Id', jobId);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getJobPostingViewDetailsById(jobpostid: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/JobPostingView/GetJobPostingViewDetailById';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('JobPostingId', jobpostid);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getViewDetailsByShortUrlId(shortUrlId: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/JobPostingView/GetViewDetailsByShortUrlId';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('ShortUrlId', shortUrlId);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public uploadFile(param: any,file : File, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/FileUpload/UploadResume';
    const queryParameters = new HttpParams();
    //const path = 'https://careerservices.careerbuddyonline.com/CareerBuddiesServices'+this.url;
    const path = SERVER_URL+this.url;

    let uploadData : FormData = new FormData();
    uploadData.append('formfile', file, file.name);
    uploadData.append('UserId',param.UserId);
    uploadData.append('resumelink',param.resumelink);
    uploadData.append('FileType','docx');
    uploadData.append('AdminId',param.AdminId);
    return this.httpnew.post(path, uploadData);
    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public applyJob(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/ProfileUpdate/IsCandEligibleForJob';
    const queryParameters = new HttpParams();
    //const path = 'https://careerservices.careerbuddyonline.com/CareerBuddiesServices'+this.url;
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getVerificationStatus(userId: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/RegisterUser/GetVerificationStatus';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userId);
    queryParameters = queryParameters.set('AdminId', userId);

    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }


  public createProfesssionalDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupProfessionalDetail/CreateCupProfessionalDetail';
    const queryParameters = new HttpParams();
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public updateProfesssionalDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupProfessionalDetail/UpdateCupProfessionalDetail';
    const queryParameters = new HttpParams();
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public createSkillDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupSkillsDetail/CreateCupSkillsDetail';
    const queryParameters = new HttpParams();
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getCupProfessionalDetails(userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupProfessionalDetail/GetCupProfessionalDetailById';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('UserId', userid);
    queryParameters = queryParameters.set('AdminId', userid);

    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public createCertificationDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupCertificationDetail/CreateCupCertificationDetail';
    const queryParameters = new HttpParams();
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public updateSkillDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupSkillsDetail/UpdateCupSkillsDetail';
    const queryParameters = new HttpParams();
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getCertifications(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CertificationMaster/GetCertifications';
    let queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public deleteSkillDetailById(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CupSkillsDetail/DeleteCupSkillsDetailById';
    const queryParameters = new HttpParams();
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getJobTemplateDetailByCareerPathNJobLevel(careerpathid : any,joblevel : any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/CareerPathJobTemplateMapping/GetJobTemplateDetailByCareerPathNJobLevel';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('CareerPathId', careerpathid);
    queryParameters = queryParameters.set('JobLevel', joblevel);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getCitiesList(extraHttpRequestParams?: any): Observable<any> {
    this.url = '/City/GetCitiesList';
    const queryParameters = new HttpParams();
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getCitiesListForState(state: any, userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/City/GetCities';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('AdminId', userid);
    queryParameters = queryParameters.set('State', state);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getStates(userid: any,extraHttpRequestParams?: any): Observable<any> {
    this.url = '/StatesMaster/GetStates';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('AdminId', userid);
    
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public getProskiDetailsById(userid: any,jobPostingId : any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/ProSkiDetails/GetProSkiDetailById';
    let queryParameters = new HttpParams();
    queryParameters = queryParameters.set('JobPostingId', jobPostingId);
    queryParameters = queryParameters.set('CandidateId', userid);
    queryParameters = queryParameters.set('AdminId', userid);
    const path = SERVER_URL+this.url;
    return this.getByParamWithHttpInfo(queryParameters,path, extraHttpRequestParams);
  }

  public saveOrUpdatePskiDetails(param: any, extraHttpRequestParams?: any): Observable<any> {
    this.url = '/ProSkiDetails/UpdateProSkiDetails';
    const queryParameters = new HttpParams();
    const path =SERVER_URL+this.url;

    return this.postWithHttpInfo(param, path, queryParameters, extraHttpRequestParams);
  }

  public getByParamWithHttpInfo(queryParameters: HttpParams,path: any, extraHttpRequestParams?: any): Observable<any> {
   // const path = this.basePath + this.url;
   //const path = 'https://careerservices.careerbuddyonline.com/CareerBuddiesServices' + this.url;
 
    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    console.log('\nRollbar fixes : ' + path + requestOptions);
    return this.httpnew.get(path, requestOptions);
  }


  public postWithHttpInfo(param: any, path: any, queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
    //const path = 'https://careerservices.careerbuddyonline.com/CareerBuddiesServices'+ this.url;

    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    console.log('\nRollbar fixes : ' + path + requestOptions);

    return this.httpnew.post(path, param, requestOptions);
  }

  public postByQueryParamWithHttpInfo(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {

    const path = this.basePath + this.url;

    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    console.log('\nRollbar fixes : ' + path + requestOptions);

    return this.httpnew.post(path, {}, requestOptions);
  }


  public putWithHttpInfo(param?: any, queryParameters?: HttpParams, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + this.url;

    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    console.log('\nRollbar fixes : ' + path + requestOptions);
    return this.httpnew.put<any>(path, param, requestOptions);
  }

  public deleteWithHttpInfo(queryParameters: HttpParams, param?: any, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + this.url;

    const body: any = {
      body: param,
      params: queryParameters
    };

    console.log('Deleting Action with new httpclient module ' + JSON.stringify(body));
    return this.httpnew.request('delete', path, body);
  }

  public deleteByQueryParamWithHttpInfo(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + this.url;

    const body: any = {
      params: queryParameters
    };

    console.log('Deleting Action with new httpclient module ' + JSON.stringify(body));
    return this.httpnew.request('delete', path, body);
  }

  public putByQueryParam(queryParameters: HttpParams, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + this.url;

    let requestOptions: any = {
      params: queryParameters
    };

    if (extraHttpRequestParams) {
      requestOptions = this.extendObj(requestOptions, extraHttpRequestParams);
    }

    console.log('\nRollbar fixes : ' + path + requestOptions);

    return this.httpnew.put<any>(path, {}, requestOptions);
  }




}
