import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-share-job',
  templateUrl: './sharejob.component.html',
})
export class ShareJob implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {}

  handleClick(){
    this.router.navigate(['/jobsearch']);
  }

}
