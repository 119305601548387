export class SkillBadgeDetails {
        Id: any;
        CandidateId: any;
        SkillBadgeId: any;
        SkillBadge: string;
        CupSkillId: any;
        Skill: string;
        SkillCategory: string;
        CreatedBy: string;
        CreatedOn: string;
        UpdatedBy: string;
        UpdatedOn: string;
        AdminId: any;
}
