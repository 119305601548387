import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Response} from '../models/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-personal-details',
  templateUrl: './personaldetailsupdate.component.html',
})
export class PersonalDetailsUpdate implements OnInit {
  userId : any;
  Id: any;
  submitted : boolean;
  userInfo : Response;
  errormessage : any;
  cities : any;
  states : any;
  mobilevalid : boolean;


  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<PersonalDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log("ngonit");
    
    this.userInfo = new Response();
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.userId = sessionStorage.getItem('userid');
    this.mobilevalid = true;
    this.getStates();
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  validateMobileNo(){
    this.mobilevalid = true;
    console.log('in validateMobileNo : ',this.data.Mobile);
    if(this.data.Mobile){
      var mno = new String(this.data.Mobile);
      console.log(mno ,' length : ', mno.length);

      if(mno.indexOf('-') != -1){
        var subs = mno.substring(mno.indexOf('-')+1);
        if(subs.length > 10){
          this.mobilevalid = false;
        }
      } else {
        if(mno.length > 10){
          this.mobilevalid = false;
        }
      }
      
    }
   return this.mobilevalid;
    
  }


getCitiesForState(ob : any){
  console.log('in getCitiesForState : ', ob.value, this.data.State);
  var state = '';
  if(ob.value){
    state = ob.value;
  } else {
    state = this.data.State;
  }
  this.jobService.getCitiesListForState(state,this.userId).subscribe(
    data => {
            if(data.success == true){
              this.cities = data.Results;
            } else {
              this.errormessage = data.error;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
  

}

getStates(){
this.jobService.getStates(this.userId).subscribe(
  data1 => {
          if(data1.success == true){
            this.states = data1.Results;

            if(this.data.State){
              this.getCitiesForState(this.data.State);
            }

          } else {
            this.errormessage = data1.error;
            console.log('Error message : ', data1.error)
          }
  },
  error => {
    this.submitted = false;
    console.log('Error occurred : ', error);
        });

}

onCancel(): void { 
  this.dialogRef.close(); 
}

update(valid : boolean){
  this.submitted = true;

  this.validateMobileNo();
  if(!this.mobilevalid){
    return false;
  }

  if(valid){
    console.log('User profile form is valid');
    this.dialogRef.close(this.data);
  } else {
    console.log('User profile detail form is not valid');
    return;
  }
}


}
