import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Header } from './header/header.component';
import { WelcomeScreen } from './welcomescreen/welcomescreen.component';
import { JobSearchPage } from './jobsearchpage/jobsearchpage.component';
import { JobserachskillsComponent } from './jobserachskills/jobserachskills.component';
import { SearchBar } from './searchbar/searchbar.component';
import { JobItem } from './jobitem/jobitem.component';
import { JobService, SessionService } from './services/index';
import { HttpClientModule ,HTTP_INTERCEPTORS} from '@angular/common/http';
import { RegisterNewUser, Registerprofession } from './registernewuser/index';
import { FormsModule } from '@angular/forms';
import { Config } from './config/config';
import { Login } from './login/login.component';
import { PageTitle } from './pagetitle/pagetitle.component';
import { ForgotPassword } from './forgotpassword/forgotpassword.component';
import { ResetPassword } from './resetpassword/resetpassword.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDialogModule} from '@angular/material/dialog';
import {MatRadioModule} from '@angular/material/radio';
import {MatSliderModule} from '@angular/material/slider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JobFilter } from './jobfilter/jobfilter.component';
import { ShareJob } from './sharejob/sharejob.component';
import { ProskiScore } from './proski/proskiscore.component';
import { JobProfile ,JobApply,JobAlert,JobOpportunities,EduQualUpdate,CurCompanyDetailsUpdate } from './jobprofile/index';
import { Dashboard } from './dashboard/dashboard.component';
import { BottomLink } from './bottomlink/bottomlink.component';
import { BottomDash } from './bottomdash/bottomdash.component';
import { BottomProski } from './bottomproski/bottomproski.component';
import { BottomMenu } from './bottommenu/bottommenu.component';
import { UserProfile , PersonalDetailsUpdate, UserProfileAcademic,
  AcademicDetailsUpdate, CompanyDetailsProfile,CompanyDetailsUpdate, 
  SkillDetails ,ProfessionalDetails,VisaDetailsUpdate,LanguageDetailsUpdate,
  ProfessionalDetailsUpdate,SkillDetailsUpdate,CertDetailsUpdate,AddSkill,CareerPaths,CareerPathSkill} from './userprofile/index';
import { OutgoingInterceptor } from './httpInterceptor/outgoingInterceptor';
import { Slider } from './slider/slider.component';
import { SearchPipe } from './searchpipe/searchpipe.component';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Alert, AlertDialog } from './alert/index';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import {TermandconditionComponent } from '../app/termandcondition/termandcondition.component'
import { LandingpageComponent } from './landingpage/landingpage.component';
import { AvailablecareerComponent } from './availablecareer/availablecareer.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';

@NgModule({
  declarations: [AppComponent, Header, WelcomeScreen, JobSearchPage, JobserachskillsComponent, SearchBar, JobItem,
    RegisterNewUser, Login, PageTitle, ForgotPassword, ResetPassword, JobFilter, ShareJob, 
    UserProfile, PersonalDetailsUpdate, UserProfileAcademic, AcademicDetailsUpdate,
    CompanyDetailsProfile, CompanyDetailsUpdate, SkillDetails, ProskiScore, JobProfile,
    JobOpportunities,JobApply, JobAlert,Dashboard,BottomLink,BottomDash,BottomProski,BottomMenu,Registerprofession,Slider,
    ProfessionalDetails,VisaDetailsUpdate,LanguageDetailsUpdate,CertDetailsUpdate,
    ProfessionalDetailsUpdate, SkillDetailsUpdate,AddSkill,CareerPaths,CareerPathSkill,
    SearchPipe,Alert, AlertDialog, EduQualUpdate,CurCompanyDetailsUpdate, TermandconditionComponent,LandingpageComponent, AvailablecareerComponent],
  entryComponents: [TermandconditionComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule, 
    MatFormFieldModule, MatInputModule, BrowserAnimationsModule, MatSelectModule, MatDatepickerModule,
    MatNativeDateModule, MatDialogModule, MatRadioModule,MatSliderModule,ReactiveFormsModule,
    MatAutocompleteModule,MatCheckboxModule],
  providers: [
    StatusBar,
    SplashScreen,
    JobService,
    SessionService,
    SocialSharing,
    Config,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: OutgoingInterceptor, multi: true },
    SmsRetriever
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
