import { Component, OnInit, Input } from '@angular/core';
import {JobService} from '../services/index';
import {Job, JobFilter, JobViewDetail} from '../models/index';
import {Config} from '../config/index';
import { SearchPipe } from '../searchpipe/searchpipe.component';

@Component({
  selector: 'app-search-page',
  templateUrl: './jobsearchpage.component.html',
})
export class JobSearchPage implements OnInit {

  constructor(private jobService: JobService) { }

  jobs : Job[];
  jobFilter : JobFilter;
  industries : any;
  domains : any;
  joblevels : any;
  cities : any;
  showjobs : boolean;
  Industry : any;
  JobLevel : any;
  JobCity : any;
  Domain : any;
  config : Config;
  jobViewDetail : JobViewDetail[];
  jobViewData : Map<number, any>;

  ngOnInit() {
    console.log('In ngoninit of job search page');
    this.jobFilter = new JobFilter();
    //this.jobRestrictionObj = {};
    this.config = new Config();
    this.showjobs = false;
    this.Industry = '';
    this.JobLevel = '';
    this.JobCity = '';
    this.Domain = '';
    this.jobViewData = new Map<number, any>();
    this.cities = this.config.getCitiesForJobPosts();
    this.getJobPostsForLandingPage();
    
    this.getDomains();
    this.getJobLevels();
    

  }

  getJobPostsForLandingPage(){
    console.log('In getJobPostsForLandingPage');
    this.jobService.getJobpostsForLandingPage().subscribe(
      data => {
              this.jobs = data.Results;
              this.getIndustries();
              this.processJobs();
      },
      error => {
        console.log('Error occurred : ', error);
            });
  }

  processJobs(){
    if(this.jobs && this.jobs.length > 0){
      for(var job of this.jobs){
        this.getJobPostingViewDetailByShortUrlId(job.Id);
      }
    }
  }

  getJobPostingViewDetailByShortUrlId(jobId: any){
    var jobRestrictionObj = new Object();
    this.jobService.getJobPostingViewDetailsById(jobId).subscribe(
      data => {
        if(data.success == true){
          this.jobViewDetail = data.Results;

          if(this.jobViewDetail && this.jobViewDetail.length > 0){
            for(let jobdata of this.jobViewDetail){
              if(jobdata.Role === 'Candidate'){
                jobRestrictionObj['showjobpostedfor'] = jobdata.showJobPostFor;
                jobRestrictionObj['showctc'] = jobdata.showEligibleCurrentCTC;
                console.log('this.jobRestrictionObj : ', jobRestrictionObj);
                this.jobViewData.set(jobId, jobRestrictionObj);
                
                console.log('Job view data : ',this.jobViewData.size, this.jobViewData);

                if(this.jobViewData.size == this.jobs.length){
                  this.showjobs = true;
                }
                break;
              }
            }
          }
        }
      },
      error => {
        console.log('Error occurred : ', error);
            });
  }


  getIndustries(){
    console.log('in getIndustries : ');
    
    this.jobService.getIndustries().subscribe(
      data => {
              if(data.success == true){
                //this.industries = data.Results;
                //to display industries dropdown in alphabetical order
                this.industries = data.Results.sort((a,b) => {
                  if(a.IndustryName < b.IndustryName) { return -1; }
                  if(a.IndustryName > b.IndustryName) { return 1; }
              })
              console.log('sorted industries : ', this.industries)

                
              } else {
                console.log('Error message : ', data.error)
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
  }
  
  getDomains(){
    console.log('in getDomains : ');
    
    this.jobService.getDomains().subscribe(
      data => {
              if(data.success == true){
        
                this.domains  = data.Results.sort((a,b) => {
                  if(a.DomainName < b.DomainName) { return -1; }
                  if(a.DomainName > b.DomainName) { return 1; }
              })
              console.log('sorted domains : ', this.domains)
                
              } else {
                console.log('Error message : ', data.error)
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
    
  
  }

  getJobLevels(){
    this.jobService.getJobLevels().subscribe(
      data => {
              if(data.success == true){
                this.joblevels = data.Results;
                
             /*    this.joblevels  = data.Results.sort((a,b) => {
                  if(a.JobLevel < b.JobLevel) { return -1; }
                  if(a.JobLevel > b.JobLevel) { return 1; }
              }) */
              } else {
                console.log('Error message : ', data.error)
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
    
    }

    

      filterJob(){
        if(this.jobFilter.Industry){
          this.Industry = this.jobFilter.Industry;
        }
        if(this.jobFilter.JobCity){
          this.JobCity = this.jobFilter.JobCity;
        }
        if(this.jobFilter.JobLevel){
          this.JobLevel = this.jobFilter.JobLevel;
        }
        if(this.jobFilter.Domain){
          this.Domain = this.jobFilter.Domain;
        }
      }

}
