import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Response} from '../models/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Config} from '../config/index';


@Component({
  selector: 'app-academic-details-update',
  templateUrl: './academicdetailsupdate.component.html',
})
export class AcademicDetailsUpdate implements OnInit {
  userId : any;
  Id: any;
  submitted : boolean;
  userInfo : Response;
  errormessage : any;
  educationLevels : any;
  qualifications : any;
  specializations : any;
  years: any;
  months : any;
  marktypes : any;


  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<AcademicDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config) { }

  ngOnInit() {
    this.userInfo = new Response();
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.userId = sessionStorage.getItem('userid');
    this.years = this.config.getYearsList();
    this.months = this.config.getMonthsList();
    this.marktypes = ['Percentage' , 'GPA'];
    this.getEducationLevels();
    this.specializations = [];
  }

  gotoPage(route){
    this.router.navigate([route]);
  }


getQualifications(ob : any, update : boolean){
  console.log('in getQualifications : ', ob.value, ob);
  var edulevel = '';
  if(update){
    edulevel = ob;
  } else {
    edulevel = ob.value;
  }
  this.jobService.getQualifications(edulevel).subscribe(
    data => {
            if(data.success == true){
              this.qualifications = data.Results;
              if(this.data.save == 'update'){
                this.getSpecializations(this.data.academicdetail.Degree, true);
              }
            } else {
              this.errormessage = data.error;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
}

getSpecializations(ob : any, update : boolean){
  var qualification = '';
  if(update){
    qualification = ob;
  } else {
    qualification = ob.value;
  }
  this.jobService.getSpecializations(this.data.academicdetail.EducationCategory, qualification).subscribe(
    data => {
            if(data.success == true){
              this.specializations = data.Results;
            } else {
              this.errormessage = data.error;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
  

}

getEducationLevels(){
this.jobService.getEducationLevels().subscribe(
  data => {
          if(data.success == true){
            this.educationLevels = data.Results;
            if(this.data.save == 'update'){
                this.getQualifications(this.data.academicdetail.EducationCategory, true);
            }
          } else {
            this.errormessage = data.error;
            console.log('Error message : ', data.error)
          }
  },
  error => {
    this.submitted = false;
    console.log('Error occurred : ', error);
        });

}

onCancel(): void { 
  this.dialogRef.close(); 
}

saveDetails(valid : boolean){
  this.submitted = true;

  if(valid){
    console.log('Academic detail form is valid');
    this.dialogRef.close(this.data.academicdetail);
  } else {
    console.log('Academic detail form is not valid');
    return;
  }

}


}
