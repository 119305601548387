
export class RelevantTemplateResponse {
    AdminId: any;
CareerPathCertifications: any;
CareerPathDomain: any;
CareerPathFunction: any;
CareerPathIndustries: any;
CareerPathJobCategories: any;
CareerPathJobLevelDetails: any;
CareerPathQualifications: any;
CareerPathSkills: any;
CreatedBy: any;
CreatedOn: any;
Id: any;
JobCategory: any;
JobLevel: any;
MaxExpInYears: any;
MinExpInYears: any;
TemplateName: string;
UpdatedBy: any;
UpdatedOn: any;
}
