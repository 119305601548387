import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import {JobService} from '../services/index';
import {UserInfo} from '../models/index';
import {Router} from '@angular/router';
import { SmsRetriever } from '@ionic-native/sms-retriever/ngx';
import {Alert} from '../alert/alert.component';
//declare var SMSReceive: any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgotpassword.component.html',
})

export class ForgotPassword implements OnInit {
  @Input() hideNav:boolean;
  @Output() signInForm:EventEmitter<any> = new EventEmitter();
  initialsection : boolean;
  nextsection : boolean;
  Otp: any;
  otpGenerated : any;
  emailid : any;
  userInfo : UserInfo;
  submitted : boolean;
  resendOtpMessage: any;
  errormessage : any;
  successmessage : any;
  otpsubmitted : boolean;
  showResetPwd : boolean;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;

  constructor(private router: Router, private jobService: JobService, private smsRetriever: SmsRetriever) { 
    
  }

  ngOnInit() {
    console.log('In ngoninit of forgotpassword component');
    this.errormessage = '';
    this.successmessage = '';
    this.otpGenerated = '';
    this.initialsection = true;
    this.nextsection = false;
    this.userInfo = new UserInfo();
    this.showResetPwd = false;
    this.showalert = false;
  }

  gotoPage(route){
    this.router.navigate([route]);
  }
  
  generateotp(valid : boolean, resend : boolean){
    this.start();
    
    this.userInfo.Role = 'candidateUser';
    this.errormessage = '';
    this.submitted = true;
    this.showalert = false;
    if(valid == true){
      this.userInfo.EmailId = this.emailid;
      this.jobService.generateOtp(this.userInfo).subscribe(
        data => {
          this.submitted = false;
          console.log('Generate otp data received :', data);
                if(data.success == true){
                  this.nextsection = true;
                  this.initialsection = false;
                  this.otpGenerated = data.Result.Otp;
                  console.log('otp generated successfully');
                  if(resend){
                    this.resendOtpMessage = 'Check your mobile for OTP or e-mail';
                    this.responseMessage = 'Check your mobile for OTP or e-mail';
                    this.typeOfResponseMessage = 'success';
                    this.showalert = true;
                  } else {
                    this.emailid = '';

                  }
                } else {
                  this.errormessage = data.error;
                  this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  console.log('otp not generated successfully');
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          this.responseMessage = 'Error occurred while generate otp';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          console.log('Error occurred : ', error);
              });
    } else {
      console.log('Form is not valid');
    }
 
  }

  verifyotp(valid: boolean){
    console.log('In verify otp');
    this.otpsubmitted = true;
    this.userInfo.Otp = this.Otp;
    this.errormessage = '';
    this.showalert = false;
    this.responseMessage ='';
    this.typeOfResponseMessage = '';

    if(valid == true){
      
      if(this.otpGenerated && this.Otp && (this.otpGenerated == this.Otp)){
        console.log('otp is correct');
        document.getElementById('forgotpassword').style.display = 'none';
        this.showResetPwd = true;
        this.emailid = this.userInfo.EmailId;
      } else {
        console.log('Otp is incorrect');
        this.errormessage = 'Entered Otp is invalid...Please enter valid Otp!';
        this.responseMessage = 'Entered Otp is invalid...Please enter valid Otp!';
        this.typeOfResponseMessage = 'error';
        this.showalert = true;
      }
      
      //this.router.navigate(['/resetpassword']);
                  

    } else {
      console.log('Form not valid');
    }
  }

  closeAlert(event : any){
    if(event){
      this.showalert = false;
    }
  }

  start() {
    this.smsRetriever.startWatching()
      .then((res: any) => {
        console.log(res);
        this.processSMS(res);
      })
      .catch((error: any) => console.error(error));
  }

  /*stop() {
    console.log('In stop watch');
    SMSReceive.stopWatch(
      () => { console.log('watch stopped') },
      () => { console.log('watch stop failed') }
    );
  }*/

  processSMS(data) {
    // Check SMS for a specific string sequence to identify it is you SMS
    // Design your SMS in a way so you can identify the OTP quickly i.e. first 6 letters
    // In this case, I am keeping the first 6 letters as OTP
    const message = data.Message;
    if (message && message.indexOf('careerbuddyonline.com') !== -1) {
      console.log('Message received : ', message);

      var msgArr = message.split(' ');
      this.Otp = msgArr[7];
      //this.OTP = data.body.slice(0, 6);
      //console.log(this.OTP);
      //this.OTPmessage = 'OTP received. Proceed to register'
      //this.stop();
    }
  }
  signIn(){
    this.signInForm.emit('signinForm');
  }


}
