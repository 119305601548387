import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { JobService } from '../services/index';
import { Response, CompanyDetails, Skill, SkillBadgeDetails } from '../models/index';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CompanyDetailsUpdate } from './companydetailsupdate.component';

@Component({
  selector: 'app-user-company-details',
  templateUrl: './companydetails.component.html',
})
export class CompanyDetailsProfile implements OnInit, OnDestroy {
  userId: any;
  Id: any;
  submitted: boolean;
  userInfo: Response;
  errormessage: any;
  successmessage: any;
  cities: any;
  states: any;
  dialogRef: any;
  companyDetails: any;
  updateComDetail: any;
  companyDetail: CompanyDetails;
  companyDetailsReq: CompanyDetails[];
  companycompletionscore: any;
  profilecompletionscore: any;
  responseMessage: any;
  typeOfResponseMessage: any;
  showalert: boolean;


  constructor(private jobService: JobService, private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.companyDetailsReq = [];
    this.userInfo = new Response();
    this.companyDetail = new CompanyDetails();
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.successmessage = '';
    this.showalert = false;
    this.userId = sessionStorage.getItem('userid');
    var compScore = sessionStorage.getItem('CompanyProfileScore');

    if (!(compScore) || (compScore == 'null')) {
      this.companycompletionscore = '0';
    } else {
      this.companycompletionscore = compScore;
    }
    this.profilecompletionscore = sessionStorage.getItem('ProfileCompletionScore');
    this.getUserprofileCompanyData();
    console.log("1",this.companyDetails);
    this.companyDetail.UserId = this.userId;
  }

  ngOnDestroy() {
    console.log('In ngdestroy of user profile academic');
    if (this.dialogRef) {
      console.log('Closing dialog');
      this.dialogRef.close();
    }
  }

  gotoPage(route) {
    this.router.navigate([route]);
  }

  getUserprofileCompanyData() {
    this.companyDetails = [];
    console.log('userid from session : ', this.userId);
    this.jobService.getCompanyDetails(this.userId).subscribe(
      data => {
        if (data.success == true) {
          this.companyDetails = data.Results;
          console.log('Company details obtained : ', this.companyDetails);
         } else {
        //   this.errormessage = data.error1;
        //   this.responseMessage = data.error1;
        //   this.typeOfResponseMessage = 'error';
         //  this.showalert = true;
           console.log('Error message : ', data.error1)
         }
      },
      error => {
        this.submitted = false;
        this.responseMessage = 'Error occurred while getting user data';
        this.typeOfResponseMessage = 'error';
        this.showalert = true;
        console.log('Error occurred : ', error);
      });

  }

  openDialog(save: boolean, ob: any) {
    this.successmessage = '';
    this.errormessage = '';
    let saveorupdate = '';
    if (save) {
      this.companyDetail = new CompanyDetails();
      saveorupdate = 'save';
    } else {
      this.companyDetail = ob;
      saveorupdate = 'update';
    }

    this.dialogRef = this.dialog.open(CompanyDetailsUpdate, {
      width: '100vw',
      height: '100%',
      closeOnNavigation: true,
      data: { companydetail: this.companyDetail, save: saveorupdate }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('in dialog close test');
      if (result) {
        this.companyDetail = result;
        console.log('Company detail to be saved or updated : ', this.companyDetail);
        if (save) {
          this.createCompanyDetails();
        } else {
          //this.getUserprofileCompanyData();  
          this.updateCompanyDetails();
        }

      }
      else {
        this.getUserprofileCompanyData();
      }
    });
  }

  updateCompanyDetails() {
    this.errormessage = '';
    this.successmessage = '';
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;
    console.log('In updateCompanyDetails');
    this.companyDetail.AdminId = this.userId;
    this.companyDetail.UserId = this.userId;
    //code to check unique or not
    const index = this.companyDetails.findIndex(details => details.Id === this.companyDetail.Id);
    let updatedRecord = this.companyDetails[index];
    let tempArray = this.companyDetails.slice();
    tempArray.splice(index, 1);
    const exists = tempArray.some(details =>
      details.CompanyName.toLowerCase() === updatedRecord.CompanyName.toLowerCase() &&
      details.FromDate === updatedRecord.FromDate &&
      details.JobLevel === updatedRecord.JobLevel
    );
    if (!exists) {
      this.companyDetails[index] = updatedRecord;
      this.updateComDetail=updatedRecord;
    }
    else {
      this.getUserprofileCompanyData();
      console.log("2",this.companyDetails);
      this.responseMessage = 'Company with the same details already exists';
      this.typeOfResponseMessage = 'error';
      this.showalert = true;
    }

    this.jobService.updateCompanyDetails(this.updateComDetail).subscribe(
      data => {
        if (data.success == true) {
          console.log('update', updatedRecord);
          console.log(this.companyDetails);
          this.userInfo = data.Result;
          this.companycompletionscore = this.userInfo.CompanyProfileCompletionScore;
          this.profilecompletionscore = this.userInfo.TotalProfileCompletionScore;
          sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
          this.responseMessage = 'Company details updated successfully';
          this.typeOfResponseMessage = 'success';
          this.showalert = true;
          this.getUserprofileCompanyData();
          console.log("3",this.companyDetails);
        } else {
          this.errormessage = data.error;
          this.responseMessage = data.error;
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          this.getUserprofileCompanyData();
          console.log("4",this.companyDetails);
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });

  }

  createCompanyDetails() {
    this.companyDetailsReq = [];
    this.errormessage = '';
    this.successmessage = '';
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;
    console.log('In createCompanyDetails');
    this.companyDetail.AdminId = this.userId;
    this.companyDetail.UserId = this.userId;
    //this.companyDetailsReq.push(this.companyDetail);

    const exists = this.companyDetails.some(details =>
      details.CompanyName.toLowerCase() === this.companyDetail.CompanyName.toLowerCase() &&
      details.FromDate === this.companyDetail.FromDate &&
      details.JobLevel === this.companyDetail.JobLevel)
    if (!exists) {
      this.companyDetailsReq.push(this.companyDetail);
      console.log(this.companyDetailsReq);
    }
    else {
      this.responseMessage = 'Company with the same details already exists';
      this.typeOfResponseMessage = 'error';
      this.showalert = true;
    }

    this.jobService.createCompanyDetails(this.companyDetailsReq).subscribe(
      data => {
        if (data.success == true) {
          this.userInfo = data.Result;
          this.companycompletionscore = this.userInfo.CompanyProfileCompletionScore;
          this.profilecompletionscore = this.userInfo.TotalProfileCompletionScore;
          sessionStorage.setItem('ProfileCompletionScore', this.profilecompletionscore);
          this.companyDetails = data.Result.CUPCompanyDetails;
         //this.companyDetails.push(this.companyDetailsReq);         
          console.log('Company details received : ', this.companyDetails);
         // this.getUserprofileCompanyData();
          this.responseMessage = 'Company details created successfully';
          this.typeOfResponseMessage = 'success';
          this.showalert = true;
        } else {
          this.responseMessage = data.error;
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });
  }

  deleteCompanyDetails(ob: any) {
    this.errormessage = '';
    this.successmessage = '';
    console.log('In deleteCompanyDetails');
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;
    this.companyDetail = ob;
    this.companyDetail.AdminId = this.userId;
    this.jobService.deleteCompanyDetails(this.companyDetail).subscribe(
      data => {
        if (data.success == true) {
          this.getUserprofileCompanyData();
          console.log("5",this.companyDetails);
          this.responseMessage = 'Company details deleted successfully';
          this.typeOfResponseMessage = 'success';
          this.showalert = true;
        } else {
          this.getUserprofileCompanyData();
          console.log("6",this.companyDetails);
          this.errormessage = data.error;
          this.responseMessage = data.error;
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
      });

  }

  closeAlert(event: any) {
    if (event) {
      this.showalert = false;
    }
  }



}




