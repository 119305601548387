
export class CupSkillCertification {
    CertificationName: any;
    CertificationNameother: any;
    IssuingAuthority: any;
    Remark: any;
    ResultUrl: any;
    SkillName: any;
    UserId: any;
    Year: any;
    AdminId: any;
}
