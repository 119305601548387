import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './searchbar.component.html',
})
export class SearchBar implements OnInit {

  constructor() { }

  ngOnInit() {}

}
