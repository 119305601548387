import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {WelcomeScreen } from './welcomescreen';
import { JobSearchPage } from './jobsearchpage/jobsearchpage.component';
import { RegisterNewUser,Registerprofession } from './registernewuser/index';
import { Login } from './login/login.component';
import { PageTitle } from './pagetitle/pagetitle.component';
import { ForgotPassword } from './forgotpassword/forgotpassword.component';
import { ResetPassword } from './resetpassword/resetpassword.component';
import { JobFilter } from './jobfilter/jobfilter.component';
import { ShareJob } from './sharejob/sharejob.component';
import { ProskiScore } from './proski/proskiscore.component';
import { JobProfile } from './jobprofile/jobprofile.component';
import { JobOpportunities } from './jobprofile/jobopportunities.component';
import { JobApply } from './jobprofile/jobapply.component';
import { Dashboard } from './dashboard/dashboard.component';
import { Slider } from './slider/slider.component';
import { JobserachskillsComponent } from './jobserachskills/jobserachskills.component';
import { UserProfile, UserProfileAcademic, CompanyDetailsProfile, SkillDetails, ProfessionalDetails } from './userprofile/index';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { PersonalDetailsUpdate } from './userprofile/personaldetailsupdate.component';
import { AvailablecareerComponent } from './availablecareer/availablecareer.component';
const routes: Routes = [
 // { path: '', component: WelcomeScreen },
  { path: '', component: LandingpageComponent },
  { path: 'homepage', component: Slider },
  { path: 'welcome', component: LandingpageComponent },
  { path: 'jobsearch', component: JobserachskillsComponent },
  // { path: 'jobsearch', component: JobSearchPage },
  { path: 'registernewuser', component: RegisterNewUser },
  { path: 'login', component: Login },
  { path: 'alertlogin', component: PageTitle },
  { path: 'forgotpassword', component: ForgotPassword },
  { path: 'resetpassword', component: ResetPassword },
  { path: 'filterjob', component: JobFilter },
  { path: 'sharejob', component: ShareJob },
  { path: 'userprofile', component: UserProfile },
  { path: 'userprofileacademic', component: UserProfileAcademic },
  { path: 'companydetails', component: CompanyDetailsProfile },
  { path: 'skilldetails', component: SkillDetails },
  { path: 'proskiscore', component: ProskiScore },
  { path: 'jobprofile/:candidateId/:adminId/:id', component: JobProfile },
  { path: 'jobprofile/:id', component: JobProfile },
  { path: 'jobopportunities', component: JobOpportunities },
  { path: 'jobapply', component: JobApply },
  { path: 'dashboard', component: Dashboard,  runGuardsAndResolvers: 'always'},
  { path: 'regprof', component: Registerprofession },
  { path: 'slider', component: Slider },
  { path: 'professionaldetails', component: ProfessionalDetails },
  { path: 'personalDetailsUpdate', component:PersonalDetailsUpdate},
  { path: 'availablecareerComponent', component:AvailablecareerComponent},
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
