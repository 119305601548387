import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

export class CountryList {
    country: string;
    code: number;
}

@Injectable()
export class Config {

    public countryList: CountryList[] = [{country : 'India', code : 91}, {country : 'France', code : 33},{country : 'Germany', code : 49} ,
    {country : 'Greece', code : 30},{country : 'Indonesia', code : 62},{country : 'Japan', code : 81}];

    getCountryList(): CountryList[]  {
        return this.countryList;
    }

    public years: any = ['1950','1951','1952','1953','1954','1955','1956','1957','1958','1959','1960','1961','1962',
    '1963','1964','1965','1966','1967','1968','1969','1970','1971','1972','1973','1974','1975','1976','1977','1978','1979','1980',
    '1981','1982','1983','1984','1985','1986','1987','1988','1989','1990','1991','1992','1993','1994','1995','1996','1997','1998',
    '1999','2000','2001','2002','2003','2004','2005','2006','2007','2008','2009','2010','2011','2012','2013','2014','2015','2016',
    '2017','2018','2019','2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027'];

    getYearsList(): any  {
        return this.years;
    }

    public months: any = ['January','February','March','April','May','June','July',
    'August','September','October','November','December'];

    getMonthsList(): any{
        return this.months;
    }

    getSkillRatingDescription(value) : any {
        if(value){
            if(value >= 0 && value <=3){
                return 'Awareness';
            }
            if(value >= 3.1 && value <=4){
                return 'Create hands-on';
            }
            if(value >= 4.1 && value <=5){
                return 'Need support for Applying';
            }
            if(value >= 5.1 && value <=8){
                return 'Apply Independently';
            }
            if(value >= 8.1 && value <=10){
                return 'Deliver value/Coach others';
            }
        }
    }

    getSkillRatingDescriptionAlongWithValue(value) : any {
        if(value){
            if(value >= 0 && value <=3){
                return '0-3 :- Awareness';
            }
            if(value >= 3.1 && value <=4){
                return '3.1-4 :- Create hands-on';
            }
            if(value >= 4.1 && value <=5){
                return '4.1-5 :- Need support for Applying';
            }
            if(value >= 5.1 && value <=8){
                return '5.1-8 :- Apply Independently';
            }
            if(value >= 8.1 && value <=10){
                return '8.1-10 :- Deliver value/Coach others';
            }
        }
    }

    public numbers: any = ['0','1','2','3','4','5','6','7','8','9','10','11','12',
    '13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30',
    '31','32','33','34','35','36','37','38','39','40'];


    getNumbersList(): any  {
        return this.numbers;
    }

    public languagearray: any = [{ val: "Mandarin Chinese" }, { val: "Spanish" }, { val: "English" }, { val: "English -US" }, { val: "English -BR" }, { val: "Hindi" }, { val: "Bengali" }, { val: "Portuguese" }, { val: "Russian" }, { val: "Japanese" }, { val: "Western Punjabi" }, { val: "Marathi" }, { val: "Telugu" }, { val: "Wu Chinese" }, { val: "Turkish" }, { val: "Korean" }, { val: "French" }, { val: "German" }, { val: "Vietnamese" }, { val: "Tamil" }, { val: "Yue Chinese" }, 
    { val: "Urdu (Persianised Hindustani)" }, { val: "Javanese" }, { val: "Italian" }, { val: "Egyptian Arabic" }, { val: "Gujarati" }, { val: "Iranian Persian" }, { val: "Bhojpuri" }, { val: "Min Nan Chinese" }, { val: "Hakka Chinese" }, { val: "Jin Chinese" }, { val: "Hausa" }, { val: "Kannada" }, { val: "Indonesian (Indonesian Malay)" }, { val: "Polish" }, { val: "Yoruba" }, { val: "Xiang Chinese" }, { val: "Malayalam" }, { val: "Odia" }, { val: "Maithili" }, { val: "Burmese" }, 
    { val: "Eastern Punjabi" }, { val: "Sunda" }, { val: "Sudanese Arabic" }, { val: "Algerian Arabic" }, { val: "Moroccan Arabic" }, { val: "Ukrainian" }, { val: "Igbo" }, { val: "Northern Uzbek" }, { val: "Sindhi" }, { val: "North Levantine Arabic" }, { val: "Romanian" }, { val: "Tagalog" }, { val: "Dutch" }, { val: "Saʽidi Arabic" }, { val: "Gan Chinese" }, { val: "Amharic" }, { val: "Northern Pashto" }, { val: "Magahi" }, { val: "Thai" }, { val: "Saraiki" }, { val: "Khmer" }, { val: "Chhattisgarhi" }, { val: "Somali" }, { val: "Malay (Malaysian Malay)" }, { val: "Cebuano" }, { val: "Nepali" }, { val: "Mesopotamian Arabic" }, { val: "Assamese" }, { val: "Sinhalese" }, { val: "Northern Kurdish" }, 
    { val: "Hejazi Arabic" }, { val: "Nigerian Fulfulde" }, { val: "Bavarian" }, { val: "South Azerbaijani" }, { val: "Greek" }, { val: "Chittagonian" }, { val: "Kazakh" }, { val: "Deccan" }, { val: "Hungarian" }, { val: "Kinyarwanda" }, { val: "Zulu" }, { val: "South Levantine Arabic" }, { val: "Tunisian Arabic" }, { val: "Sanaani Spoken Arabic" }, { val: "Min Bei Chinese" }, { val: "Southern Pashto" }, { val: "Rundi" }, { val: "Czech" }, { val: "Taʽizzi-Adeni Arabic" }, { val: "Uyghur" }, { val: "Min Dong Chinese" }, { val: "Sylheti" }];

    getLanguagesArray() : any {
        return this.languagearray;
    }

    public skillbadges: any = ['Have a skill','Planning to have','Trainer','Author','Core skill','Certified','Mentor',
    'Coach','Speaker','Keynote speaker','None'];

    getSkillBadges(): any{
        return this.skillbadges;
    }

    public cities: any = ['Bengaluru','Hyderabad','Mumbai','Nashik','Pune'];

    getCitiesForJobPosts(): any{
        return this.cities;
    }

    public fileformats: any = ['rtf','doc','docx'];

    getFileFormats(): any{
        return this.fileformats;
    }

    public imageformats: any = ['png', 'jpg', 'jpeg'];

    getImageFormats(): any{
        return this.imageformats;
    }

    encryptData(data : any){
        var key = CryptoJS.enc.Utf8.parse('igmite~0');
        var iv = CryptoJS.enc.Utf8.parse('propmo~0');
        
        var encrypted = CryptoJS.DES.encrypt(
          data,
          key,
          {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
          }
        );
        console.log('encrypted: ' + encrypted.toString());
        return encrypted.toString();
       }

       makeid(length : any) {
        var result           = '';
        var characters       = '1234567890';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        console.log('Generated random : ', result);
        return result;
     }

}
