import { LanguageDetails } from './index';
export class ProfessionDetails {
    AdminId : any;
    AvailableFrom: any;
ConflictOfInterestSpecs: any;
CreatedBy: any;
CreatedOn: any;
CurrentBenefits: any;
CurrentCTC: any;
CurrentCity: any;
CurrentJobLevel: any;
ECTC: any;
EmploymentType: any;
EngagementType: any;
ExcludeCompanyList: any;
ExpectedJobType: any;
ExpectedProfile: any;
Id: 110;
LanguageProficiency: any;
Nationality: any;
NoticePeriod: any;
OtherInputs: any;
PassPortExpDate: any;
PassPortIssueDate: any;
PassportNo: any;
ProfileCompletionScore: any;
PropmoResumeLink: any;
ReadinessForOverSeas: any;
ReadyToWorkInShifts: any;
ResumeLink: any;
SkypeIMID: any;
TotalExp: any;
UpdatedBy: any;
UpdatedOn: any;
UserId: any;
VisaDetails: any;
canJoinImmediately: any;
canRelocate: any;
isActivelyLookingForJob: any;
isConfictOfInterest: any;
isCvUploaded: any;
isDifferentlyAbled: any;
isECTCNegotiable: any;
isJoiningOnDate: any;
isNPAfterOffer: any;
isNPNegotiable: any;
}
