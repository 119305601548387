import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Config} from '../config/index';


@Component({
  selector: 'app-lang-details-update',
  templateUrl: './langdetailsupdate.component.html',
})
export class LanguageDetailsUpdate implements OnInit {
  reading: boolean;
  writing : boolean;
  speaking : boolean;
  languages : any;
  langvalid : boolean;
  submitted : boolean;
  

  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<LanguageDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config) { }

  ngOnInit() {
    this.reading = false;
    this.writing = false;
    this.speaking = false;
    this.submitted = false;
    this.langvalid = true;
    this.languages = [];
    this.config = new Config();
    this.languages = this.config.getLanguagesArray();
  }

  gotoPage(route){
    this.router.navigate([route]);
  }


  onCancel(): void { 
    this.dialogRef.close(); 
  }

  saveDetails(valid : boolean){

    if(valid){
      var rateLangs = '';
      this.submitted = true;
      if(this.reading && this.writing && this.speaking){
        rateLangs = 'Reading,Writing,Speaking';
      } else if(this.reading && !this.writing && !this.speaking){
        rateLangs = 'Reading,,';
      } else if(!this.reading && this.writing && !this.speaking){
        rateLangs = ',Writing,';
      } else if(!this.reading && !this.writing && this.speaking){
        rateLangs = ',,Speaking';
      } else if(this.reading && this.writing && !this.speaking){
        rateLangs = 'Reading,Writing,';
      } else if(!this.reading && this.writing && this.speaking){
        rateLangs = ',Writing,Speaking';
      }else if(this.reading && !this.writing && this.speaking){
        rateLangs = 'Reading,,Speaking';
      } else if(!this.reading && !this.writing && !this.speaking){
        this.langvalid = false;
        return;
      }
  
      this.data.langdetail.RateLanguage = rateLangs;
      this.dialogRef.close(this.data.langdetail);
    }
    
}


}
