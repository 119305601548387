import { Component, OnInit, Input,OnDestroy } from '@angular/core';
import {JobService} from '../services/index';
import {Response} from '../models/index';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { PersonalDetailsUpdate } from './personaldetailsupdate.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './userprofile.component.html',
})
export class UserProfile implements OnInit,OnDestroy  {
  @Input() hideNav: boolean;
  userId : any;
  Id: any;
  submitted : boolean;
  userInfo : Response;
  errormessage : any;
  successmessage : any;
  cities : any;
  states : any;
  dialogRef : any;
  profcompletionscore : any;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;
  isHideScroll: boolean=false;

  constructor(private jobService: JobService, private router: Router,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.userInfo = new Response();
    this.Id = '';
    this.submitted = false;
    this.errormessage = '';
    this.successmessage = '';
    this.userId = sessionStorage.getItem('userid');
    this.getStates();
    this.getUserprofileData();
    this.showalert  = false;
  }

  ngOnDestroy(){
    console.log('In ngdestroy of user profile');
    if(this.dialogRef){
      console.log('Closing dialog');
      this.dialogRef.close();
    }
  }

  gotoPage(route){
    this.router.navigate([route]);
  }

  getUserprofileData(){
      console.log('userid and id from session : ', this.userId, this.Id);
      this.jobService.getCandidateDetails(this.userId, this.Id).subscribe(
        data => {
                if(data.success == true){
                  this.userInfo = data.Result;
                  this.userInfo.FirstName = sessionStorage.getItem('fname');
                  this.userInfo.LastName = sessionStorage.getItem('lname');
                  this.userInfo.EmailId = sessionStorage.getItem('email');
                  this.userInfo.AdminId = this.userId;
                  this.userInfo.ProfileSubmittedBy = 'self';
                  sessionStorage.setItem('AcademicProfileScore', this.userInfo.AcadCompletionScore);
                  sessionStorage.setItem('CompanyProfileScore', this.userInfo.CompanyCompletionScore);
                  this.profcompletionscore =  this.userInfo.ProfileCompletionScore;
                  sessionStorage.setItem('ProfileCompletionScore', this.profcompletionscore);
                  this.getCitiesForState();
                  console.log('City : ',this.userInfo.City );
                  console.log('State : ',this.userInfo.State );
                } else {
                  this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  this.errormessage = data.error;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.profcompletionscore = 0;
          this.responseMessage = 'Error occurred while getting user data';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          sessionStorage.setItem('ProfileCompletionScore', this.profcompletionscore);

          this.submitted = false;
          console.log('Error occurred : ', error);
              });

  }
gotoProfile(){
  console.log("gotoprofile");
  
  this.router.navigate(['/personalDetailsUpdate']);
}
openDialog(){
  this.successmessage = '';
  this.errormessage = '';
  this.dialogRef = this.dialog.open(PersonalDetailsUpdate, {
    width:'100vw',
    height:'100%',
    closeOnNavigation : true,
    data: this.userInfo
  });

  this.dialogRef.afterClosed().subscribe(result => {
    console.log('in dialog close test');
    if(result){
      this.userInfo = result;

      console.log('User info to be updated : ', this.userInfo);

      if(this.userInfo.Id){
        this.updateCandidateDetails();
      } else {
        this.createCandidateDetails();
      }
    }
    else{
      this.getStates();
      this.getUserprofileData();
    }
    
  });
}

updateCandidateDetails(){
  this.errormessage = '';
  this.successmessage = '';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert = false;
  console.log('In updateCandidateDetails');
  if(this.userInfo.Dob){
    this.userInfo.Dob = this.convertDate(this.userInfo.Dob);
  }
  this.userInfo.AdminId = this.userId;
  
this.jobService.updateCandidateDetails(this.userInfo).subscribe(
      data => {
              if(data.success == true){
                  this.userInfo = data.Result.CandidateDetail;

                  sessionStorage.setItem('fname', this.userInfo.FirstName);
                  sessionStorage.setItem('lname', this.userInfo.LastName);
                  //this.userInfo.FirstName = sessionStorage.getItem('fname');
                  //this.userInfo.LastName = sessionStorage.getItem('lname');
                  this.userInfo.EmailId = sessionStorage.getItem('email');
                  this.userInfo.ProfileSubmittedBy = 'self';
                  this.getCitiesForState();
                  this.profcompletionscore = data.Result.TotalProfileCompletionScore;
                  sessionStorage.setItem('ProfileCompletionScore', this.profcompletionscore);
                  this.successmessage = 'Personal details updated successfully';
                  this.responseMessage = 'Personal details updated successfully';
                  this.typeOfResponseMessage = 'success';
                  this.showalert = true;
                  
              } else {
                  this.errormessage = data.error;
                  this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                
                console.log('Error message : ', data.error)
              }
      },
      error => {
        this.submitted = false;
        console.log('Error occurred : ', error);
            });
    
}

createCandidateDetails() {
  this.errormessage = '';
  this.successmessage = '';
  this.typeOfResponseMessage = '';
  this.responseMessage = '';
  this.showalert = false;
  console.log('In createCandidateDetails');
  if(this.userInfo.Dob){
    this.userInfo.Dob = this.convertDate(this.userInfo.Dob);
  }
  this.userInfo.AdminId = this.userId;
  this.jobService.createCandidateDetails(this.userInfo).subscribe(
    data => {
            if(data.success == true){
              this.userInfo = data.Result.CandidateDetail;
              //this.userInfo.FirstName = sessionStorage.getItem('fname');
              //this.userInfo.LastName = sessionStorage.getItem('lname');
              sessionStorage.setItem('fname', this.userInfo.FirstName);
              sessionStorage.setItem('lname', this.userInfo.LastName);
              this.userInfo.EmailId = sessionStorage.getItem('email');
              this.userInfo.ProfileSubmittedBy = 'self';
              this.getCitiesForState();
              this.profcompletionscore = data.Result.TotalProfileCompletionScore;
              sessionStorage.setItem('ProfileCompletionScore', this.profcompletionscore);
              this.successmessage = 'Personal details created successfully';
              this.responseMessage = 'Personal details created successfully';
              this.typeOfResponseMessage = 'success';
              this.showalert = true;
            } else {
              this.responseMessage = data.error;
              this.typeOfResponseMessage = 'error';
              this.errormessage = data.error;
              this.showalert = true;
              console.log('Error message : ', data.error);
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
}

convertDate(date : any){
  var dat = new Date(date);
  
  let latest_date = dat.toISOString();
  console.log('Converted date : ', latest_date);
  return latest_date;
 }

 getStates(){
  this.jobService.getStates(this.userId).subscribe(
    data => {
            if(data.success == true){
              this.states = data.Results;
            } else {
              this.errormessage = data.error;
              console.log('Error message : ', data.error)
            }
    },
    error => {
      this.submitted = false;
      console.log('Error occurred : ', error);
          });
  
  }

  getCitiesForState(){
    console.log('in getCitiesForState : ', this.userInfo.State);

    if(this.userInfo.State){
      this.jobService.getCitiesListForState(this.userInfo.State,this.userId).subscribe(
        data => {
                if(data.success == true){
                  this.cities = data.Results;
                } else {
                  this.errormessage = data.error;
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          console.log('Error occurred : ', error);
              });
      
    }
  }

  closeAlert(event : any){
    if(event){
      this.showalert = false;
    }
  }
}




