import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';
import {JobService} from '../services/index';
import {JobPostingDetail} from '../models/index';



@Component({
  selector: 'app-job-oppor',
  templateUrl: './jobopportunities.component.html',
})
export class JobOpportunities implements OnInit {
  @Input() hideNav: boolean;
  userId: any;
  adminId: any;
  errormessage : any;
  jobposts : JobPostingDetail[];
  showJobProfile : boolean;
  jobdata : JobPostingDetail;
  dataLoad: boolean = true;

  constructor(private router: Router,private jobService : JobService) { }

  ngOnInit() {
    this.showJobProfile = false;
    this.userId = sessionStorage.getItem('userid');
    this.getJobOpportunities();
  }

  getJobOpportunities(){
    this.jobService.getJobPostingDetails(this.userId,this.userId).subscribe(
      data => {
              if(data.success == true){
                this.jobposts = data.Results;
                this.dataLoad = false;
                console.log('Job details obtained : ', this.jobposts);
              } else {
                this.errormessage = data.error;
                console.log('Error message : ', data.error);
              }
      },
      error => {
        console.log('Error occurred : ', error);
            });
  }

  viewJobProfile(jobdata: any){
    this.jobdata = jobdata;
    sessionStorage.setItem('jobreqid',this.jobdata.JobReqId );
    //document.getElementById('jobopportunities').style.display = 'none';
    //this.showJobProfile = true;
    this.router.navigate(['/jobprofile', this.userId,this.userId,this.jobdata.JobReqId  ]);

  }
   

  


}
