import { Component, OnInit, Input } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottommenu.component.html',
})
export class BottomMenu implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  gotoPage(route){
    if(route == '/dashboard'){
      var userid = sessionStorage.getItem('userid');
      if(userid && userid !== 'null'){
        this.router.navigate([route]);
      } else {
        sessionStorage.setItem('currentroute', this.router.url);
        this.router.navigate(['/alertlogin']);
      }
    } else {
      this.router.navigate([route]);
    }
  }

}
