import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JobService } from '../services/index';
import {
  JobPostingDetail, JobLevelSkill, JobApplyData, JobApplySkill,
  JobApplyAcademicDetails, JobApplyCertification, CurrentCompany, CompanyDetails
} from '../models';
import { Config } from '../config/index';
import { EduQualUpdate } from './eduQualupdate.component';
import { CurCompanyDetailsUpdate } from './curcompdetailsupdate.component';
import { MatDialog } from '@angular/material/dialog';
import { SERVER_URL_HOST } from '../../environments/environment';

@Component({
  selector: 'app-job-apply',
  templateUrl: './jobapply.component.html',
})
export class JobApply implements OnInit {
  userId: any;
  adminId: any;
  errormessage: any;
  matchingdetailsreq: any;
  uploadreq: any;
  jobreqId: any;
  jobData: JobPostingDetail;
  showSkills: boolean;
  config: Config;
  joblevels: any;
  years: any;
  months: any;
  noofyears: any;
  noofmonths: any;
  selectedFile: File;
  selectedImageFile: File;
  jobApplyData: JobApplyData;
  resumeLink: any;
  showjobalert: boolean;
  jobAlertMsg: any;
  uploaderrormsg: any;
  uploadsuccessmsg: any;
  responseMessage: any;
  typeOfResponseMessage: any;
  showalert: boolean;
  validFileFormats: any;
  validImageFormats: any;
  dialogRef: any;
  curCompany: CurrentCompany;
  curCompanyList: CurrentCompany[];
  curCompanyDupCheck: CurrentCompany;
  ryears: any;
  rmonths: any;
  cities: any;
  tobeselectedcities: any;
  serverUrl: any;
  imgPath: any;
  uploadedImage: any;
  isImageUploaded: boolean;
  pageloaded: boolean;
  companyDetails: [];
  companyDetail: CompanyDetails;
  submitted: boolean;

  constructor(private router: Router, private jobService: JobService, public dialog: MatDialog,
    private route: ActivatedRoute) { }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    console.log('Job ID:', id);
    this.curCompanyList = [];
    this.companyDetail = new CompanyDetails;
    this.showjobalert = false;
    this.getCities();
    console.log('In ngoninit of job apply component');
    this.userId = sessionStorage.getItem('userid');
    this.jobreqId = sessionStorage.getItem('jobreqid');
    this.getCompanyDetails();
    this.getCandidateMatchingJobDetails();
    this.showSkills = false;
    this.config = new Config();
    this.getJobLevels();
    this.years = this.config.getNumbersList();
    this.months = this.config.getNumbersList();
    console.log('Years : ', this.years);
    console.log('Months : ', this.months);
    this.validFileFormats = this.config.getFileFormats();
    this.validImageFormats = this.config.getImageFormats();
    this.uploaderrormsg = '';
    this.uploadsuccessmsg = '';
    this.showalert = false;
    this.tobeselectedcities = [];
    this.serverUrl = SERVER_URL_HOST;
    this.isImageUploaded = false;
    this.imgPath = '';
    this.pageloaded = false;
  }

  changeImage(event, type) {
    console.log("event", event);
    if (event.srcElement.files && event.srcElement.files[0]) {
      var e = document.getElementById(type);
      console.log("e", e);
      e.setAttribute('src', window.URL.createObjectURL(event.srcElement.files[0]));
      if (type == 'imgupload') {
        this.selectedImageFile = event.srcElement.files[0];
        console.log('Uploaded image :  ', this.selectedImageFile);
        console.log('Uploaded image name:  ', this.selectedImageFile.name);
        console.log('Uploaded image name:  ', this.selectedImageFile.type);
      } else {
        this.selectedFile = event.srcElement.files[0];
        console.log('Uploaded file :  ', this.selectedFile);
        console.log('Uploaded file name:  ', this.selectedFile.name);
        console.log('Uploaded file name:  ', this.selectedFile.type);
      }
    }
  }
  getCompanyDetails() {
    this.companyDetails = []
    this.jobService.getCompanyDetails(this.userId).subscribe(
      data => {
        if (data.success == true) {
          this.companyDetails = data.Results;
          console.log('Company details obtained : ', this.companyDetails);
        } else {
          console.log('Error message : ', data.error);
        }
      },
      error => {
        this.responseMessage = 'Error occurred while getting user data';
        this.typeOfResponseMessage = 'error';
        this.showalert = true;
        console.log('Error occurred : ', error);
      });
  }

  uploadcv() {
    this.uploaderrormsg = '';
    this.uploadsuccessmsg = '';
    this.uploadreq = {};
    this.uploadreq.UserId = this.userId;
    this.uploadreq.AdminId = this.userId;
    this.uploadreq.FileType = this.getFileType(this.selectedFile.name);
    if (!this.checkIfValidFile(this.uploadreq.FileType, 'resume')) {
      console.log('Uploaded file format is not correct');
      this.responseMessage = 'Only rtf or doc or docx file allowed';
      this.typeOfResponseMessage = 'error';
      this.showalert = true;
      return;
    }

    console.log("uploadcv", this.uploadreq)

    this.uploadreq.resumelink = 'testlink';
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;

    this.jobService.uploadFile(this.uploadreq, this.selectedFile).subscribe(
      data => {
        if (data.success == true) {
          console.log('data result : ', data.Result);
          this.resumeLink = data.Result;
          this.uploadsuccessmsg = 'Successfully uploaded resume';
          this.responseMessage = 'Successfully uploaded resume';
          this.typeOfResponseMessage = 'success';
          this.showalert = true;
        } else {
          this.responseMessage = 'Failed to upload resume';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          this.errormessage = data.error;
          this.uploaderrormsg = 'Failed to upload resume';
          console.log('Error message : ', data.error)
        }
      },
      error => {
        console.log('Error occurred : ', error);
      });
  }

  uploadImage() {
    this.uploaderrormsg = '';
    this.uploadsuccessmsg = '';
    this.uploadreq = {};
    this.uploadreq.UserId = this.userId;
    this.uploadreq.AdminId = this.userId;
    this.uploadreq.FileType = this.getFileType(this.selectedImageFile.name);

    if (!this.checkIfValidFile(this.uploadreq.FileType, 'image')) {
      console.log('Uploaded file format is not correct');
      this.responseMessage = 'Only png or jpg or jpeg file allowed';
      this.typeOfResponseMessage = 'error';
      this.showalert = true;
      return;
    }
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;
    this.isImageUploaded = false;
    this.jobService.uploadFile(this.uploadreq, this.selectedImageFile).subscribe(
      data => {
        if (data.success == true) {
          console.log('data result : ', data.Result);
          this.uploadedImage = data.Result;
          this.isImageUploaded = true;
          this.imgPath = SERVER_URL_HOST + '/' + this.uploadedImage;
          this.uploadsuccessmsg = 'Successfully uploaded image';
          this.responseMessage = 'Successfully uploaded image';
          this.typeOfResponseMessage = 'success';
          this.showalert = true;
        } else {
          this.responseMessage = 'Failed to upload image';
          this.typeOfResponseMessage = 'error';
          this.showalert = true;
          this.errormessage = data.error;
          this.uploaderrormsg = 'Failed to upload image';
          console.log('Error message : ', data.error);
        }
      },
      error => {
        console.log('Error occurred : ', error);
      });
  }

  getFileType(fileName: any) {
    var fileType = '';
    if (fileName.indexOf('.') > 0) {
      fileType = fileName.substr(fileName.indexOf('.') + 1, fileName.length - 1);
      console.log('File type : ', fileType);
    }
    return fileType;
  }

  getCandidateMatchingJobDetails() {
    this.jobApplyData = new JobApplyData();
    this.matchingdetailsreq = {};
    this.matchingdetailsreq.adminId = this.userId;
    this.matchingdetailsreq.CandidateId = this.userId;
    this.matchingdetailsreq.JobPostingId = this.jobreqId;
    this.matchingdetailsreq.JobReqId = this.jobreqId;
    this.jobService.getMatchingDetailsOfCandidate(this.matchingdetailsreq).subscribe(
      data => {
        if (data.success == true) {
          this.jobData = data.Result;
          this.populateJobApplyDataObject();
          this.populatePreferredLocations();
          this.populateCandidatePhoto();
          this.populateRelevantExp();
          this.showSkills = true;
          console.log('Job details obtained : ', this.jobData);
          if (this.jobData.CurrentCompDetails) {
            this.curCompany = new CurrentCompany();
            this.curCompany.Designation = this.jobData.CurrentCompDetails.Designation;
            this.curCompany.ToDate = this.jobData.CurrentCompDetails.ToDate;
            this.curCompany.FromDate = this.jobData.CurrentCompDetails.FromDate;
            this.curCompany.CompanyName = this.jobData.CurrentCompDetails.CompanyName;
            this.curCompany.JobLevel = this.jobData.CurrentCompDetails.JobLevel;
            this.curCompany.Id = this.jobData.CurrentCompDetails.Id;
            this.curCompanyList.push(this.curCompany);
          }
          console.log('this.curCompanyList : ', this.curCompanyList);
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        console.log('Error occurred : ', error);
      });
  }

  populatePreferredLocations() {
    if (this.jobData.PreferredLocations) {
      var plocations = JSON.parse(this.jobData.PreferredLocations);

      for (let loc of plocations) {
        this.tobeselectedcities.push(loc);
      }
    }
  }

  populateCandidatePhoto() {
    if (this.jobData.CandidatePhoto) {
      this.uploadedImage = this.jobData.CandidatePhoto;
      this.imgPath = this.serverUrl + '/' + this.uploadedImage;
      this.isImageUploaded = true;
    }
  }

  isCandidatePossessedCert(certobj: any, forskill: boolean) {
    if (this.jobData.CandECTC === "") {
      this.jobApplyData.CandECTC = "0";
    }
    if (certobj.isPresentInCand == 1) {
      if (forskill && certobj.SelfRating > 0) {
        certobj.selected = true;
      }
      else {
        certobj.selected = false;
      }
      return true;
    } else {
      return false;
    }
  }

  getJobLevelCertificationName(cert: any) {
    return cert.CertificationName;
  }

  populateJobApplyDataObject() {
    this.jobApplyData.jobPostingId = this.jobData.JobReqId;
    this.jobApplyData.CandCurrentCTC = this.jobData.CandCurrentCTC;
    this.jobApplyData.CandECTC = this.jobData.CandECTC;
    this.jobApplyData.CurrentJobLevel = this.jobData.CurrentJobLevel;
    this.jobApplyData.ExpectedJobLevel = this.jobData.ExpectedJobLevel;

    /*this.jobApplyData.CupAcademicDetail = this.jobData.JobLevelQualifications;
    this.jobApplyData.CupCertificationDetail = this.jobData.JobLevelCertifications;
    this.jobApplyData.CupSkillRatingModels = this.jobData.JobLevelSkills;

    for(var skill of this.jobApplyData.CupSkillRatingModels){
      if(skill.SelfRating && skill.SelfRating !== 'null'){
        console.log('making selected as true');
          skill.selected = true;
      }
    }*/

  }

  getMatSliderName(skill: any) {
    var name = skill.Skill + skill.Id;
    return name;
  }

  getCheckboxname(skill: any) {
    var name = skill.Skill + skill.Id + 'check';
    return name;
  }

  getJobLevels() {
    this.jobService.getJobLevels().subscribe(
      data => {
        if (data.success == true) {
          this.joblevels = data.Results;
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        console.log('Error occurred : ', error);
      });

  }

  applyForJob(valid: boolean) {
    if (!valid) {
      this.submitted = true;
    }
    if (valid) {

      this.jobApplyData.ResumeLink = this.resumeLink;
      // tslint:disable-next-line:radix
      this.jobApplyData.TotalExp = Number.parseInt(this.noofyears) * 12 + Number.parseInt(this.noofmonths);
      this.jobApplyData.CandidateId = this.userId;
      this.jobApplyData.AdminId = this.userId;

      var jobApplySkillDetails = [];
      for (var skill of this.jobData.JobLevelSkills) {

        if (skill.selected) {
          var jobApplySkill = new JobApplySkill();
          jobApplySkill.SkillName = skill.Skill;
          jobApplySkill.SelfRating = skill.SelfRating;
          jobApplySkill.Category = skill.SkillCategory;
          jobApplySkill.EmployerRating = skill.EmployerRating;
          jobApplySkill.InternalRating = skill.PROPMORating;
          jobApplySkill.SystemRating = skill.SystemRating;
          jobApplySkill.AdminId = this.userId;
          jobApplySkill.CandidateId = this.userId;

          if (skill.SkillCategory == 'Technical') {
            jobApplySkill.isCore = 1;
          }

          jobApplySkillDetails.push(jobApplySkill);

        }

      }

      this.jobApplyData.CupSkillRatingModels = jobApplySkillDetails;

      var qualificationsSelected = [];
      if (this.jobData.JobLevelQualifications && this.jobData.JobLevelQualifications.length > 0) {
        for (var edu of this.jobData.JobLevelQualifications) {
          if (edu.selected == true) {
            var jobApplyQualification = new JobApplyAcademicDetails();
            jobApplyQualification.Percentage = edu.Percentage;
            jobApplyQualification.EducationCategory = edu.QualCategory;
            jobApplyQualification.Degree = edu.Qualification;
            jobApplyQualification.Major = edu.QualSpecialization;
            jobApplyQualification.UserId = this.userId;
            qualificationsSelected.push(jobApplyQualification);
          }
        }
      }


      this.jobApplyData.CupAcademicDetail = qualificationsSelected;

      var certificationsSelected = [];
      if (this.jobData.JobLevelCertifications && this.jobData.JobLevelCertifications.length > 0) {
        for (var edu1 of this.jobData.JobLevelCertifications) {
          if (edu1.selected == true) {
            var jobApplyCert = new JobApplyCertification();
            jobApplyCert.CertificationName = edu1.CertificationName;
            certificationsSelected.push(jobApplyCert);
          }
        }
      }

      this.jobApplyData.CupCertificationDetail = certificationsSelected;

      this.jobApplyData.AllocationStatus = 'Applied for a job';
      if (this.ryears && this.rmonths) {
        this.jobApplyData.RelevantExp = this.ryears + ' Years ' + this.rmonths + ' Months';
      }

      this.jobApplyData.HoldingAnyOffer = this.jobData.HoldingAnyOffer;
      this.jobApplyData.ReasonForJobChange = this.jobData.ReasonForJobChange;
      this.jobApplyData.PreferredLocations = JSON.stringify(this.tobeselectedcities);
      this.jobApplyData.CUPCompanyDetails = this.curCompanyList;
      this.jobApplyData.CandidatePhoto = this.uploadedImage;
      console.log('Final object for job apply : ', this.jobApplyData);

      this.jobService.applyJob(this.jobApplyData).subscribe(
        data => {
          if (data.success == true) {

            this.jobAlertMsg = data.Result.error;
            document.getElementById('jobapply').style.display = 'none';
            this.showjobalert = true;

            if (data.Result.success == true) {
              this.getVerificationStatus();
            }
            console.log('Job apply response : ', data);
          } else {
            this.errormessage = data.error;
            console.log('Error message : ', data.error)
          }
        },
        error => {
          console.log('Error occurred : ', error);
        });
    }
  }

  getVerificationStatus() {
    this.jobService.getVerificationStatus(this.userId).subscribe(
      data => {
        if (data.success == true) {

          console.log('Verification status response : ', data);
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        console.log('Error occurred : ', error);
      });

  }

  populateRelevantExp() {
    if (this.jobData.RelevantExp) {
      const spl = this.jobData.RelevantExp.split(' ');
      console.log('spl : ', spl);
      if (spl) {
        this.ryears = spl[0];
        this.rmonths = spl[2];
      }
    }
  }

  isSkillByCandidate(selfrating, skill: any): boolean {
    if (selfrating && selfrating > 0 && selfrating !== 'null') {
      skill.selected = true;
      return true;
    } else {
      skill.selected = false;
      return false;
    }
  }

  getSliderLabel(skillObject: JobLevelSkill) {
    if (skillObject.SelfRating == 'null' || !skillObject.SelfRating) {
      return '()';
    } else {
      return '(' + this.config.getSkillRatingDescriptionAlongWithValue(skillObject.SelfRating) + ')';
    }
  }

  checkIfValidFile(fileType: any, category: any): boolean {
    if (category == 'resume') {
      if (fileType) {
        if (this.validFileFormats.includes(fileType)) {
          return true;
        }
      }
      return false;
    }

    if (category == 'image') {
      if (fileType) {
        if (this.validImageFormats.includes(fileType)) {
          return true;

        }
      }
      return false;
    }
  }
  closeAlert(event: any) {
    if (event) {
      this.showalert = false;
    }
  }

  closeJobAlert(event: any) {
    if (event) {
      console.log('In close job alert modal');
      this.showjobalert = false;
      this.router.navigate(['/jobsearch']);

    }
  }

  openDialog(ob: any) {
    this.errormessage = '';

    this.dialogRef = this.dialog.open(EduQualUpdate, {
      width: '100%',
      height: '70%',
      closeOnNavigation: true,
      data: { academicdetail: ob }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.log('in dialog close of education details update');
      if (result) {
        console.log('Object obtained : ', ob);
      }
    });
  }

  openAddCurCompDialog(ob: any, save: boolean) {
    this.errormessage = '';
    let saveorupdate = '';
    if (save) {
      this.curCompany = new CurrentCompany();
      saveorupdate = 'save';
    } else {
      this.curCompany = ob;
      saveorupdate = 'update';
    }
    this.curCompany.UserId = this.userId;
    this.curCompanyDupCheck = JSON.parse(JSON.stringify(ob));
    this.dialogRef = this.dialog.open(CurCompanyDetailsUpdate, {
      width: '100%',
      height: '70%',
      closeOnNavigation: true,
      data: { curcomp: this.curCompany, save: saveorupdate }
    });
    this.dialogRef.afterClosed().subscribe(result => {
      console.log('in dialog close of education details update', this.curCompanyDupCheck);
      if (result) {
        if (!this.curCompanyList) {
          this.curCompanyList = [];
        }
        let exists = false;
        if (!exists) {
          if (!save) {
            const index = this.curCompanyList.findIndex(company => company.Id === this.curCompanyDupCheck.Id);
            this.curCompanyList[index] = this.curCompanyDupCheck;
          }
          const exists = this.curCompanyList.some(CurrentCompany =>
            CurrentCompany.CompanyName.toLowerCase() === result.CompanyName.toLowerCase() &&
            CurrentCompany.FromDate === result.FromDate &&
            CurrentCompany.Designation.toLowerCase() === result.Designation.toLowerCase() &&
            CurrentCompany.JobLevel === result.JobLevel)
          if (exists) {
            this.responseMessage = 'Company with the same details already exists';
            this.typeOfResponseMessage = 'error';
            this.showalert = true;
          }
          if (!exists) {
            if (save) {
              this.curCompanyList.push(result);
              console.log(this.curCompanyList);
            }
            else {
              const index = this.curCompanyList.findIndex(company => company.Id === this.curCompanyDupCheck.Id);
              this.curCompanyList[index] = this.curCompany;
            }
          }
        }
      }
      else {
        if (!save) {
          const index = this.curCompanyList.findIndex(company => company.Id === this.curCompanyDupCheck.Id);
          this.curCompanyList[index] = this.curCompanyDupCheck;
        }
        this.responseMessage = 'Company with the same details already exists';
        this.typeOfResponseMessage = 'error';
        this.showalert = true;
      }
      console.log('updating e company details', result);
    });
  }
  getCities() {

    this.jobService.getCitiesList().subscribe(
      data => {
        if (data.success == true) {
          this.pageloaded = true;
          this.cities = data.Results;
        } else {
          this.errormessage = data.error;
          console.log('Error message : ', data.error)
        }
      },
      error => {
        console.log('Error occurred : ', error);
      });
  }
}


