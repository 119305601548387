
export class JobLevelQualification {
    AdminId: any;
CreatedBy: any;
CreatedOn: any;
Id: any;
JobReqId: any;
Need: string;
Percentage: any;
QualCategory: string;
QualCutOffMarks: any;
QualMarkingSystem: any;
QualSpecialization: any;
Qualification: any;
UpdatedBy: any;
UpdatedOn: any;
isPresentInCand: any;

//Used for job apply
EducationCategory: any;
MarkingSystem : any;
UserId : any;
selected : any;
}
