import { Component, OnInit, Input } from '@angular/core';
import {JobService} from '../services/index';
import {UserInfo} from '../models/index';
import {Router} from '@angular/router';

@Component({
  selector: 'app-page-title',
  styleUrls: ['./pagetitle.component.css'],
  templateUrl: './pagetitle.component.html',
})
export class PageTitle implements OnInit {
  navParam:any;
  
  constructor(private router: Router, ) { }

  ngOnInit() {
    
  }

  gotoPage(route){
      this.router.navigate([route], {queryParams:{nav:this.navParam}});
  
    
  }
  


}
