import { Component, OnInit, Input, OnChanges, OnDestroy, Output,EventEmitter  } from '@angular/core';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA,MatDialog } from '@angular/material/dialog';
import { AlertDialog } from './alertdialog.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
})
export class Alert implements OnInit, OnChanges , OnDestroy{

  @Input() message: string;
  @Input() type : string;
  @Output() respaction = new EventEmitter<string>();

  dialogRef : any;

  constructor(private router: Router,public dialog: MatDialog) { }

  ngOnInit() {
    console.log("showalert");
  }

  ngOnChanges(){
    this.openDialog();
  }

  ngOnDestroy(){
  }

  openDialog(){
    console.log("opendialoge")
    this.dialogRef = this.dialog.open(AlertDialog, {
      width:'100%',
      height:'40%',
      closeOnNavigation : true,
      data: {message : this.message, type : this.type}
    });
  
    this.dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close();
        this.respaction.emit('close');
    });
  }
  

}
