
export class JobLevelSkill {
    AdminId: any;
CreatedBy: any;
CreatedOn: any;
EmployerRating: any;
ExpectedCompetency: any;
Id: any;
JobReqId: any;
PROPMORating: any;
SelfRating: any;
Skill: any;
SkillCategory: any;
SkillMasterId: any;
SkillNeed: any;
SystemRating: any;
UpdatedBy: any;
UpdatedOn: any;
isPresentInCand: any;
selected : any;
}
