import { Component, OnInit, Input,AfterViewInit,OnDestroy } from '@angular/core';
import {Router} from '@angular/router';
import Glide from '@glidejs/glide';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
})
export class Slider implements OnInit,AfterViewInit,OnDestroy {
  startAt : any;
  perView : any;
  autoplay : any;
  gap : any;
  carouselimages : any;
  glideresponse : any;
  randomnumber : any;

  constructor(private router: Router) { }

  ngOnDestroy(){
    console.log('In ngon destroy of slider component');
  }

  ngOnInit() {
    this.randomnumber = this.makeid(5);
    this.startAt = 0;
    this.perView = 1;
    //this.autoplay = 2000;
    this.gap = 10;
    this.carouselimages = ['assets/images/animation2.png', 'assets/images/animation1.png','assets/images/animation3.png'];
    //this.initializeSlider();

  }

  ngAfterViewInit(){
    console.log('In ngAfterViewInit');
    console.log(document.getElementById('greetcarousel'));

    this.initializeSlider();
  }

  handleClick(){
    console.log('In handleClick of slider');
    this.router.navigate(['/dashboard']);
  }

  initializeSlider(){
    console.log('In initialize slider');
    
    setTimeout(() => {
        new Glide('#greetcarousel'+this.randomnumber, {
          type: 'carousel',
          startAt: 0,
          perView: 1,
          breakpoints: { 1600: { perView: 1 },1920: { perView: 1 }  },
          gap: 15,
          autoplay: 2000,
          hoverpause : true,
          peek: {
            before: 15,
            after: 15,
          }
        }).mount();
     }, 800);

  }

  makeid(length : any) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    console.log('Generated random : ', result);
    return result;
 }



}
