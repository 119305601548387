
export class UserInfo {
    FirstName: string;
    LastName: string;
    EmailId: string;
    Password: string;
    Role: string;
    isActive: any;
    ProfileSubmittedBy: string;
    Mobile: string;
    isTnCAccepted: any;
    Otp: any;
    NewPass: any;
    CaptchaRandomReq1: any;
    CaptchaRandomReq2: any;
    CaptchaResponse: any;
}
