
export class CareerPathSkills {
    
    AdminId: any;
    CreatedBy: any;
    CreatedOn: any;
    EmployerRating: any;
    ExpectedCompetency: any;
    Id: any;
    JobTemplateId: any;
    PROPMORating: any;
    SelfRating: any;
    Skill: any;
    SkillCategory: any;
    SkillMasterId: any;
    SkillNeed: any;
    SystemRating: any;
    UpdatedBy: any;
    UpdatedOn: any;
    isPresentInCand: any;
    skillbadge: any;
}
