import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {JobService} from '../services/index';
import {UserInfo} from '../models/index';
import {Config} from '../config/index';
import {Router} from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TermandconditionComponent } from '../termandcondition/termandcondition.component';

@Component({
  selector: 'app-new-user-reg',
  templateUrl: './registernewuser.component.html',
})
export class RegisterNewUser implements OnInit {
  @Input() hideNav:boolean;
  @Output() signInForm:EventEmitter<any> = new EventEmitter();
  userInfo : UserInfo;
  TC : false;
  countrycode : any;
  mobileno : any;
  countrylist : any;
  submitted : boolean;
  isCountryCodeValid : boolean;
  errormessage : any;
  successmessage : any;
  tcchecked : boolean;
  show : boolean;
  mobilevalid : boolean;
  responseMessage : any;
  typeOfResponseMessage : any;
  showalert : boolean;
  firstNum : any;
  secondNum : any;
  captchaRes : number;
  captchaDisplay : any;
  captchaValue : any;
  captchavalid : boolean;

  constructor(private jobService: JobService,private config : Config,private router: Router, private dialog: MatDialog) { }

  ngOnInit() {
    this.submitted = false;
    this.userInfo = new UserInfo();
    this.countrycode = '';
    this.countrylist = this.config.getCountryList();
    console.log('Country list obtained : ', this.countrylist);
    this.isCountryCodeValid = false;
    this.errormessage = '';
    this.successmessage = '';
    this.tcchecked = false;
    this.show = false;
    this.mobilevalid = true;
    this.showalert  = false;
    this.captchavalid = true;
    this.firstNum = this.config.makeid(1);
    this.secondNum = this.config.makeid(1);
    this.calculateCapthaRes();
    this.formCaptchaDisplay();
    console.log('Two random numbers obtained : ', this.firstNum , this.secondNum);
  }

  countryCodeChange(){
    this.isCountryCodeValid = true;
  }

  validateMobileNo(){
    this.mobilevalid = true;
    if(this.mobileno){
      var mno = new String(this.mobileno);
      if(mno.length > 10){
        this.mobilevalid = false;
      }
    }
   return this.mobilevalid;
    
  }
  openDialog(){
  this.dialog.open(TermandconditionComponent, {
    width:'100%',
    height:'70%',
    closeOnNavigation : true,
  });
}

  registerUser(valid: boolean){
    this.submitted = true;
    this.errormessage = '';
    this.successmessage = '';
    this.typeOfResponseMessage = '';
    this.responseMessage = '';
    this.showalert = false;

    if(valid == true){

      if(this.countrycode == 'Country Code') {
        console.log();
        this.isCountryCodeValid = false;
        return;
      }
      this.userInfo.Role = 'candidateUser';
      this.userInfo.isActive = 1;
      this.userInfo.ProfileSubmittedBy = 'self';

      if(this.TC){
        this.tcchecked = true;
        this.userInfo.isTnCAccepted =1;
      } else {
        this.tcchecked = false;
        this.userInfo.isTnCAccepted =0;
        return;
      }

      if(!this.validateMobileNo()){
        return;
      }

      if(!this.isValidCaptcha()){
        return;
      }

      this.userInfo.Mobile = '+'+this.countrycode+'-'+this.mobileno;
      console.log('Finally formed mobile number : ', this.userInfo.Mobile);

      this.userInfo.CaptchaRandomReq1 = this.config.encryptData(this.firstNum);
      this.userInfo.CaptchaRandomReq2 = this.config.encryptData(this.secondNum);
      this.userInfo.CaptchaResponse =  this.config.encryptData(this.captchaValue + '');

      console.log('User info object formed : ', this.userInfo);

      this.jobService.registerUser(this.userInfo).subscribe(
        data => {
          this.submitted = false;
          console.log('User registration data received :', data);
                if(data.success == true){
                  this.successmessage = 'Successfully registered with us - CareerBuddyOnline Team!';
                  sessionStorage.setItem('userid', data.Result.UserId);
                  sessionStorage.setItem('email', data.Result.EmailId);

                  /*var jobreqid = sessionStorage.getItem('jobreqid');
                  if(jobreqid){
                    this.router.navigate(['/jobprofile']);
                  } else {*/
                    //this.router.navigate(['/regprof']);
                    this.signInForm.emit('regprof');
                  //}
                  
                  console.log('User registered successfully');
                } else {
                  this.responseMessage = data.error;
                  this.typeOfResponseMessage = 'error';
                  this.showalert = true;
                  this.errormessage = data.error;
                  console.log('User Not registered successfully');
                  console.log('Error message : ', data.error)
                }
        },
        error => {
          this.submitted = false;
          console.log('Error occurred : ', error);
              });
    } else {
      if(this.countrycode == 'Country Code') {
        this.isCountryCodeValid = false;
        return;
      }
      if(this.TC){
        this.tcchecked = true;
        return;
      } else {
        this.tcchecked = false;
        return;
      }

    }
  }
  
  togglepassword(){
    console.log('Show initial : ', this.show);
    this.show = !this.show;
    console.log('Show final : ', this.show);
  }

  closeAlert(event : any){
    if(event){
      this.showalert = false;
    }
  }

 calculateCapthaRes(){
    this.captchaRes = parseInt(this.firstNum) + parseInt(this.secondNum);
    console.log('Captcha result obtained : ', this.captchaRes);
 }

 formCaptchaDisplay(){
   this.captchaDisplay = this.firstNum + ' + ' + this.secondNum + ' = ';
   console.log('captcha display :: ', this.captchaDisplay);
 }

 isValidCaptcha(){
  if(parseInt(this.captchaValue) == this.captchaRes){
    console.log('Entered captcha is valid');
    this.captchavalid = true;
    return true;
  }
  this.captchavalid = false;
  return false;
 }

 resetSubmit(){
   this.submitted = false;
   this.captchavalid = true;
 }

 signIn(){
  this.signInForm.emit('signinForm');

 }




}
