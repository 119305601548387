
export class AcademicDetails {
    Id: any;
    AdminId: any;
    UserId: any;
    EducationCategory: string;
    Major: string;
    Degree: string;
    UniversityName: string;
    Percentage: any;
    PassoutMonth: any;
    PassoutYear: null;
    MarkingSystem: string;
    CreatedBy: any;
    CreatedOn: any;
    UpdatedBy: any;
    UpdatedOn: any;
    Badge: any;
    ProfileCompletionScore: any;
}
