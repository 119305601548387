import { Component, OnInit, Input,Inject } from '@angular/core';
import {JobService} from '../services/index';
import {Router} from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Config} from '../config/index';


@Component({
  selector: 'app-visa-details-update',
  templateUrl: './visadetailsupdate.component.html',
})
export class VisaDetailsUpdate implements OnInit {

  constructor(private jobService: JobService, private router: Router,
    public dialogRef: MatDialogRef<VisaDetailsUpdate>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private config : Config) { }

  ngOnInit() {
    
  }

  gotoPage(route){
    this.router.navigate([route]);
  }


  onCancel(): void { 
    this.dialogRef.close(); 
  }

  saveDetails(valid : boolean){

  if(valid){
    this.dialogRef.close(this.data.visadetail);
  } else {
    return;
  }

}


}
